import { AuthenticationError } from "~/lib/api";
import { next, type MiddlewareContext } from "~/lib/middleware/middleware";
import { signOut } from "~/lib/session";
import { type RequiredAuthenticatedUser } from "~/services/auth/fetch-authenticated-user";

export const protectRouteFromBlockedUsers = async (ctx: MiddlewareContext<{ user: RequiredAuthenticatedUser }>) => {
  if (!ctx.user.blockedAt) {
    return next(ctx);
  }

  await signOut(ctx.req);

  throw new AuthenticationError("Account blocked", {
    targetUrl: ctx.resolvedUrl,
    error: "account_blocked",
  });
};
