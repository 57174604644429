import { QueueJobName } from "@prisma/client";
import { number, object, string } from "yup";
import { type AppContext } from "~/lib/context";
import { BaseJobDataSchema } from "~/lib/queue/base-job-data-schema";
import { sendJob } from "~/lib/queue/send-job";
import { type YupOutputType } from "~/lib/utils";
import { duplicateSalaryGridForNewVersion } from "~/services/salary-bands/creation/duplicate-salary-grid-for-new-version";

const DuplicateSalaryGridForNewVersionJobDataSchema = BaseJobDataSchema.concat(
  object({ salaryGridId: number().required(), name: string().required() })
);

export type DuplicateSalaryGridForNewVersionJobData = YupOutputType<
  typeof DuplicateSalaryGridForNewVersionJobDataSchema
>;

export const duplicateSalaryGridForNewVersionWorkerService = async (
  ctx: AppContext,
  data: DuplicateSalaryGridForNewVersionJobData
) => {
  const input = DuplicateSalaryGridForNewVersionJobDataSchema.validateSync(data, { abortEarly: false });

  await duplicateSalaryGridForNewVersion(ctx, input);
};

export const sendDuplicateSalaryGridForNewVersionJob = async (
  ctx: AppContext,
  data: DuplicateSalaryGridForNewVersionJobData
) => {
  await sendJob(ctx, {
    jobName: QueueJobName.DUPLICATE_SALARY_GRID_FOR_NEW_VERSION,
    data,
    options: { singletonKey: data.salaryGridId.toString() },
  });
};
