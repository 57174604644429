/**
 * Everything you need to know (and more !) about integrations is in Thee Holy Bible :
 * https://www.notion.so/figures-hr/Integrations-Bible-e574387e1c64417082dd90f34f6c4334
 */

import {
  type Company,
  CompensationFrequency,
  type Currency,
  EmployeeMappingSkipReason,
  ExternalEmployeeSource,
  ExternalEmployeeStatus,
  ExternalRemunerationStatus,
  ExternalRemunerationType,
  Gender,
  IntegrationSource,
} from "@prisma/client";
import { mapSeries } from "bluebird";
import { add, isAfter } from "date-fns";
import { compact, get, isArray, isNumber, isString, map, values } from "lodash";
import { match } from "ts-pattern";
import { value } from "~/components/helpers";
import { config } from "~/config";
import { type AppContext } from "~/lib/context";
import { fetch } from "~/lib/fetch";
import { buildCustomBaseSalaryRemunerationItem } from "~/lib/hris/helpers/build-custom-base-salary-remuneration-item";
import { computeAdditionalFieldValuePayloads } from "~/lib/hris/helpers/compute-additional-field-value-payloads";
import { getEmployeeProfilePicture } from "~/lib/hris/helpers/get-employee-profile-picture";
import { getMissingCustomFields, type IntegrationCustomFields } from "~/lib/hris/helpers/get-missing-custom-fields";
import { getNumberOfMonth } from "~/lib/hris/helpers/get-number-of-month";
import { mapCustomRemunerationItem } from "~/lib/hris/helpers/map-custom-remuneration-item";
import { type IntegrationConfig, type SourceConfig, type StaticModels } from "~/lib/integration";
import { logInfo, logWarn } from "~/lib/logger";
import { assertProps, isIn } from "~/lib/utils";
import { type IntegrationDiagnostic } from "~/services/synchronization/fetch-company-integration-diagnostics";
import { type EmployeeData, type IntegrationSettingsForSync } from "~/services/synchronization/sync-external-employees";

const base: IntegrationConfig = {
  source: IntegrationSource.PERSONIO,
  companyId: 0,
  domain: null,
  clientId: config.personio.clientId,
  clientSecret: config.personio.clientSecret,
  anonymous: false,
  enabled: true,
  retryCount: 0,
  fteCustomFieldName: null,
  levelCustomFieldName: null,
  externalIdCustomFieldName: null,
  baseSalaryCustomFieldName: null,
  baseSalaryCustomFieldFrequency: null,
  variableCustomFieldName: null,
  variableCustomFieldFrequency: null,
  holidayAllowanceCustomFieldName: null,
  holidayAllowanceCustomFieldFrequency: null,
  isCustomFieldOnlyHolidayAllowance: null,
  isHolidayAllowanceIncludedInBaseSalary: null,
  businessUnitCustomFieldName: null,
  locationCustomFieldName: null,
};

export const personioConfigs: SourceConfig = {
  "default": base,
  "anonymous": { ...base, anonymous: true },
  "custom fields": {
    ...base,
    anonymous: true,
    externalIdCustomFieldName: "dynamic_3900088",
    businessUnitCustomFieldName: "department",
  },
};

type Credentials = {
  clientId: string;
  clientSecret: string;
  anonymous: boolean;
};

// Those headers are used by Personio to monitor their API usage
const tracingHeaders = {
  "X-Personio-Partner-ID": "FIGURES_HR",
  "X-Personio-App-ID": "FIGURES_HR_SYNC",
};

const authorization: Record<string, { accessToken: string; expiresAt: Date } | null> = {};

const authenticate = async (credentials: Credentials): Promise<string> => {
  const res = await fetch(
    `https://api.personio.de/v1/auth?client_id=${credentials.clientId}&client_secret=${credentials.clientSecret}`,
    {
      method: "POST",
      headers: {
        Accept: "application/json",
        ...tracingHeaders,
      },
    }
  );

  const json = await res.json();

  if (!res.ok || !json.success) {
    throw new Error(`Personio authentication failed: [${json.error.code}] - ${json.error.message}`);
  }

  return json.data.token as string;
};

const personioFetch = async (url: string, credentials: Credentials) => {
  const needsRefresh = value(() => {
    const authInfo = authorization[credentials.clientId];
    if (!authInfo) {
      return true;
    }
    if (isAfter(new Date(), authInfo.expiresAt)) {
      return true;
    }
    return false;
  });

  if (needsRefresh) {
    const accessToken = await authenticate(credentials);
    const expiresAt = add(new Date(), { hours: 23 });

    authorization[credentials.clientId] = {
      accessToken: `Bearer ${accessToken}`,
      expiresAt,
    };
  }

  const res = await fetch(url, {
    headers: {
      Accept: "application/json",
      Authorization: authorization[credentials.clientId]?.accessToken as string,
      ...tracingHeaders,
    },
  });

  if (!res.ok) {
    throw new Error(`Personio error : [${res.status}] - ${res.statusText}`);
  }

  const accessToken = res.headers.get("authorization");

  authorization[credentials.clientId] = accessToken
    ? {
        accessToken,
        expiresAt: add(new Date(), { hours: 23 }),
      }
    : null;

  return res;
};

export enum SalaryIntervalEnum {
  MONTHLY = "monthly",
  YEARLY = "yearly",
}

export enum GenderEnum {
  MALE = "male",
  FEMALE = "female",
  UNDISCLOSED = "",
}

export enum StatusEnum {
  ACTIVE = "active",
  INACTIVE = "inactive",
}

export type PersonioApiEmployee = {
  id: { label: string; value: number };
  first_name?: { label: string; value: string };
  last_name?: { label: string; value: string };
  email?: { label: string; value: string };
  gender?: { label: string; value: GenderEnum };
  status?: { label: string; value: StatusEnum };
  position?: { label: string; value: "" | string };
  hire_date?: { label: string; value: string | null };
  office?: {
    label: string;
    value: { attributes: { id: number; name: string } } | null;
  };
  fix_salary?: { label: string; value: 0 | number; currency: string };
  fix_salary_interval?: { label: string; value: SalaryIntervalEnum | string };
  profile_picture?: { label: string; value: string | null };
  supervisor?: {
    label: string;
    value: { attributes: { id: { value: number } } } | null;
  };
} & Record<`dynamic_${string}`, { label: string; value: string | null | { attributes: { id: number; name: string } } }>;

const anonymise = (user: PersonioApiEmployee): PersonioApiEmployee => {
  delete user.first_name;
  delete user.last_name;
  delete user.profile_picture;
  delete user.email;
  return user;
};

export const getPersonioEmployees = async (
  credentials: Credentials,
  options?: { offset?: number; limit?: number; paginate?: boolean; filter?: boolean }
): Promise<PersonioApiEmployee[]> => {
  const limit = options?.limit ?? 200;
  const offset = options?.offset ?? 0;
  const paginate = options?.paginate ?? true;
  const filter = options?.filter ?? true;

  const res = await personioFetch(
    `https://api.personio.de/v1/company/employees?limit=${limit}&offset=${offset}`,
    credentials
  );

  const json = await res.json();

  if (!json.success) {
    throw new Error(`Personio error: [${json.error.code}] - ${json.error.message}`);
  }

  const employees = map(json.data, "attributes")
    // Keep only active employees
    .filter((model: PersonioApiEmployee) => !filter || !model.status || model.status.value === StatusEnum.ACTIVE)
    .map((model: PersonioApiEmployee) => {
      if (credentials.anonymous) {
        return anonymise(model);
      }
      return model;
    });

  if (json.metadata.current_page === json.metadata.total_pages || !paginate) {
    return employees;
  }

  const nextEmployees = await getPersonioEmployees(credentials, { offset: offset + limit });

  return [...employees, ...nextEmployees];
};

const getPersonioProfilePicture = async (
  ctx: AppContext,
  options: { integrationSettings: SafeIntegrationSettings; apiEmployee: PersonioApiEmployee }
) => {
  if (!options.apiEmployee.profile_picture?.value) {
    return undefined;
  }

  return getEmployeeProfilePicture(ctx, {
    apiEmployeeId: options.apiEmployee.id.value.toString(),
    source: ExternalEmployeeSource.PERSONIO,
    integrationSettings: options.integrationSettings,
    fetch: () =>
      personioFetch(
        `https://api.personio.de/v1/company/employees/${options.apiEmployee.id.value}/profile-picture/512`,
        options.integrationSettings
      ),
  });
};

const getCustomField = (ctx: AppContext, apiEmployee: PersonioApiEmployee, fieldId: string | null): string | null => {
  if (!fieldId) {
    return null;
  }

  const field = get(apiEmployee, fieldId);

  if (!field || !field.value) {
    return null;
  }

  if (isArray(field.value)) {
    return null;
  }

  if (!isString(field.value) && !isNumber(field.value)) {
    if (!!field.value.attributes.name) {
      return field.value.attributes.name;
    }

    logWarn(ctx, "[personio] Cannot handle custom field format, skipping", {
      fieldId,
      value: field.value,
    });

    return null;
  }

  return `${field.value}`;
};

export type PersonioIntegrationSettingsInput = Credentials & IntegrationCustomFields;

export const getPersonioDiagnostic = async (
  ctx: AppContext,
  input: PersonioIntegrationSettingsInput
): Promise<IntegrationDiagnostic> => {
  try {
    const [employee] = await getPersonioEmployees(input, { limit: 1, paginate: false, filter: false });

    if (!employee) {
      return {
        connection: false,
        connectionError: "We could not find any employees within your Personio account",
        missingFields: [],
        availableFields: [],
      };
    }

    const availableFields = Object.entries(employee)
      .filter(([, field]) => isString(field.value) || isNumber(field.value) || !!field.value?.attributes)
      .map(([id, field]) => ({ id, name: field.label }));

    const missingFields = getMissingCustomFields(input, availableFields);

    return { connection: true, connectionError: "", missingFields, availableFields };
  } catch (error) {
    return { connection: false, connectionError: error.message, missingFields: [], availableFields: [] };
  }
};

export const mapPersonioUser = async (
  ctx: AppContext,
  company: Company,
  apiEmployee: PersonioApiEmployee,
  integrationSettings: SafeIntegrationSettings,
  staticModels: StaticModels,
  ignoreProfilePicture?: boolean
): Promise<EmployeeData> => {
  const {
    externalIdCustomFieldName,
    fteCustomFieldName,
    levelCustomFieldName,
    baseSalaryCustomFieldName,
    baseSalaryCustomFieldFrequency,
    variableCustomFieldName,
    variableCustomFieldFrequency,
    holidayAllowanceCustomFieldName,
    businessUnitCustomFieldName,
    locationCustomFieldName,
    jobCustomFieldName,
    additionalFieldMappings = [],
    customRemunerationItems = [],
  } = integrationSettings;

  const customExternalId = getCustomField(ctx, apiEmployee, externalIdCustomFieldName);
  const customFte = getCustomField(ctx, apiEmployee, fteCustomFieldName);
  const customLevel = getCustomField(ctx, apiEmployee, levelCustomFieldName);
  const customBaseSalary = getCustomField(ctx, apiEmployee, baseSalaryCustomFieldName);
  const customVariable = getCustomField(ctx, apiEmployee, variableCustomFieldName);
  const holidayAllowanceValue = getCustomField(ctx, apiEmployee, holidayAllowanceCustomFieldName);
  const businessUnit = getCustomField(ctx, apiEmployee, businessUnitCustomFieldName);
  const customLocation = getCustomField(ctx, apiEmployee, locationCustomFieldName);
  const customJob = getCustomField(ctx, apiEmployee, jobCustomFieldName);

  const additionalFieldValues = additionalFieldMappings.map(({ hrisFieldName, additionalFieldId, id }) => ({
    additionalFieldId,
    additionalFieldMappingId: id,
    value: getCustomField(ctx, apiEmployee, hrisFieldName),
  }));

  const customRemunerationItemsValues = customRemunerationItems.map((customRemunerationItem) => ({
    ...customRemunerationItem,
    value: getCustomField(ctx, apiEmployee, customRemunerationItem.hrisFieldName),
  }));

  const euro = staticModels.currencies.find((currency) => currency.code === "EUR");

  const currency = value(() => {
    if (!apiEmployee.fix_salary?.currency) {
      return euro as Currency;
    }

    return (
      staticModels.currencies.find((currency) => currency.code === apiEmployee.fix_salary?.currency) ||
      (euro as Currency)
    );
  });

  const job = value(() => {
    const jobTitle = customJob ?? apiEmployee.position?.value;

    if (!jobTitle) return;

    return {
      job: {
        connectOrCreate: {
          where: {
            companyId_externalId: {
              companyId: company.id,
              externalId: jobTitle,
            },
          },
          create: {
            name: jobTitle,
            externalId: jobTitle,
            company: {
              connect: { id: company.id },
            },
          },
        },
      },
    };
  });

  const location = value(() => {
    if (customLocation) {
      return {
        location: {
          connectOrCreate: {
            where: {
              companyId_externalId: {
                companyId: company.id,
                externalId: customLocation,
              },
            },
            create: {
              externalId: customLocation,
              name: customLocation,
              autoMappingEnabled: true,
              company: {
                connect: { id: company.id },
              },
            },
          },
        },
      };
    }

    if (apiEmployee.office?.value) {
      return {
        location: {
          connectOrCreate: {
            where: {
              companyId_externalId: {
                companyId: company.id,
                externalId: apiEmployee.office.value.attributes.id.toString(),
              },
            },
            create: {
              externalId: apiEmployee.office.value.attributes.id.toString(),
              name: apiEmployee.office.value.attributes.name,
              autoMappingEnabled: true,
              company: {
                connect: { id: company.id },
              },
            },
          },
        },
      };
    }

    return null;
  });

  // If the employee is not a permanent, we create them with a SKIPPED status
  const isAutoSkipped = value(() => {
    const field = values(apiEmployee).find((attribute) => attribute.label === "Occupation type");

    if (!field?.value) {
      logInfo(ctx, "[personio] Skipping Personio employee without occupation type", { apiEmployeeId: apiEmployee.id });
      return false;
    }

    if (
      isIn(field.value, [
        "permanent employment",
        "Permanent Employee",
        "Permanent Employment",
        "Full-time",
        "Indefinite contract",
        "Permanent full-time",
      ])
    ) {
      return false;
    }

    // @see https://linear.app/figures/issue/FIG-1455/mapped-employees-are-being-auto-skipped-for-client-ticketswap-1506
    // We've allowed those occupation types for TicketSwap, but we're not sure if it should be allowed globally
    // I've added a log to monitor if it's used by other clients to better understand the use case.
    if (isIn(field.value, ["Temporary contract", "On-call contract"])) {
      if (company.id === 1506) {
        return false;
      } else {
        logInfo(ctx, `[personio] Skipping Personio occupation-type`, {
          companyId: company.id,
          occupationType: field.value,
          apiEmployee,
        });
        return true;
      }
    }

    logInfo(ctx, `[personio] Skipping Personio occupation-type`, {
      companyId: company.id,
      occupationType: field.value,
      apiEmployeeId: apiEmployee.id,
    });
    return true;
  });

  const input: EmployeeData["input"] = {
    source: ExternalEmployeeSource.PERSONIO,
    externalId: apiEmployee.id.value.toString(),
    status: isAutoSkipped ? ExternalEmployeeStatus.SKIPPED : ExternalEmployeeStatus.UNMAPPED,
    mappingSkipReason: isAutoSkipped ? EmployeeMappingSkipReason.NOT_PERMANENT_EMPLOYEE : null,
    firstName: apiEmployee.first_name?.value,
    lastName: apiEmployee.last_name?.value,
    email: apiEmployee.email?.value,
    employeeNumber: customExternalId || apiEmployee.id.value.toString(),
    gender: value(() => {
      if (apiEmployee.gender?.value === GenderEnum.FEMALE) {
        return Gender.FEMALE;
      }
      if (apiEmployee.gender?.value === GenderEnum.MALE) {
        return Gender.MALE;
      }

      return null;
    }),
    hireDate: apiEmployee.hire_date?.value,
    company: {
      connect: { id: company.id },
    },
    currency: {
      connect: { code: currency.code },
    },
    ...location,
    ...job,

    ...(customLevel && {
      level: {
        connectOrCreate: {
          where: {
            companyId_externalId: {
              companyId: company.id,
              externalId: customLevel,
            },
          },
          create: {
            name: customLevel,
            externalId: customLevel,
            company: {
              connect: { id: company.id },
            },
          },
        },
      },
    }),
    ...(businessUnit && { businessUnit }),
  };

  const remunerationItems: EmployeeData["remunerationItems"] = value(() => {
    const numberMonths = getNumberOfMonth({
      externalId: location?.location?.connectOrCreate?.create?.externalId,
      additionalMonthRules: staticModels.additionalMonthRules,
      externalLocations: staticModels.externalLocations,
    });

    const customBaseSalaryRemunerationItem = buildCustomBaseSalaryRemunerationItem({
      customBaseSalary,
      baseSalaryCustomFieldFrequency,
      numberMonths,
      company,
      source: ExternalEmployeeSource.PERSONIO,
    });

    if (customBaseSalaryRemunerationItem) {
      return [customBaseSalaryRemunerationItem];
    }

    const amount = (apiEmployee.fix_salary?.value ?? 0) * 100;
    if (!amount) {
      return [];
    }

    const interval = apiEmployee.fix_salary_interval?.value ?? "";

    if (interval !== SalaryIntervalEnum.YEARLY && interval !== SalaryIntervalEnum.MONTHLY) {
      logWarn(ctx, `[personio] Unhandled Personio fix salary interval`, { interval });
    }

    return [
      {
        source: ExternalEmployeeSource.PERSONIO,
        externalId: "fix-salary",
        amount: Math.round(interval === SalaryIntervalEnum.MONTHLY ? amount * numberMonths : amount),
        numberMonths,
        status: ExternalRemunerationStatus.LIVE,
        nature: {
          connectOrCreate: {
            where: {
              companyId_source_externalId: {
                companyId: company.id,
                source: ExternalEmployeeSource.PERSONIO,
                externalId: "fix-salary",
              },
            },
            create: {
              source: ExternalEmployeeSource.PERSONIO,
              externalId: "fix-salary",
              name: "Fixed salary",
              mappedType: ExternalRemunerationType.FIXED_SALARY,
              company: {
                connect: {
                  id: company.id,
                },
              },
            },
          },
        },
      },
    ];
  });

  if (customVariable && parseInt(customVariable) && variableCustomFieldFrequency) {
    const multiplier = match(variableCustomFieldFrequency)
      .with(CompensationFrequency.MONTHLY, () => 12)
      .with(CompensationFrequency.QUARTERLY, () => 4)
      .with(CompensationFrequency.YEARLY, () => 1)
      .exhaustive();

    const amount = Math.round(parseInt(customVariable) * multiplier * 100);

    remunerationItems.push({
      source: ExternalEmployeeSource.PERSONIO,
      externalId: "variable-bonus",
      amount: amount,
      status: ExternalRemunerationStatus.LIVE,
      nature: {
        connectOrCreate: {
          where: {
            companyId_source_externalId: {
              companyId: company.id,
              source: ExternalEmployeeSource.PERSONIO,
              externalId: "variable-bonus",
            },
          },
          create: {
            source: ExternalEmployeeSource.PERSONIO,
            externalId: "variable-bonus",
            name: "Variable bonus",
            mappedType: ExternalRemunerationType.VARIABLE_BONUS,
            company: {
              connect: {
                id: company.id,
              },
            },
          },
        },
      },
    });
  }

  if (customRemunerationItemsValues.length > 0) {
    const customItems = compact(
      customRemunerationItemsValues.map((customRemunerationItem) =>
        mapCustomRemunerationItem(integrationSettings, customRemunerationItem)
      )
    );
    remunerationItems.push(...customItems);
  }

  return {
    input,
    picturePath: !ignoreProfilePicture
      ? await getPersonioProfilePicture(ctx, { integrationSettings, apiEmployee })
      : undefined,
    remunerationItems,
    additionalFieldValues: computeAdditionalFieldValuePayloads(company, additionalFieldValues),
    managerExternalId: apiEmployee.supervisor?.value?.attributes.id.value.toString(),
    holidayAllowanceValue,
    // If one day they support hourly rates, we just have to update this (cf. bamboo)
    ...(customFte && { fte: customFte, ignoreFte: false }),
  };
};

const assertSafeIntegrationSettings = (integrationSettings: IntegrationSettingsForSync) =>
  assertProps(integrationSettings, ["clientId", "clientSecret"]);

export type SafeIntegrationSettings = ReturnType<typeof assertSafeIntegrationSettings>;

export const getMappedPersonioEmployees = async (
  ctx: AppContext,
  company: Company,
  integrationSettings: IntegrationSettingsForSync,
  staticModels: StaticModels,
  ignoreProfilePicture = false
): Promise<EmployeeData[]> => {
  const safeIntegrationSettings = assertSafeIntegrationSettings(integrationSettings);

  const personioEmployees = await getPersonioEmployees(safeIntegrationSettings);

  return mapSeries(personioEmployees, (personioEmployee) =>
    mapPersonioUser(ctx, company, personioEmployee, safeIntegrationSettings, staticModels, ignoreProfilePicture)
  );
};

export const getRawPersonioEmployees = async (
  ctx: AppContext,
  company: Company,
  integrationSettings: IntegrationSettingsForSync
): Promise<PersonioApiEmployee[]> => {
  const safeIntegrationSettings = assertSafeIntegrationSettings(integrationSettings);

  return getPersonioEmployees(safeIntegrationSettings);
};
