import { map, mapSeries } from "bluebird";
import { chain } from "lodash";
import { type AppContext } from "~/lib/context";
import { getRequiredUser } from "~/lib/get-required-user";

export const savePerformanceReviewRatings = async (
  ctx: AppContext,
  params: { performanceReviewCycleId: number; externalEmployees: { id: number; performanceRating: string }[] }
) => {
  const { performanceReviewCycleId, externalEmployees } = params;

  const externalEmployeesByRating = chain(externalEmployees)
    .groupBy("performanceRating")
    .orderBy(([externalEmployee]) => externalEmployee?.performanceRating, "asc")
    .value();

  await mapSeries(externalEmployeesByRating, async (rating, position) => {
    const name = rating[0]?.performanceRating as string;

    const existingRating = await ctx.prisma.performanceReviewRating.findUnique({
      where: {
        performanceReviewCycleId_name: {
          name,
          performanceReviewCycleId,
        },
      },
      select: { id: true },
    });

    const ratingsToRemoveEmployeesFrom = await ctx.prisma.performanceReviewRating.findMany({
      where: {
        performanceReviewCycleId,
        externalEmployees: { some: { id: { in: rating.map((employee) => employee.id) } } },
      },
    });

    await map(
      ratingsToRemoveEmployeesFrom,
      async (ratingToRemoveEmployeesFrom) => {
        return ctx.prisma.performanceReviewRating.update({
          where: { id: ratingToRemoveEmployeesFrom.id },
          data: {
            externalEmployees: {
              disconnect: rating.map((employee) => ({ id: employee.id })),
            },
          },
        });
      },
      { concurrency: 10 }
    );

    if (existingRating) {
      return ctx.prisma.performanceReviewRating.update({
        where: { id: existingRating.id },
        data: {
          name,
          position,
          externalEmployees: {
            connect: rating.map((employee) => ({ id: employee.id })),
          },
        },
      });
    }

    return ctx.prisma.performanceReviewRating.create({
      data: {
        name,
        position,
        company: { connect: { id: getRequiredUser(ctx).companyId } },
        performanceReviewCycle: { connect: { id: performanceReviewCycleId } },
        externalEmployees: {
          connect: rating.map((employee) => ({ id: employee.id })),
        },
      },
    });
  });
};
