import { type Currency, type Employee, type ExternalEmployee, type Gender, type Image } from "@prisma/client";
import { chain, compact } from "lodash";
import { match } from "ts-pattern";
import { convertCurrency } from "~/lib/money";
import { type Predicate } from "~/lib/utils";
import { EmployeeLevel, getMergedLevels, LevelsMetaData } from "~/services/employee/employee-level";
import { publicUrl } from "~/services/image";

export type ComputeEmployeeCompensationInput = {
  baseSalary: number;
  fixedBonus: number | null;
  fixedBonusPercentage?: number | null;
  onTargetBonus: number | null;
  onTargetBonusPercentage?: number | null;
  currency: Pick<Currency, "code" | "euroExchangeRate">;
};

export type ComputeEmployeeCompensationOptions = {
  targetCurrency?: Pick<Currency, "code" | "euroExchangeRate">;
  measure?: Measure;
};

export type Measure =
  | "totalCash"
  | "baseSalary"
  | "variableCompensation"
  | "onTargetEarnings"
  | "onTargetBonus"
  | "fixedBonus";

/**
 * Computes a compensation metric for the given employee.
 * By default, the value will be converted to Euro. It can be overriden by
 * specificing `options.targetCurrency`
 */
export const computeEmployeeCompensation = (
  employee: ComputeEmployeeCompensationInput,
  options?: ComputeEmployeeCompensationOptions
): number => {
  const { measure = "totalCash", targetCurrency } = options ?? {};

  const value = match(measure)
    .with("baseSalary", () => {
      return employee.baseSalary;
    })
    .with("totalCash", () => {
      let totalCash = employee.baseSalary;

      if (employee.onTargetBonusPercentage) {
        totalCash += employee.baseSalary * employee.onTargetBonusPercentage;
      } else if (employee.onTargetBonus) {
        totalCash += employee.onTargetBonus;
      }

      if (employee.fixedBonusPercentage) {
        totalCash += employee.baseSalary * employee.fixedBonusPercentage;
      } else if (employee.fixedBonus) {
        totalCash += employee.fixedBonus;
      }

      return totalCash;
    })
    .with("variableCompensation", () => {
      return employee.onTargetBonus ?? 0;
    })
    .with("onTargetBonus", () => {
      return employee.onTargetBonus ?? 0;
    })
    .with("onTargetEarnings", () => {
      return employee.baseSalary + (employee.onTargetBonus ?? 0);
    })
    .with("fixedBonus", () => {
      return employee.fixedBonus ?? 0;
    })
    .exhaustive();

  const convertedAmount = targetCurrency
    ? convertCurrency(value, employee.currency, targetCurrency)
    : value / employee.currency.euroExchangeRate;

  return Math.round(convertedAmount);
};

export const isFromCompany = (companyIds: number[]): Predicate<Pick<Employee, "companyId">> => {
  return (employee): boolean => {
    return companyIds.includes(employee.companyId);
  };
};

export const isNotFromCompany = (companyIds: number[]): Predicate<Employee> => {
  return (employee): boolean => {
    return !companyIds.includes(employee.companyId);
  };
};

export const hasJob = (jobsIds: number[]): Predicate<{ jobId: number }> => {
  return (employee): boolean => {
    return jobsIds.includes(employee.jobId);
  };
};

export const hasLevel = (
  levels: EmployeeLevel[],
  employees: Pick<Employee, "companyId" | "level">[],
  { mergeAdvancedLevels }: { mergeAdvancedLevels: boolean } = { mergeAdvancedLevels: false }
): Predicate<{ companyId: number; level: EmployeeLevel }> => {
  const employeesWithLevelDetails = employees.map((employee) => ({
    employee,
    levelDetails: LevelsMetaData[employee.level],
  }));

  return (employee): boolean => {
    return levels.some((level) => {
      if (level === EmployeeLevel.MA0X) {
        const highestLevel = chain(employeesWithLevelDetails)
          .filter((row) => employee.companyId === row.employee.companyId)
          .filter(({ levelDetails }) => levelDetails.index >= LevelsMetaData.MANAGER.index)
          .maxBy(({ levelDetails }) => levelDetails.index)
          .value();

        if (!highestLevel) {
          return false;
        }

        return employee.level === highestLevel.employee.level;
      }

      const mergedLevels = getMergedLevels(level, { mergeAdvancedLevels });

      return mergedLevels.includes(employee.level);
    });
  };
};

export const hasLocation = (locationsIds: number[]): Predicate<{ locationId: number }> => {
  return (employee): boolean => {
    if (!locationsIds.length) {
      return true;
    }

    return locationsIds.includes(employee.locationId);
  };
};

export const hasCountry = (countryIds: number[]): Predicate<{ location: { countryId: number } }> => {
  return (employee): boolean => {
    if (!countryIds.length) {
      return true;
    }

    return countryIds.includes(employee.location.countryId);
  };
};

export const hasKnownGender = (): Predicate<Pick<Employee, "gender">> => {
  return (employee): boolean => {
    return employee.gender !== null;
  };
};

export const hasGender = (gender: Gender): Predicate<Pick<Employee, "gender">> => {
  return (employee): boolean => {
    return employee.gender === gender;
  };
};

export const formatEmployeeName = (
  externalEmployee: Pick<ExternalEmployee, "employeeNumber" | "firstName" | "lastName"> | null,
  { fallbackToEmployeeNumber = true } = {}
): string => {
  if (!externalEmployee) {
    return "N/A";
  }

  const parts = compact([externalEmployee.firstName, externalEmployee.lastName]);

  if (parts.length > 0) {
    return parts.join(" ");
  }

  if (fallbackToEmployeeNumber) {
    return `#${externalEmployee.employeeNumber}`;
  }

  return "";
};

const EMPLOYEE_PICTURE_PLACEHOLDER_COUNT = 13;

type Params = {
  picture?: Pick<Image, "path" | "width" | "height"> | null;
  pictureUrl?: string;
  placeholderNumber: number;
  size: number;
};

export const getEmployeePictureProps = ({ picture, pictureUrl, placeholderNumber, size }: Params) => {
  const placeholderId = (placeholderNumber % EMPLOYEE_PICTURE_PLACEHOLDER_COUNT) + 1;
  const src = picture ? publicUrl(picture) : pictureUrl ?? `/images/profiles/almost-human-${placeholderId}.svg`;

  if (!picture) {
    return { height: size, src, width: size };
  }

  const height = picture.height >= picture.width ? size : picture.height * (size / picture.width);
  const width = picture.height >= picture.width ? picture.width * (size / picture.height) : size;

  return { height, src, width };
};
