import { type Product } from "@prisma/client";
import { ApiFeatureAccessError } from "~/lib/api";
import { next, type MiddlewareContext } from "~/lib/middleware/middleware";
import {
  type NullableAuthenticatedUser,
  type RequiredAuthenticatedUser,
} from "~/services/auth/fetch-authenticated-user";
import { productToPermission } from "~/services/subscriptions/utils";

export const protectRouteWithSubscription = async (
  ctx: MiddlewareContext<{ user: RequiredAuthenticatedUser; impersonatingUser: NullableAuthenticatedUser }>,
  options: { product?: Product }
) => {
  if (!options.product || ctx.req.subscriptions[productToPermission(options.product)]) {
    return next(ctx);
  }

  throw new ApiFeatureAccessError(`User cannot access the product "${options.product}"`);
};
