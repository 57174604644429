import {
  EmployeeLevel as BaseEmployeeLevel,
  type Company,
  EmployeeStatus,
  HighestAvailableLevelSource,
} from "@prisma/client";
import { uniq } from "lodash";
import { type TFunction } from "next-i18next";
import { match } from "ts-pattern";
import { value } from "~/components/helpers";
import { type AppContext } from "~/lib/context";
import { type NullableAuthenticatedUser } from "~/services/auth/fetch-authenticated-user";
import { type FetchAllowedCompanyBenchmarkLevelsResult } from "~/services/benchmark-level/fetch-allowed-company-benchmark-levels";

export const LEVELS_MAP: Record<string, BaseEmployeeLevel> = {
  "Beginner": BaseEmployeeLevel.BEGINNER,
  "Junior": BaseEmployeeLevel.JUNIOR,
  "Intermediate": BaseEmployeeLevel.INTERMEDIATE,
  "Senior": BaseEmployeeLevel.SENIOR,
  "Staff": BaseEmployeeLevel.STAFF,
  "Principal": BaseEmployeeLevel.PRINCIPAL,
  "Team Lead": BaseEmployeeLevel.TEAM_LEAD,
  "Manager": BaseEmployeeLevel.MANAGER,
  "Head Of": BaseEmployeeLevel.HEAD_OF,
  "Director": BaseEmployeeLevel.DIRECTOR,
  "VP": BaseEmployeeLevel.VP,
  "C-Level": BaseEmployeeLevel.C_LEVEL,
} as const;

type IndividualLevelMetaData = {
  track: "INDIVIDUAL_CONTRIBUTOR";
  index: number;
  advanced: boolean;
  meta?: boolean;
};

type ManagerLevelMetaData = {
  track: "MANAGER";
  index: number;
  advanced: boolean;
  meta?: boolean;
};

type LevelMetaData = IndividualLevelMetaData | ManagerLevelMetaData;

type IndividualLevelDetails = IndividualLevelMetaData & {
  name: string;
  alias?: string;
  nameWithoutSeniority: string;
  scope: string[];
  scopeLevel: string;
  autonomy: string[];
  complexity: string;
  hints?: string[];
  advanced: boolean;
  meta?: boolean;
};

type ManagerLevelDetails = ManagerLevelMetaData & {
  name: string;
  alias?: string;
  nameWithoutSeniority: string;
  scope: string[];
  responsibilities: string[];
  hints?: string[];
  advanced: boolean;
  meta?: boolean;
};

type LevelDetails = IndividualLevelDetails | ManagerLevelDetails;

export const EmployeeLevel = {
  ...BaseEmployeeLevel,
  MA0X: "MA0X",
} as const;

export type EmployeeLevel = (typeof EmployeeLevel)[keyof typeof EmployeeLevel];

export type EmployeeTrack = "INDIVIDUAL_CONTRIBUTOR" | "MANAGER";

export const getLevelDefinitions = (t: TFunction): Record<EmployeeLevel, LevelDetails> => {
  return {
    BEGINNER: {
      name: t("components.employee.employee-level.level.advanced.BEGINNER.name"),
      nameWithoutSeniority: t("components.employee.employee-level.level.advanced.BEGINNER.name-without-seniority"),
      scopeLevel: t("components.employee.employee-level.level.advanced.BEGINNER.scope-level"),
      scope: [t("components.employee.employee-level.level.advanced.BEGINNER.scope")],
      autonomy: [
        t("components.employee.employee-level.level.advanced.BEGINNER.autonomy.very-limited"),
        t("components.employee.employee-level.level.advanced.BEGINNER.autonomy.daily-supervision"),
      ],
      complexity: t("components.employee.employee-level.level.advanced.BEGINNER.complexity"),
      ...(LevelsMetaData[EmployeeLevel.BEGINNER] as IndividualLevelMetaData),
    },
    JUNIOR: {
      name: t("components.employee.employee-level.level.advanced.JUNIOR.name"),
      nameWithoutSeniority: t("components.employee.employee-level.level.advanced.JUNIOR.name-without-seniority"),
      scopeLevel: t("components.employee.employee-level.level.advanced.JUNIOR.scope-level"),
      scope: [t("components.employee.employee-level.level.advanced.JUNIOR.scope")],
      autonomy: [
        t("components.employee.employee-level.level.advanced.JUNIOR.autonomy.limited"),
        t("components.employee.employee-level.level.advanced.JUNIOR.autonomy.require-supervision"),
      ],
      complexity: t("components.employee.employee-level.level.advanced.JUNIOR.complexity"),
      hints: [
        t("components.employee.employee-level.level.advanced.JUNIOR.hints.first-working-experience"),
        t("components.employee.employee-level.level.advanced.JUNIOR.hints.need-supervision"),
      ],
      ...(LevelsMetaData[EmployeeLevel.JUNIOR] as IndividualLevelMetaData),
    },
    INTERMEDIATE: {
      name: t("components.employee.employee-level.level.advanced.INTERMEDIATE.name"),
      nameWithoutSeniority: t("components.employee.employee-level.level.advanced.INTERMEDIATE.name-without-seniority"),
      scopeLevel: t("components.employee.employee-level.level.advanced.INTERMEDIATE.scope-level"),
      scope: [t("components.employee.employee-level.level.advanced.INTERMEDIATE.scope")],
      autonomy: [
        t("components.employee.employee-level.level.advanced.INTERMEDIATE.autonomy.rely-on-more-senior-people"),
        t("components.employee.employee-level.level.advanced.INTERMEDIATE.autonomy.receive-general-instructions"),
      ],
      complexity: t("components.employee.employee-level.level.advanced.INTERMEDIATE.complexity"),
      hints: [
        t("components.employee.employee-level.level.advanced.INTERMEDIATE.hints.complex-tasks"),
        t("components.employee.employee-level.level.advanced.INTERMEDIATE.hints.individual-initiatives"),
      ],
      ...(LevelsMetaData[EmployeeLevel.INTERMEDIATE] as IndividualLevelMetaData),
    },
    SENIOR: {
      name: t("components.employee.employee-level.level.advanced.SENIOR.name"),
      nameWithoutSeniority: t("components.employee.employee-level.level.advanced.SENIOR.name-without-seniority"),
      scopeLevel: t("components.employee.employee-level.level.advanced.SENIOR.scope-level"),
      scope: [
        t("components.employee.employee-level.level.advanced.SENIOR.scope.work-with-peers"),
        t("components.employee.employee-level.level.advanced.SENIOR.scope.often-mentoring"),
      ],
      autonomy: [t("components.employee.employee-level.level.advanced.SENIOR.autonomy")],
      complexity: t("components.employee.employee-level.level.advanced.SENIOR.complexity"),
      hints: [
        t("components.employee.employee-level.level.advanced.SENIOR.hints.mentor"),
        t("components.employee.employee-level.level.advanced.SENIOR.hints.take-ownership"),
        t("components.employee.employee-level.level.advanced.SENIOR.hints.manage-senior-stakeholder"),
      ],
      ...(LevelsMetaData[EmployeeLevel.SENIOR] as IndividualLevelMetaData),
    },
    STAFF: {
      name: t("components.employee.employee-level.level.advanced.STAFF.name"),
      alias: t("components.employee.employee-level.level.advanced.STAFF.alias"),
      nameWithoutSeniority: t("components.employee.employee-level.level.advanced.STAFF.name-without-seniority"),
      scopeLevel: t("components.employee.employee-level.level.advanced.STAFF.scope-level"),
      scope: [t("components.employee.employee-level.level.advanced.STAFF.scope")],
      autonomy: [t("components.employee.employee-level.level.advanced.STAFF.autonomy")],
      complexity: t("components.employee.employee-level.level.advanced.STAFF.complexity"),
      hints: [
        t("components.employee.employee-level.level.advanced.STAFF.hints.expert"),
        t("components.employee.employee-level.level.advanced.STAFF.hints.impact-the-company"),
        t("components.employee.employee-level.level.advanced.STAFF.hints.contribute-company-strategy"),
      ],
      ...(LevelsMetaData[EmployeeLevel.STAFF] as IndividualLevelMetaData),
    },
    PRINCIPAL: {
      name: t("components.employee.employee-level.level.advanced.PRINCIPAL.name"),
      alias: t("components.employee.employee-level.level.advanced.PRINCIPAL.alias"),
      nameWithoutSeniority: t("components.employee.employee-level.level.advanced.PRINCIPAL.name-without-seniority"),
      scopeLevel: t("components.employee.employee-level.level.advanced.PRINCIPAL.scope-level"),
      scope: [
        t("components.employee.employee-level.level.advanced.PRINCIPAL.act-as-strategic-leader"),
        t("components.employee.employee-level.level.advanced.PRINCIPAL.taking-critical-decisions"),
      ],
      autonomy: [t("components.employee.employee-level.level.advanced.PRINCIPAL.autonomy")],
      complexity: t("components.employee.employee-level.level.advanced.PRINCIPAL.complexity"),
      hints: [
        t("components.employee.employee-level.level.advanced.PRINCIPAL.hints.recognised-expert"),
        t("components.employee.employee-level.level.advanced.PRINCIPAL.hints.contribute-to-company-strategy"),
      ],
      ...(LevelsMetaData[EmployeeLevel.PRINCIPAL] as IndividualLevelMetaData),
    },
    TEAM_LEAD: {
      name: t("components.employee.employee-level.level.advanced.TEAM_LEAD.name"),
      nameWithoutSeniority: t("components.employee.employee-level.level.advanced.TEAM_LEAD.name-without-seniority"),
      scope: [
        t("components.employee.employee-level.level.advanced.TEAM_LEAD.scope.coordinate-supervise-activities"),
        t(
          "components.employee.employee-level.level.advanced.TEAM_LEAD.scope.still-owner-key-operational-responsibilities"
        ),
        t("components.employee.employee-level.level.advanced.TEAM_LEAD.scope.first-time-manager"),
      ],
      responsibilities: [
        t("components.employee.employee-level.level.advanced.TEAM_LEAD.responsibilities.responsible-good-execution"),
        t(
          "components.employee.employee-level.level.advanced.TEAM_LEAD.responsibilities.contributing-people-development"
        ),
      ],
      ...(LevelsMetaData[EmployeeLevel.TEAM_LEAD] as ManagerLevelMetaData),
    },
    MANAGER: {
      name: t("components.employee.employee-level.level.advanced.MANAGER.name"),
      nameWithoutSeniority: t("components.employee.employee-level.level.advanced.MANAGER.name-without-seniority"),
      scope: [
        t("components.employee.employee-level.level.advanced.MANAGER.scope.manage-individual-contributors"),
        t("components.employee.employee-level.level.advanced.MANAGER.scope.own-performance"),
        t("components.employee.employee-level.level.advanced.MANAGER.scope.still-own-operational-responsibilities"),
      ],
      responsibilities: [
        t("components.employee.employee-level.level.advanced.MANAGER.responsibilities.cascade-strategy"),
        t("components.employee.employee-level.level.advanced.MANAGER.responsibilities.lead-people-development"),
        t("components.employee.employee-level.level.advanced.MANAGER.responsibilities.role-model"),
      ],
      hints: [
        t("components.employee.employee-level.level.advanced.MANAGER.hints.bigger-team"),
        t("components.employee.employee-level.level.advanced.MANAGER.hints.manage-ic"),
        t("components.employee.employee-level.level.advanced.MANAGER.hints.full-autonomy"),
        t("components.employee.employee-level.level.advanced.MANAGER.hints.lead-people-growth"),
      ],
      ...(LevelsMetaData[EmployeeLevel.MANAGER] as ManagerLevelMetaData),
    },
    HEAD_OF: {
      name: t("components.employee.employee-level.level.advanced.HEAD_OF.name"),
      nameWithoutSeniority: t("components.employee.employee-level.level.advanced.HEAD_OF.name-without-seniority"),
      scope: [
        t("components.employee.employee-level.level.advanced.HEAD_OF.scope.own-performance"),
        t("components.employee.employee-level.level.advanced.HEAD_OF.scope.not-single-decision-maker"),
      ],
      responsibilities: [
        t("components.employee.employee-level.level.advanced.HEAD_OF.responsibilities.set-their-teams-roadmap"),
        t("components.employee.employee-level.level.advanced.HEAD_OF.responsibilities.propose-budget"),
        t("components.employee.employee-level.level.advanced.HEAD_OF.responsibilities.role-model"),
      ],
      hints: [
        t("components.employee.employee-level.level.advanced.HEAD_OF.hints.manage-several-teams"),
        t("components.employee.employee-level.level.advanced.HEAD_OF.hints.manage-ic"),
        t("components.employee.employee-level.level.advanced.HEAD_OF.hints.owner-roadmap"),
        t("components.employee.employee-level.level.advanced.HEAD_OF.hints.propose-budget"),
      ],
      ...(LevelsMetaData[EmployeeLevel.HEAD_OF] as ManagerLevelMetaData),
    },
    DIRECTOR: {
      name: t("components.employee.employee-level.level.advanced.DIRECTOR.name"),
      nameWithoutSeniority: t("components.employee.employee-level.level.advanced.DIRECTOR.name-without-seniority"),
      scope: [
        t("components.employee.employee-level.level.advanced.DIRECTOR.scope.manage-department"),
        t("components.employee.employee-level.level.advanced.DIRECTOR.scope.not-single-decision-maker"),
      ],
      responsibilities: [
        t("components.employee.employee-level.level.advanced.DIRECTOR.responsibilities.set-direction"),
        t("components.employee.employee-level.level.advanced.DIRECTOR.responsibilities.approve-staffing"),
        t("components.employee.employee-level.level.advanced.DIRECTOR.responsibilities.role-model"),
      ],
      hints: [
        t("components.employee.employee-level.level.advanced.DIRECTOR.hints.owns-department"),
        t("components.employee.employee-level.level.advanced.DIRECTOR.hints.manage-managers"),
        t("components.employee.employee-level.level.advanced.DIRECTOR.hints.vision-strategic-impact"),
        t("components.employee.employee-level.level.advanced.DIRECTOR.hints.approver-department"),
      ],
      ...(LevelsMetaData[EmployeeLevel.DIRECTOR] as ManagerLevelMetaData),
    },
    VP: {
      name: t("components.employee.employee-level.level.advanced.VP.name"),
      nameWithoutSeniority: t("components.employee.employee-level.level.advanced.VP.name-without-seniority"),
      scope: [
        t("components.employee.employee-level.level.advanced.VP.scope.manage-whole-department"),
        t("components.employee.employee-level.level.advanced.VP.scope.final-decision-maker"),
      ],
      responsibilities: [
        t("components.employee.employee-level.level.advanced.VP.responsibilities.set-direction"),
        t("components.employee.employee-level.level.advanced.VP.responsibilities.approve-staffing"),
        t("components.employee.employee-level.level.advanced.VP.responsibilities.role-model"),
      ],
      hints: [
        t("components.employee.employee-level.level.advanced.VP.hints.manage-several-layers"),
        t("components.employee.employee-level.level.advanced.VP.hints.impact-other-departments"),
        t("components.employee.employee-level.level.advanced.VP.hints.final-decision-maker"),
        t("components.employee.employee-level.level.advanced.VP.hints.exec-team-member"),
      ],
      ...(LevelsMetaData[EmployeeLevel.VP] as ManagerLevelMetaData),
    },
    C_LEVEL: {
      name: t("components.employee.employee-level.level.advanced.C_LEVEL.name"),
      nameWithoutSeniority: t("components.employee.employee-level.level.advanced.C_LEVEL.name-without-seniority"),
      scope: [t("components.employee.employee-level.level.advanced.C_LEVEL.scope.define-strategy")],
      responsibilities: [
        t("components.employee.employee-level.level.advanced.C_LEVEL.responsibilities.inspire-drive-strategy"),
        t("components.employee.employee-level.level.advanced.C_LEVEL.responsibilities.hold-direct-report-accountable"),
        t("components.employee.employee-level.level.advanced.C_LEVEL.responsibilities.role-model"),
      ],
      hints: [
        t("components.employee.employee-level.level.advanced.C_LEVEL.hints.contribute-and-influence"),
        t("components.employee.employee-level.level.advanced.C_LEVEL.hints.owns-her-scope"),
        t("components.employee.employee-level.level.advanced.C_LEVEL.hints.report-to-ceo-coo-board"),
      ],
      ...(LevelsMetaData[EmployeeLevel.C_LEVEL] as ManagerLevelMetaData),
    },
    MA0X: {
      name: t("components.employee.employee-level.level.advanced.MA0X.name"),
      nameWithoutSeniority: t("components.employee.employee-level.level.advanced.MA0X.name"),
      scope: [t("components.employee.employee-level.level.advanced.MA0X.scope")],
      responsibilities: [],
      ...(LevelsMetaData[EmployeeLevel.MA0X] as ManagerLevelMetaData),
    },
  };
};

export const LevelsMetaData: Record<EmployeeLevel, LevelMetaData> = {
  BEGINNER: {
    index: 1,
    track: "INDIVIDUAL_CONTRIBUTOR",
    advanced: true,
  },
  JUNIOR: {
    track: "INDIVIDUAL_CONTRIBUTOR",
    index: 2,
    advanced: false,
  },
  INTERMEDIATE: {
    track: "INDIVIDUAL_CONTRIBUTOR",
    index: 3,
    advanced: false,
  },
  SENIOR: {
    track: "INDIVIDUAL_CONTRIBUTOR",
    index: 4,
    advanced: false,
  },
  STAFF: {
    track: "INDIVIDUAL_CONTRIBUTOR",
    index: 5,
    advanced: false,
  },
  PRINCIPAL: {
    track: "INDIVIDUAL_CONTRIBUTOR",
    index: 6,
    advanced: true,
  },
  TEAM_LEAD: {
    track: "MANAGER",
    index: 7,
    advanced: false,
  },
  MANAGER: {
    track: "MANAGER",
    index: 8,
    advanced: true,
  },
  HEAD_OF: {
    track: "MANAGER",
    index: 9,
    advanced: false,
  },
  DIRECTOR: {
    track: "MANAGER",
    index: 10,
    advanced: true,
  },
  VP: {
    track: "MANAGER",
    index: 11,
    advanced: false,
  },
  C_LEVEL: {
    track: "MANAGER",
    index: 12,
    advanced: false,
  },
  MA0X: {
    track: "MANAGER",
    index: 13,
    meta: true,
    advanced: false,
  },
};

export const formatLevel = (t: TFunction, level: EmployeeLevel, useAdvancedLevels: boolean): string => {
  const advancedKey = `components.employee.employee-level.level.advanced.${level}.name`;
  const basicKey = `components.employee.employee-level.level.basic.${level}.name`;

  if (useAdvancedLevels) {
    return t(advancedKey, { defaultValue: level });
  } else {
    return t(basicKey, { defaultValue: level });
  }
};

export const formatBenchmarkLevel = (id: number, benchmarkLevels: FetchAllowedCompanyBenchmarkLevelsResult) => {
  if (!benchmarkLevels) {
    return "N/A";
  }

  return benchmarkLevels.find((level) => level.id === id)?.name ?? "N/A";
};

export const formatScopeLevel = (t: TFunction, level: EmployeeLevel): string[] => {
  return getLevelDefinitions(t)[level].scope;
};

export const getMappedLevel = (level: EmployeeLevel, { useAdvancedLevels }: { useAdvancedLevels: boolean }) => {
  if (useAdvancedLevels) {
    return level;
  }

  return match(level)
    .with(EmployeeLevel.BEGINNER, () => EmployeeLevel.JUNIOR)
    .with(EmployeeLevel.PRINCIPAL, () => EmployeeLevel.STAFF)
    .with(EmployeeLevel.MANAGER, () => EmployeeLevel.HEAD_OF)
    .with(EmployeeLevel.DIRECTOR, () => EmployeeLevel.VP)
    .otherwise(() => level);
};

export const getMergedLevels = (level: EmployeeLevel, { mergeAdvancedLevels }: { mergeAdvancedLevels: boolean }) => {
  if (!mergeAdvancedLevels) {
    return [level];
  }

  return match(level)
    .with(EmployeeLevel.BEGINNER, () => [EmployeeLevel.BEGINNER, EmployeeLevel.JUNIOR])
    .with(EmployeeLevel.JUNIOR, () => [EmployeeLevel.BEGINNER, EmployeeLevel.JUNIOR])
    .with(EmployeeLevel.STAFF, () => [EmployeeLevel.STAFF, EmployeeLevel.PRINCIPAL])
    .with(EmployeeLevel.PRINCIPAL, () => [EmployeeLevel.STAFF, EmployeeLevel.PRINCIPAL])
    .with(EmployeeLevel.MANAGER, () => [EmployeeLevel.MANAGER, EmployeeLevel.HEAD_OF])
    .with(EmployeeLevel.HEAD_OF, () => [EmployeeLevel.MANAGER, EmployeeLevel.HEAD_OF])
    .with(EmployeeLevel.DIRECTOR, () => [EmployeeLevel.DIRECTOR, EmployeeLevel.VP])
    .with(EmployeeLevel.VP, () => [EmployeeLevel.DIRECTOR, EmployeeLevel.VP])
    .otherwise(() => [level]);
};

export const getLevelMetaData = (level: EmployeeLevel): IndividualLevelMetaData | ManagerLevelMetaData => {
  return LevelsMetaData[level];
};

export const formatTrack = (t: TFunction, track: EmployeeTrack): string => {
  return t(`components.employee.employee-level.track.${track}`);
};

export const getAllowedLevels = (user: NullableAuthenticatedUser, mergeAdvancedLevels?: boolean): EmployeeLevel[] => {
  const initialLevels =
    !user || !user.permissions.allowedLevels.length ? Object.values(EmployeeLevel) : user.permissions.allowedLevels;

  const allowedLevels = uniq(
    initialLevels.flatMap((level) => {
      return getMergedLevels(level, { mergeAdvancedLevels: !!mergeAdvancedLevels });
    })
  );

  const hasOneAllowedManagerLevel = allowedLevels.some((level) => {
    return LevelsMetaData[level].track === "MANAGER";
  });
  if (hasOneAllowedManagerLevel) {
    return [...allowedLevels, "MA0X"];
  }

  return allowedLevels;
};

export const canAccessLevel = (t: TFunction, user: NullableAuthenticatedUser, level: EmployeeLevel) => {
  const userCanAccessLevel = value(() => {
    const allowedLevels = user?.permissions.allowedLevels ?? [];

    if (!allowedLevels.length) {
      return true;
    }

    if (level === "MA0X") {
      return allowedLevels.some((level) => {
        return ["MA04", "MA05", "MA06", "MA07", "MA08"].includes(level);
      });
    }

    return allowedLevels.includes(level);
  });

  const companyCanAccessLevel = value(() => {
    const levelDetails = getLevelMetaData(level);
    const highestAvailableLevel = user?.company.highestAvailableLevel;

    if (!highestAvailableLevel) {
      return true;
    }

    const highestAvailableLevelDetails = getLevelMetaData(highestAvailableLevel);

    if (level === "MA0X" && highestAvailableLevelDetails.index >= 7) {
      return true;
    }

    return levelDetails.index <= highestAvailableLevelDetails.index;
  });

  return userCanAccessLevel && companyCanAccessLevel;
};

export const updateCompanyHighestAvailableLevel = async (ctx: AppContext, company: Company) => {
  const employeeWithHighestLevel = await ctx.prisma.employee.findFirst({
    select: { level: true },
    where: { status: EmployeeStatus.LIVE, companyId: company.id },
    orderBy: { level: "desc" },
  });

  if (!employeeWithHighestLevel) {
    return null;
  }

  const highestAvailableLevel = employeeWithHighestLevel.level;

  await ctx.prisma.company.update({
    where: { id: company.id },
    data: {
      highestAvailableLevel,
      highestAvailableLevelSource: HighestAvailableLevelSource.AUTOMATIC,
    },
  });

  return highestAvailableLevel;
};

export const updateCompanyUseExternalLevel = async (ctx: AppContext, company: Company) => {
  const employeesWithoutExternalLevelsCount = await ctx.prisma.employee.count({
    where: {
      status: EmployeeStatus.LIVE,
      companyId: company.id,
      externalLevel: null,
    },
  });

  const useExternalLevels = employeesWithoutExternalLevelsCount === 0;

  await ctx.prisma.company.update({
    where: { id: company.id },
    data: {
      useExternalLevels,
      useExternalLevelsSource: "AUTOMATIC",
    },
  });

  return useExternalLevels;
};
