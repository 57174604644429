import { mapSeries } from "bluebird";
import { type AppContext } from "~/lib/context";
import { logInfo } from "~/lib/logger";
import { type UpdateMappingSchemaInput } from "~/pages/api/update-mapping";
import { matchLevelWithBenchmarkLevel } from "~/services/benchmark-level/match-level-with-benchmark-level";

export const mapExternalLevels = async (
  ctx: AppContext,
  externalLevels: UpdateMappingSchemaInput["externalLevels"],
  companyId: number
) => {
  logInfo(ctx, "[mapping] Handling external levels", { companyId, externalLevels });

  const impactedEmployeeIds: number[] = [];

  await mapSeries(externalLevels ?? [], async (level) => {
    const benchmarkLevel = await matchLevelWithBenchmarkLevel(ctx, { level: level.mappedLevel });
    // Find the impacted employees first
    const originalLevel = await ctx.prisma.externalLevel.findFirst({
      where: { id: level.externalLevelId, companyId: companyId },
      include: { employees: { include: { mappedEmployee: true } } },
    });
    if (!originalLevel) {
      return;
    }
    originalLevel.employees.forEach((employee) => {
      // Unmapped employees & employees with unmapped locations are all impacted
      if (!originalLevel.mappedLevel || !employee.mappedEmployee) {
        impactedEmployeeIds.push(employee.id);
        return;
      }

      // Employees mapped to the original level are all impacted
      // (meaning we exclude employees who might have been remapped manually to another level)
      if (employee.mappedEmployee.level === originalLevel.mappedLevel) {
        impactedEmployeeIds.push(employee.id);
      }
    });
    await ctx.prisma.externalLevel.update({
      where: { id: level.externalLevelId },
      data: {
        mappedLevel: level.mappedLevel,
        mappedBenchmarkLevel: { connect: { id: benchmarkLevel?.id } },
        skippedAt: null,
      },
    });
  });

  logInfo(ctx, "[mapping] Handled external levels", { companyId, externalLevels });

  return impactedEmployeeIds;
};
