import { OffCycleReviewRequestStatus } from "@prisma/client";
import { mapSeries } from "bluebird";
import { config } from "~/config";
import { type AppContext } from "~/lib/context";
import { sendOffCycleReviewRequestedEmail } from "~/lib/external/sendinblue/templates";
import { getRequiredUser } from "~/lib/get-required-user";
import { type RequestOffCycleReviewInput } from "~/pages/api/compensation-review/off-cycle-reviews/request-off-cycle-review";
import { fetchCompensationOffCycleReviewContext } from "~/services/compensation-review/compensation-review-context";
import { fetchOffCycleReviewConfiguration } from "~/services/compensation-review/off-cycle-reviews/fetch-off-cycle-review-configuration";
import { fetchCompensationReviewAdmins } from "~/services/compensation-review/shared/fetch-compensation-review-admins";
export const requestOffCycleReview = async (ctx: AppContext, input: RequestOffCycleReviewInput) => {
  const user = getRequiredUser(ctx);

  const configuration = await fetchOffCycleReviewConfiguration(ctx);

  const targetRange = input.targetRangeId
    ? await ctx.prisma.salaryRange.findFirst({ where: { id: input.targetRangeId } })
    : null;

  const request = await ctx.prisma.offCycleReviewRequest.create({
    data: {
      configurationId: configuration.id,
      companyId: user.companyId,
      userId: user.id,
      externalEmployeeId: input.externalEmployeeId,
      status: OffCycleReviewRequestStatus.PENDING,
      reason: input.reason,
      targetRangeAfterPromotionId: targetRange?.id ?? null,
      effectiveDate: input.effectiveDate,
      comment: input.comment,
    },
    include: {
      externalEmployee: true,
      user: true,
    },
  });

  const offCycleReviewContext = await fetchCompensationOffCycleReviewContext(ctx);
  const admins = await fetchCompensationReviewAdmins(offCycleReviewContext, { scope: offCycleReviewContext.scope });

  await mapSeries(admins, async (admin, index) => {
    if (!config.app.isJest && !config.app.isProduction && !!index) return;

    await sendOffCycleReviewRequestedEmail(ctx, {
      user: admin,
      request,
    });
  });
};
