import {
  DataValidationFlagOrigin,
  type Employee,
  EmployeeSource,
  type EmployeeUpdateStrategy,
  type ExternalEmployee,
} from "@prisma/client";
import { capitalize } from "lodash";
import { mixed, number, object, string } from "yup";
import { type AppContext } from "~/lib/context";
import { normaliseExternalEmployeeColumns } from "~/lib/external/external-employee";
import { getRequiredUser } from "~/lib/get-required-user";
import { logInfo } from "~/lib/logger";
import { type YupOutputType } from "~/lib/utils";
import { createMissingBenchmarkLevel } from "~/services/benchmark-level/create-missing-benchmark-level";
import { createEmployeeFlags } from "~/services/employee-data-validation-flag/data-flagging";
import {
  createHistoricalEmployee,
  createRemunerationItemsForExternalEmployee,
  mapInput,
  mapInputForExternalEmployee,
} from "~/services/employee/employee-create";
import { CreateEmployeeSchema } from "~/services/employee/employee-schemas";
export const EmployeeUpdateSchema = CreateEmployeeSchema.concat(
  object({
    reason: string().required(),
    updateStrategy: mixed<EmployeeUpdateStrategy>().oneOf(["NEW_VERSION", "PATCH"]).required(),
    performanceReviewRatingId: number()
      .nullable()
      .transform((value) => (value === -1 ? null : value)),
  })
);

export type UpdateInput = YupOutputType<typeof EmployeeUpdateSchema>;

export const updateEmployee = async (ctx: AppContext, employeeId: number, input: UpdateInput): Promise<Employee> => {
  const user = getRequiredUser(ctx);

  const previousEmployee = await ctx.prisma.employee.findFirstOrThrow({
    where: {
      id: employeeId,
      status: "LIVE",
      ...(!user.isSuperAdmin && {
        companyId: user.companyId,
      }),
    },
    include: {
      company: true,
      externalEmployee: true,
    },
  });

  logInfo(ctx, "[employee] Updating employee", {
    employeeId,
    employeeStatus: previousEmployee.status,
    reason: input.reason,
  });

  if (input.updateStrategy === "NEW_VERSION") {
    // The HISTORICAL employee inherits the liveAt of the LIVE employee
    await createHistoricalEmployee(ctx, employeeId);
  }

  const payload = await mapInput(ctx, input, {
    companyId: previousEmployee.companyId,
    source: previousEmployee.source,
    externalId: previousEmployee.externalId,
    externalJobTitle: previousEmployee.externalJobTitle ?? undefined,
    updateReason: input.reason,
  });

  if (!!previousEmployee.externalEmployee) {
    await updateExternalEmployee(ctx, previousEmployee.externalEmployee, input);
  }

  const updatedEmployee = await ctx.prisma.employee.update({
    where: { id: previousEmployee.id },
    data: {
      ...payload,
      // This new version of the employee is considered LIVE starting now
      liveAt: new Date(),
    },
    include: {
      externalEmployee: true,
      job: true,
      location: true,
    },
  });

  if (updatedEmployee.source === EmployeeSource.MANUAL && !!updatedEmployee.externalEmployee) {
    const externalEmployeePayload = mapInputForExternalEmployee(
      input,
      {
        companyId: updatedEmployee.companyId,
        source: updatedEmployee.source,
        externalId: updatedEmployee.externalId,
        updateReason: input.reason,
      },
      {
        job: updatedEmployee.job,
        location: updatedEmployee.location,
        employeeNumber: updatedEmployee.employeeNumber,
      }
    );

    const externalEmployee = await ctx.prisma.externalEmployee.update({
      where: { id: updatedEmployee.externalEmployee.id },
      data: externalEmployeePayload,
    });

    await normaliseExternalEmployeeColumns(ctx, {
      externalEmployee: { id: updatedEmployee.externalEmployee.id },
    });

    await createRemunerationItemsForExternalEmployee(
      ctx,
      input,
      {
        companyId: updatedEmployee.companyId,
        source: updatedEmployee.source,
      },
      {
        externalEmployeeId: externalEmployee.id,
        fixedBonus: updatedEmployee.fixedBonus,
        onTargetBonus: updatedEmployee.onTargetBonus,
      }
    );
  }

  // (flag) eligible for pub/sub
  await createEmployeeFlags(ctx, updatedEmployee.id, user.companyId, DataValidationFlagOrigin.HRIS_SYNC);

  logInfo(ctx, "[employee] Successfully updated employee", { employeeId });

  return updatedEmployee;
};

const updateExternalEmployee = async (
  ctx: AppContext,
  externalEmployee: ExternalEmployee,
  input: Pick<UpdateInput, "externalLevel" | "level" | "performanceReviewRatingId">
) => {
  const externalLevel = input.externalLevel ?? capitalize(input.level);

  const benchmarkLevel = await createMissingBenchmarkLevel(ctx, {
    figuresLevel: input.level,
  });

  const levelPayload = !!externalLevel && {
    level: {
      connectOrCreate: {
        where: {
          companyId_externalId: { companyId: externalEmployee.companyId, externalId: externalLevel },
        },
        create: {
          name: externalLevel,
          externalId: externalLevel,
          company: { connect: { id: externalEmployee.companyId } },
          mappedLevel: input.level,
          mappedBenchmarkLevel: { connect: { id: benchmarkLevel?.id } },
        },
      },
    },
  };
  const performanceReviewRatingPayload = !!input.performanceReviewRatingId && {
    performanceReviewRating: { connect: { id: input.performanceReviewRatingId } },
  };

  if (!levelPayload && !performanceReviewRatingPayload) return;

  await ctx.prisma.externalEmployee.update({
    where: { id: externalEmployee.id },
    data: { ...levelPayload, ...performanceReviewRatingPayload },
  });
};
