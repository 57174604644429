import { KeyboardArrowDown } from "@mui/icons-material";
import { Stack, Typography } from "@mui/material";
import classNames from "classnames";
import React, { type RefObject } from "react";

type Props = {
  title?: string;
  description?: string | JSX.Element;
  subHeader?: JSX.Element;
  actions?: JSX.Element;
  leftActions?: JSX.Element;
  expanded?: boolean;
  onExpandedChange?: () => void;
  headerRef?: RefObject<HTMLDivElement>;
};

export const CardHeader: React.FC<Props> = ({
  title,
  description,
  subHeader,
  actions,
  leftActions,
  expanded,
  onExpandedChange,
  headerRef,
}) => {
  return (
    <Stack
      ref={headerRef}
      className={classNames({
        "group -mx-5 -mt-4 pt-4": true,
        "border-b": expanded !== false,
        "cursor-pointer transition hover:bg-gray-50": onExpandedChange !== undefined,
      })}
      onClick={onExpandedChange}
    >
      <Stack
        className="pb-4 pr-4 pl-5"
        direction="row"
        columnGap={8}
        rowGap={4}
        justifyContent="space-between"
        alignItems="start"
        flexWrap="wrap"
      >
        <Stack columnGap={4} rowGap={4} alignItems="center" direction="row" flexWrap="wrap">
          {expanded !== undefined && (
            <KeyboardArrowDown
              className={classNames({
                "text-base text-gray-500 transition group-hover:scale-125": true,
                "-rotate-90": !expanded,
              })}
            />
          )}

          {(title || description) && (
            <Stack spacing={1}>
              {title && <Typography variant="h3">{title}</Typography>}
              {description && (
                <Typography variant="caption" color="text.secondary" className="max-w-2xl whitespace-pre-wrap">
                  {description}
                </Typography>
              )}
            </Stack>
          )}
          {leftActions}
        </Stack>
        {actions}
      </Stack>
      {subHeader && <Stack className="border-t">{subHeader}</Stack>}
    </Stack>
  );
};
