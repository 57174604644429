import {
  CompensationReviewCampaignStatus,
  CompensationReviewConfigurationStep,
  CompensationReviewSalaryDisplay,
} from "@prisma/client";
import { type AppContext, transaction } from "~/lib/context";
import { ensure } from "~/lib/ensure";
import { getRequiredUser } from "~/lib/get-required-user";
import { refreshCampaignCurrencies } from "~/services/compensation-review/campaigns/admin/refresh-campaign-currencies";
import { sendCreateCompensationReviewCampaignEmployeesJob } from "~/workers/create-compensation-review-campaign-employees";

export const DEFAULT_MAX_REVIEWERS_COUNT = 2;

export const MAX_MAX_REVIEWERS_COUNT = 10;

export const createCampaign = async (ctx: AppContext) => {
  ensure(() => ctx.globalPermissionsContext.canCreateCompensationReviewCampaign);

  const user = getRequiredUser(ctx);

  return transaction(ctx, async (ctx) => {
    const campaignsCount = await ctx.prisma.compensationReviewCampaign.count({
      where: { companyId: user.companyId },
    });

    const budget = await ctx.prisma.compensationReviewBudget.create({
      data: {},
      select: { id: true },
    });

    const promotionBudget = await ctx.prisma.compensationReviewPromotionBudget.create({
      data: {},
      select: { id: true },
    });

    const campaign = await ctx.prisma.compensationReviewCampaign.create({
      data: {
        companyId: user.companyId,
        budgetId: budget.id,
        promotionBudgetId: promotionBudget.id,
        name: `Campaign ${campaignsCount + 1}`,
        status: CompensationReviewCampaignStatus.CONFIGURATION,
        configurationStep: CompensationReviewConfigurationStep.CAMPAIGN_DETAILS,
        validatedConfigurationSteps: [],
        requiresSandboxReset: false,
        maxReviewersCount: DEFAULT_MAX_REVIEWERS_COUNT,
        launchNotificationEnabled: false,
        reminderNotificationEnabled: false,
        variablePayReviewEnabled: true,
        relativeVariablePayEnabled: false,
        annualPerformanceBonusReviewEnabled: true,
        reviewerAnnualPerformanceBonusReviewEnabled: true,
        exceptionalBonusReviewEnabled: true,
        preferedSalaryDisplay: CompensationReviewSalaryDisplay.ON_TARGET_EARNINGS,
      },
      select: { id: true, companyId: true },
    });

    const { campaignCurrency } = await refreshCampaignCurrencies(ctx, { campaign });

    await ctx.prisma.compensationReviewCampaign.update({
      where: { id: campaign.id },
      data: { currencyId: campaignCurrency.id },
    });

    await sendCreateCompensationReviewCampaignEmployeesJob(ctx, {
      companyId: user.companyId,
      campaignId: campaign.id,
    });

    return campaign;
  });
};
