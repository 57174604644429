import { ExternalEmployeeStatus } from "@prisma/client";
import { compact, mapValues, pickBy } from "lodash";
import { number } from "yup";
import { type AppContext } from "~/lib/context";
import { getRequiredUser } from "~/lib/get-required-user";
import { getId, isPossibleDate, percentageToDecimalSchema, tryCast } from "~/lib/utils";
import { readXlsxFile, xlsxSheetToJson } from "~/lib/xlsx";
import { getNatureByValues } from "~/services/additional-field/get-additional-fields-nature";
const SHEET_NAME = "Employees";

// Make exhaustive list of non-additional field columns
const COLUMNS_MAP = {
  id: "Figures #",
  employeeNumber: "Employee #",
  name: "Name",
  location: "Location",
  job: "Job",
  level: "Level",
};

type JsonRow = {
  [key: string]: string;
};

const remapKeys = (row: JsonRow) => {
  return mapValues(COLUMNS_MAP, (inputKey) => {
    return row[inputKey];
  });
};

export const castStringAsMultipleTypes = (inputStr: string) => {
  return {
    stringValue: inputStr,
    dateValue: tryCast(isPossibleDate, inputStr),
    numberValue: tryCast(number(), inputStr),
    percentageValue: tryCast(percentageToDecimalSchema, inputStr, { assert: false }),
  };
};

export type CastStringAsMultipleTypes = ReturnType<typeof castStringAsMultipleTypes>;

const getAdditionalFields = (row: JsonRow) => {
  const rows = pickBy(row, (value, key) => {
    return !Object.values(COLUMNS_MAP).includes(key) && !key.includes("__EMPTY");
  });

  return mapValues(rows, castStringAsMultipleTypes);
};

export const parseAdditionalFieldsSpreadsheet = async (
  ctx: AppContext,
  params: {
    spreadsheetPath: string;
  }
) => {
  const user = getRequiredUser(ctx);

  /**
   * Parse spreadsheet
   */
  const spreadsheet = readXlsxFile(ctx, params.spreadsheetPath);

  const worksheet = spreadsheet.Sheets[SHEET_NAME];
  if (!worksheet) {
    return {
      status: "error",
      error: ctx.t(
        "services.additional-field.parse-additional-fields-spreadsheet.spreadsheet-should-have-a-worksheet-named-sheet_name",
        {
          sheetName: SHEET_NAME,
        }
      ),
    } as const;
  }
  const baseRows = xlsxSheetToJson(ctx, worksheet, { range: 1, raw: false }) as { [key: string]: string }[];

  // Get unique additional field names
  const columnsArray = xlsxSheetToJson(ctx, worksheet, { range: 1, header: 1 })[0] as string[];
  const uniqueAdditionalFieldNames = columnsArray.filter((column) => !Object.values(COLUMNS_MAP).includes(column));

  // get all employees id in the spreadsheet and transform them into numbers
  const employeesId = baseRows
    .map((row) => row[COLUMNS_MAP.id])
    .filter((id) => !!id)
    .map((id) => parseInt(id as string));

  const mappedRows = baseRows.map((row) => ({
    ...remapKeys(row as JsonRow),
    additionalFields: getAdditionalFields(row as JsonRow),
  }));

  const validRows = mappedRows.filter((row) => !!row.id).map((row) => ({ ...row, id: parseInt(row.id as string) }));
  const nonSkippedEmployees = await ctx.prisma.externalEmployee.findMany({
    where: {
      status: { not: ExternalEmployeeStatus.SKIPPED },
      companyId: user.companyId,
    },
    select: {
      id: true,
    },
  });
  const nonSkippedEmployeesIds = nonSkippedEmployees.flatMap(getId);

  const rowsWithMatchingEmployee = validRows.filter((row) => row.id && nonSkippedEmployeesIds.includes(row.id));
  const rowsWithAdditionalField = rowsWithMatchingEmployee.filter(
    (row) => !!Object.values(row.additionalFields).filter((value) => value.stringValue !== "").length
  );

  if (!rowsWithMatchingEmployee.length) {
    return {
      status: "error",
      error: ctx.t(
        "services.additional-field.parse-additional-fields-spreadsheet.could-not-find-any-valid-employee-ids"
      ),
    } as const;
  }

  if (!rowsWithAdditionalField.length && !employeesId.length && !uniqueAdditionalFieldNames.length) {
    return {
      status: "error",
      error: ctx.t(
        "services.additional-field.parse-additional-fields-spreadsheet.no-valid-employees-with-additional-field"
      ),
    } as const;
  }

  const unknownEmployeesRowsCount = validRows.length - rowsWithMatchingEmployee.length;
  const rowsWithoutAdditionalFieldCount = rowsWithMatchingEmployee.length - rowsWithAdditionalField.length;
  const natures = getNatureByValues(rowsWithAdditionalField.map((row) => row.additionalFields));

  return {
    status: "success",
    rows: rowsWithAdditionalField,
    uniqueAdditionalFieldNames,
    additionalFieldNatures: natures,
    ids: compact(employeesId),
    warnings: {
      unknownEmployeesRowsCount,
      rowsWithoutAdditionalFieldCount,
    },
  } as const;
};
