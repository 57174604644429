import { AuthenticationError } from "~/lib/api";
import { done, next, type MiddlewareContext } from "~/lib/middleware/middleware";
import { SessionKey } from "~/lib/session";

export const fetchUserIdFromSession = async (ctx: MiddlewareContext, options: { optional: boolean }) => {
  const userId = ctx.req.session.get<string>(SessionKey.USER_ID);

  if (userId) {
    return next({ userId });
  }

  if (!userId && !options.optional) {
    throw new AuthenticationError("Unauthorised access", {
      targetUrl: ctx.resolvedUrl,
      error: "no_session",
    });
  }

  return done();
};
