import {
  type Company,
  type Country,
  type ExternalEmployeeSource,
  ExternalEmployeeStatus,
  type IntegrationSettings,
  type Prisma,
} from "@prisma/client";
import { mapSeries } from "bluebird";
import { isNumber, isString } from "lodash";
import { array, boolean, mixed, number, object, string } from "yup";
import { type AppContext } from "~/lib/context";
import { type RawHolidayAllowanceValue } from "~/lib/hris/helpers/clean-holiday-allowance-value";
import { type CastStringAsMultipleTypes } from "~/services/additional-field/parse-additional-fields-spreadsheet";
import { deleteExternalEmployee } from "~/services/external-employee/delete-external-employee";

export type ExternalRemunerationItemWithoutEmployee = Omit<Prisma.ExternalRemunerationItemCreateInput, "employee">;

export type EmployeeData = {
  input: Prisma.ExternalEmployeeUpsertArgs["create"];
  remunerationItems: ExternalRemunerationItemWithoutEmployee[];
  additionalFieldValues?: ({
    company: { connect: { id: number } };
    additionalField: { connect: { id: number } };
    additionalFieldMapping: { connect: { id: number } } | null;
    stringValue: string | null;
  } & CastStringAsMultipleTypes)[];
  picturePath?: string;
  managerExternalId?: string | null;
  holidayAllowanceValue?: RawHolidayAllowanceValue;
  fte?: string;
  ignoreFte?: boolean;
};

// For input & remunerationItems, we don't really do any runtime validation but just trust the Prisma type
export const EmployeeDataSchema = object({
  input: mixed<Prisma.ExternalEmployeeUpsertArgs["create"]>().required(),
  remunerationItems: mixed<EmployeeData["remunerationItems"]>().required(),
  additionalFieldValues: array().of(
    object({
      company: object({ connect: object({ id: number().required() }).required() }).required(),
      additionalField: object({ connect: object({ id: number().required() }).required() }).required(),
      additionalFieldMapping: object({ connect: object({ id: number().required() }).required() }).required(),
      stringValue: string().nullable().required(),
      dateValue: mixed().nullable().optional(),
      numberValue: mixed().nullable().optional(),
      percentageValue: mixed().nullable().optional(),
    }).required()
  ),
  picturePath: string(),
  managerExternalId: string().nullable(),
  holidayAllowanceValue: mixed<EmployeeData["holidayAllowanceValue"]>().nullable(),
  fte: string(),
  ignoreFte: boolean(),
});

const customRemunerationItemSelect = {
  id: true,
  hrisFieldName: true,
  name: true,
  frequency: true,
  type: true,
  subtype: true,
} satisfies Prisma.CustomRemunerationItemSelect;

export const integrationSettingsForSyncInclude = {
  additionalFieldMappings: {
    select: { id: true, additionalFieldId: true, hrisFieldName: true },
  },
  integrationLock: { select: { onTargetBonus: true, fteDivider: true, fixedBonus: true } },
  customRemunerationItems: {
    select: customRemunerationItemSelect,
  },
} satisfies Prisma.IntegrationSettingsInclude;

type IntegrationSettingsRelationsForSync = Prisma.IntegrationSettingsGetPayload<{
  include: typeof integrationSettingsForSyncInclude;
}>;

export type CustomRemunerationItemForSync = Prisma.CustomRemunerationItemGetPayload<{
  select: typeof customRemunerationItemSelect;
}>;

export type IntegrationSettingsForSync = IntegrationSettings & IntegrationSettingsRelationsForSync;

export type CompanyForSync = Company & {
  integrationSettings: IntegrationSettingsForSync[];
  defaultCountry: Country;
};

export const handleDeletedEmployees = async (
  ctx: AppContext,
  options: {
    companyId: number;
    source?: ExternalEmployeeSource;
    employeeNumbers?: string[];
    externalIds?: string[];
    ignoreSkipped?: boolean;
  }
): Promise<number> => {
  const deletedEmployees = await ctx.prisma.externalEmployee.findMany({
    where: {
      companyId: options.companyId,

      ...(options.ignoreSkipped && { status: { not: ExternalEmployeeStatus.SKIPPED } }),

      ...(options.source && {
        source: options.source,
      }),

      ...(options.employeeNumbers && {
        employeeNumber: { notIn: options.employeeNumbers },
      }),

      ...(options.externalIds && {
        externalId: { notIn: options.externalIds },
      }),
    },
    select: { id: true },
  });

  await mapSeries(deletedEmployees, async (externalEmployee) => deleteExternalEmployee(ctx, externalEmployee.id));

  return deletedEmployees.length;
};

export const guessFteFactor = (employeeData: Pick<EmployeeData, "ignoreFte" | "fte">[]) => {
  const fteData = employeeData
    .filter(
      (employee) =>
        !employee.ignoreFte && isString(employee.fte) && isNumber(parseFloat(employee.fte.replace(",", ".")))
    )
    .map((employee) => parseFloat(employee.fte as string));

  const aboveUnitRange = fteData.some((fte) => fte > 1);

  if (aboveUnitRange) {
    return 100;
  }

  return 1;
};
