// eslint-disable-next-line no-restricted-imports
import { type FormikValues, useFormik } from "formik";
import { useI18n } from "~/lib/i18n/use-i18n";
import { translateFormikErrors } from "~/lib/i18n/yup-errors";

export type UseFormikParams<Values extends FormikValues = FormikValues> = Parameters<typeof useFormik<Values>>;

export type Form<Values extends FormikValues = FormikValues> = ReturnType<typeof useForm<Values>>;

export const useForm = <Values extends FormikValues = FormikValues>(...params: UseFormikParams<Values>) => {
  const { t } = useI18n();
  const form = useFormik<Values>(...params);

  return {
    ...form,
    errors: translateFormikErrors(t, form.errors),

    // carefully consider the side effects of this method as it void the underlying formik async setValues
    // https://github.com/figures-hr/figures/pull/4926#discussion_r1670053389
    updateValues: (values: Partial<Values>) => {
      void form.setValues({ ...form.values, ...values });
    },
    asyncUpdateValues: async (values: Partial<Values>) => {
      return form.setValues({ ...form.values, ...values });
    },
  };
};
