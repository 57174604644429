import { next, type MiddlewareContext } from "~/lib/middleware/middleware";
import { initImpersonationContext, SessionKey } from "~/lib/session";
import {
  type NullableAuthenticatedUser,
  type RequiredAuthenticatedUser,
} from "~/services/auth/fetch-authenticated-user";

export const updateImpersonationContext = async (
  ctx: MiddlewareContext<{ user: RequiredAuthenticatedUser; impersonatingUser: NullableAuthenticatedUser }>
) => {
  const companyId = ctx.req.session.get<string>(SessionKey.IMPERSONATED_COMPANY_ID);

  initImpersonationContext(ctx.req, {
    user: ctx.user,
    impersonatingUser: ctx.impersonatingUser,
    impersonatingCompanyId: companyId ? +companyId : null,
  });

  return next(ctx);
};
