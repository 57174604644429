import { cloneDeep } from "lodash";
import { type AppContext } from "~/lib/context";
import { resetContextInitialisedFlag } from "~/lib/prisma-tokens";

export const cloneContext = (ctx: AppContext) => {
  const newCtx = Object.assign({}, cloneDeep(ctx), {
    // Can't clone logger and rateLimiter
    log: ctx.log,
    rateLimiter: ctx.rateLimiter,
  });

  resetContextInitialisedFlag(newCtx);

  return newCtx;
};

export const cloneObject = <T>(obj: T): T => {
  return Object.assign(Object.create(Object.getPrototypeOf(obj)), obj);
};
