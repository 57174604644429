import { type FormikHelpers } from "formik";
import HttpStatus from "http-status-codes";
import { isEmpty, isUndefined } from "lodash";
import { useRouter } from "next/router";
import { type Route } from "nextjs-routes";
import { useCallback } from "react";
import { useAlerts } from "~/hooks/useAlerts";
import { useHttpImpersonationHeader } from "~/hooks/useHttpImpersonationHeader";
import { ApiValidationError } from "~/lib/api";
import { ImpersonatedCompanyHeader } from "~/lib/session";

export type FetchOptions<Query extends Record<string, unknown>> = {
  method?: "POST" | "GET";
  body?: unknown;
  query?: Query;
  signal?: AbortSignal;
  successMessage?: string;
  setIsLoading?: (isLoading: boolean) => void;
  setErrors?: FormikHelpers<unknown>["setErrors"];
};

export type ApiRoutePath = Route["pathname"] & `/api/${string}`;
export type ApiRoute = Route & { pathname: ApiRoutePath };

export const useApi = <Query extends Record<string, unknown>>() => {
  const router = useRouter();
  const { triggerAlert } = useAlerts();
  const { shouldUseHttpImpersonationHeader, companyId } = useHttpImpersonationHeader();

  const apiFetch = useCallback(
    async <T>(url: ApiRoutePath, options: FetchOptions<Query> = {}): Promise<T> => {
      const isForm = options.body instanceof FormData;
      let res;
      try {
        if (options.setIsLoading) {
          options.setIsLoading(true);
        }

        const queryParams = buildQueryParams<Query>(options.query);

        const fullEndpoint = `${url}${queryParams}`.replace(/\/\//g, "/");

        res = await fetch(fullEndpoint, {
          method: options.method || "POST",
          headers: {
            Accept: "application/json",
            ...(!isForm && { "Content-Type": "application/json" }),
            ...(shouldUseHttpImpersonationHeader && { [ImpersonatedCompanyHeader]: `${companyId}` }),
          },
          ...(!!options.body && isForm && { body: options.body as FormData }),
          ...(!!options.body && !isForm && { body: JSON.stringify(options.body) }),
          signal: options.signal,
        });
      } catch (err) {
        // Ignore ABORT_ERR errors, as they are thrown when fetch receives an abort signal from React
        if (!(err instanceof DOMException) || err.code !== err.ABORT_ERR) {
          triggerAlert({
            type: "error",
            message: "An unexpected error occurred. Please try again later.",
          });
        }
        throw err;
      } finally {
        options.setIsLoading?.(false);
      }

      // Query failed (non 2XX http status)
      if (!res.ok) {
        switch (res.status) {
          case HttpStatus.TOO_MANY_REQUESTS:
            triggerAlert({
              type: "error",
              message: "Too many requests. Please try again later.",
            });
            throw new Error("Too many requests. Please try again later.");

          case HttpStatus.UNAUTHORIZED:
            triggerAlert({
              type: "error",
              message: "Unauthorised request. Please sign in again.",
            });
            throw new Error("Unauthorised request. Please sign in again.");

          case HttpStatus.UNPROCESSABLE_ENTITY:
            // The body should contain validation errors from Yup
            const unprocessableBody = await res.json();

            if (options.setErrors) {
              // Pipe errors to formik if the helper is provided
              options.setErrors(unprocessableBody.fields);
            }
            throw new ApiValidationError(unprocessableBody.error, unprocessableBody.fields);

          case HttpStatus.FORBIDDEN:
            const forbiddenErrorBody = await res.json();
            const forbiddenErrorMessage =
              forbiddenErrorBody?.error ?? "You do not have the rights to perform this operation.";

            triggerAlert({
              type: "error",
              message: forbiddenErrorMessage,
            });

            if (forbiddenErrorBody.key === "impersonation-forbidden") {
              window.location.href = `/impersonation-forbidden?impersonatedCompanyId=${forbiddenErrorBody.impersonatedCompanyId}`;
            }

            if (forbiddenErrorBody.key === "impersonation-mismatch") {
              window.location.href = `/impersonation-mismatch?impersonatedCompanyId=${forbiddenErrorBody.impersonatedCompanyId}&mismatchCompanyId=${forbiddenErrorBody.mismatchCompanyId}`;
            }

            return Promise.reject(forbiddenErrorMessage);

          default:
            const defaultErrorBody = await res.json();

            // Any unhandled status code (including 500) could return a body containing {error}
            const defaultErrorMessage =
              defaultErrorBody?.error ?? "An unexpected error occurred. Please try again later.";

            triggerAlert({
              type: "error",
              message: defaultErrorMessage,
            });
            throw new Error(defaultErrorMessage);
        }
      }

      // Query succeeded
      // Trigger an alert
      if (options.successMessage) {
        triggerAlert({
          type: "success",
          message: options.successMessage,
        });
      }

      // Return JSON response
      return res.json();
    },
    [triggerAlert, shouldUseHttpImpersonationHeader, router]
  );

  return { apiFetch };
};

const buildQueryParams = <Query extends Record<string, unknown>>(query: FetchOptions<Query>["query"] | undefined) => {
  if (isEmpty(query)) {
    return "";
  }

  const params = new URLSearchParams();

  Object.entries(query).forEach(([key, value]) => {
    if (isUndefined(value)) {
      return;
    }

    params.append(key, String(value));
  });

  return `?${params}`;
};
