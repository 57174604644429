import { CompensationReviewRecommendationStatus, type Prisma } from "@prisma/client";
import { salaryRangeEmployeeForRangePenetrationIndicator } from "~/components/salary-bands/range-penetration-indicator";
import { getRequiredUser } from "~/lib/get-required-user";
import { externalEmployeeFieldsSelectForDisplay } from "~/services/additional-field/get-all-additional-fields";
import { externalEmployeeSelectForDisplay } from "~/services/compensation-review/campaigns/admin/fetch-compensation-review-employees";
import { type CompensationReviewContext } from "~/services/compensation-review/compensation-review-context";
import {
  canUpdateRecommendation,
  isPendingRecommendation,
} from "~/services/compensation-review/shared/recommendation-status";
import {
  selectCompensationReviewCurrency,
  transformCompensationReviewCurrency,
} from "~/services/compensation-review/shared/transform-compensation-review-currency";
import { externalEmployeePanelSelect } from "~/services/external-employee/fetch-external-employee-for-panel";

export const compensationReviewEmployeeSelect = {
  id: true,
  isEligibleForExceptionalBonus: true,
  isPromoted: true,
  baseSalary: true,
  variablePay: true,
  otherBonus: true,
  onTargetEarnings: true,
  totalCash: true,
  budget: true,
  baseSalaryRecommendation: true,
  variablePayRecommendation: true,
  onTargetEarningsRecommendation: true,
  annualPerformanceBonusPayoutPercentage: true,
  annualPerformanceBonusRecommendation: true,
  baseSalaryIncrease: true,
  variablePayIncrease: true,
  onTargetEarningsIncrease: true,
  isIncreaseDifferentFromRecommendation: true,
  annualPerformanceBonus: true,
  exceptionalBonus: true,
  compaRatio: true,
  rangePenetration: true,
  newCompaRatio: true,
  newRangePenetration: true,
  salaryBandMeasure: true,
  currency: { select: selectCompensationReviewCurrency },
  performanceRating: {
    select: {
      name: true,
      description: true,
    },
  },
  offCycleReviewRequest: {
    select: {
      id: true,
      status: true,
    },
  },
  externalEmployee: {
    select: {
      ...externalEmployeeSelectForDisplay,
      ...externalEmployeeFieldsSelectForDisplay,
      ...externalEmployeePanelSelect,
      manager: {
        select: externalEmployeeSelectForDisplay,
      },
      job: {
        select: {
          name: true,
        },
      },
      level: {
        select: {
          name: true,
        },
      },
      location: {
        select: {
          name: true,
          country: {
            select: {
              name: true,
              alpha2: true,
            },
          },
        },
      },
    },
  },
  targetRangeAfterPromotion: {
    select: {
      level: {
        select: { name: true },
      },
      min: true,
      max: true,
      midpoint: true,
      band: {
        select: {
          id: true,
          measure: true,
          currency: true,
          job: {
            select: { name: true },
          },
          location: {
            select: { name: true },
          },
        },
      },
    },
  },
  recommendations: {
    select: {
      id: true,
      baseSalaryIncrease: true,
      variablePayIncrease: true,
      exceptionalBonus: true,
      annualPerformanceBonus: true,
      status: true,
      comment: true,
      submittedAt: true,
      isFinalRecommendation: true,
      reviewer: {
        select: {
          id: true,
          canReviewExceptionalBonus: true,
          externalEmployee: {
            select: {
              ...externalEmployeeSelectForDisplay,
              userPermissions: {
                select: {
                  role: true,
                  user: {
                    select: { id: true, profilePictureUrl: true },
                  },
                },
              },
            },
          },
        },
      },
    },
    orderBy: { position: "asc" },
  },
} satisfies Prisma.CompensationReviewEmployeeSelect;

export type CompensationReviewEmployeeRow = Prisma.CompensationReviewEmployeeGetPayload<{
  select: typeof compensationReviewEmployeeSelect;
}>;

export const enrichCompensationReviewEmployees = async <T extends { employee: CompensationReviewEmployeeRow }>(
  ctx: CompensationReviewContext,
  params: { rows: T[] }
) => {
  const user = getRequiredUser(ctx);

  const salaryRangeEmployees = user.company.defaultSalaryGridId
    ? await ctx.prisma.salaryRangeEmployee.findMany({
        where: {
          gridId: user.company.defaultSalaryGridId,
          externalEmployeeId: { in: params.rows.map((row) => row.employee.externalEmployee.id) },
        },
        select: {
          externalEmployeeId: true,
          ...salaryRangeEmployeeForRangePenetrationIndicator,
          range: {
            select: {
              min: true,
              max: true,
              midpoint: true,
              band: {
                select: {
                  id: true,
                  measure: true,
                  currency: true,
                },
              },
            },
          },
        },
      })
    : [];

  return params.rows.map((row) => {
    const pendingRecommendation = row.employee.recommendations.find(isPendingRecommendation);

    const salaryRangeEmployee =
      salaryRangeEmployees.find(
        (salaryRangeEmployee) => salaryRangeEmployee.externalEmployeeId === row.employee.externalEmployee.id
      ) ?? null;

    return {
      ...row,

      employee: {
        ...row.employee,
        currency: transformCompensationReviewCurrency(row.employee.currency),

        salaryRangeEmployee,
        pendingRecommendation,

        recommendations: row.employee.recommendations.map((recommendation) => {
          const canEdit =
            recommendation.reviewer?.id === ctx.authenticatedReviewer?.id &&
            canUpdateRecommendation(recommendation) &&
            ctx.permissions.canReview;

          const canOverride =
            recommendation.reviewer?.id === ctx.authenticatedReviewer?.id &&
            recommendation.status === CompensationReviewRecommendationStatus.WAITING_FOR_ANOTHER_REVIEW &&
            ctx.permissions.canReview;

          return {
            ...recommendation,
            canEdit,
            canOverride,
          };
        }),
      },
    };
  });
};
