import { type GetServerSidePropsContext } from "next";
import { ForbiddenError } from "~/lib/api";
import { next, type MiddlewareContext } from "~/lib/middleware/middleware";
import {
  type NullableAuthenticatedUser,
  type RequiredAuthenticatedUser,
} from "~/services/auth/fetch-authenticated-user";
import { getOnboardingRedirection } from "~/services/onboarding/get-onboarding-redirection";

export const redirectToOnboarding = async (
  ctx: MiddlewareContext<{ user: RequiredAuthenticatedUser; impersonatingUser: NullableAuthenticatedUser }>,
  options: { getServerSidePropsContext: GetServerSidePropsContext }
) => {
  const onboardingRedirection = getOnboardingRedirection(options.getServerSidePropsContext);
  if (!onboardingRedirection) {
    return next(ctx);
  }

  throw new ForbiddenError("Must complete onboarding", onboardingRedirection);
};
