import { chain } from "lodash";
import { type AppContext } from "~/lib/context";
import { assertNotNil, isNotNull } from "~/lib/utils";
import { whereSalaryGridIs } from "~/services/salary-bands/access/helpers";
import { fetchCompanyExternalEmployees } from "~/services/salary-bands/configuration/fetch-company-external-employees";
import { selectLocationForGeoStrategy } from "~/services/salary-bands/configuration/get-geographic-strategy-location-mapper";

export const computeSalaryBandTemplates = async (ctx: AppContext, params: { salaryGridId: number }) => {
  const salaryGrid = await ctx.prisma.salaryGrid.findFirstOrThrow({
    where: whereSalaryGridIs(params),
    select: {
      companyId: true,
      jobs: {
        select: {
          id: true,
          name: true,
          mappedJobs: {
            select: {
              externalJob: {
                select: {
                  id: true,
                  mappedJobId: true,
                },
              },
            },
          },
        },
      },
      locations: {
        select: {
          id: true,
          name: true,
          mappedLocations: {
            select: {
              location: {
                select: {
                  country: {
                    select: {
                      defaultCurrencyId: true,
                    },
                  },
                },
              },
              externalLocation: {
                select: {
                  id: true,
                  mappedLocationId: true,
                  mappedLocation: {
                    select: selectLocationForGeoStrategy,
                  },
                  country: {
                    select: {
                      defaultCurrencyId: true,
                    },
                  },
                },
              },
              locationId: true,
            },
          },
        },
      },
      levels: {
        orderBy: { position: "asc" },
        select: {
          id: true,
          mappedLevels: {
            select: {
              externalLevel: {
                select: {
                  id: true,
                  mappedLevel: true,
                },
              },
              level: true,
            },
          },
          benchmarkedLevels: {
            select: {
              level: true,
            },
          },
        },
      },
    },
  });

  const externalEmployees = await fetchCompanyExternalEmployees(ctx, { companyId: salaryGrid.companyId });

  return chain(externalEmployees)
    .map((externalEmployee) => {
      const salaryBandJob = salaryGrid.jobs.find(({ mappedJobs }) => {
        return mappedJobs.find(({ externalJob }) => externalJob?.id === externalEmployee.job?.id);
      });

      const salaryBandLocation = salaryGrid.locations.find(({ mappedLocations }) => {
        return mappedLocations.find(({ externalLocation, locationId }) => {
          if (externalEmployee.mappedEmployee) {
            return (
              externalLocation?.id === externalEmployee.location?.id &&
              locationId === (externalEmployee.mappedEmployee?.location?.id ?? null)
            );
          }

          return externalLocation?.id === externalEmployee.location?.id;
        });
      });

      if (!salaryBandJob || !salaryBandLocation) {
        return null;
      }

      return { ...externalEmployee, salaryBandJob, salaryBandLocation };
    })
    .filter(isNotNull)
    .groupBy((externalEmployee) => {
      return [externalEmployee.salaryBandJob.id, externalEmployee.salaryBandLocation.id];
    })
    .mapValues((externalEmployees) => {
      const template = assertNotNil(externalEmployees[0]);

      const salaryBandLevels = salaryGrid.levels.map((salaryBandLevel) => {
        const externalEmployeesForLevel = externalEmployees.filter((externalEmployee) => {
          return salaryBandLevel.mappedLevels.find((mappedLevel) => {
            if (mappedLevel.externalLevel) {
              return externalEmployee.level?.id === mappedLevel.externalLevel.id;
            }

            if (mappedLevel.level) {
              return !externalEmployee.level && externalEmployee.mappedEmployee?.level === mappedLevel.level;
            }

            return false;
          });
        });

        return { ...salaryBandLevel, externalEmployees: externalEmployeesForLevel };
      });

      return {
        externalEmployees,
        salaryBandJob: template.salaryBandJob,
        salaryBandLocation: template.salaryBandLocation,
        salaryBandLevels,
      };
    })
    .values()
    .orderBy((salaryBand) => salaryBand.salaryBandLocation.name)
    .orderBy((salaryBand) => salaryBand.salaryBandJob.name)
    .value();
};
