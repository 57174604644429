import { SalaryGridStatus } from "@prisma/client";
import { map, mapSeries } from "bluebird";
import { omit } from "lodash";
import { type AsyncReturnType } from "type-fest";
import { value } from "~/components/helpers";
import { ForbiddenError } from "~/lib/api";
import { type AppContext, transaction } from "~/lib/context";
import { trackSalaryGridNewVersionCreated } from "~/lib/external/segment/server/events";
import { assertNotNil, getId } from "~/lib/utils";
import { type DuplicateSalaryGridForNewVersionInput } from "~/pages/api/salary-bands/duplicate-salary-grid-for-new-version";
import { auditLogDuplicateGridForNewVersion } from "~/services/salary-bands/audit-logs/duplicate-grid-for-new-version";

export const duplicateSalaryGridForNewVersion = async (
  ctx: AppContext,
  input: DuplicateSalaryGridForNewVersionInput
) => {
  const { globalPermissionsContext } = ctx;

  if (!globalPermissionsContext.canUpdateSalaryBands) {
    throw new ForbiddenError("You are not allowed to update salary bands");
  }

  const existingSalaryGrid = await ctx.prisma.salaryGrid.findUniqueOrThrow({
    where: { id: input.salaryGridId, status: { not: SalaryGridStatus.ARCHIVED } },
  });

  return transaction(
    ctx,
    async (ctx) => {
      const newSalaryGrid = await ctx.prisma.salaryGrid.create({
        data: {
          ...omit(existingSalaryGrid, ["id", "createdAt", "updatedAt"]),
          liveAt: null,
          name: input.name,
          status: SalaryGridStatus.DRAFT,
        },
      });

      const params = { salaryGridId: existingSalaryGrid.id, newSalaryGridId: newSalaryGrid.id };

      const salaryBandJobs = await createSalaryBandJobs(ctx, params);
      const salaryBandLevels = await createSalaryBandLevels(ctx, params);
      const salaryBandLocations = await createSalaryBandsLocations(ctx, params);
      await createSalaryBandBenchmarkedLevels(ctx, { ...params, salaryBandLevels });

      const salaryBands = await createSalaryBands(ctx, {
        ...params,
        salaryBandJobs,
        salaryBandLocations,
      });

      const salaryRanges = await createSalaryRanges(ctx, { salaryBands, salaryBandLevels });

      await createSalaryRangeEmployees(ctx, {
        newSalaryGridId: newSalaryGrid.id,
        salaryRanges,
      });

      await auditLogDuplicateGridForNewVersion(ctx, {
        gridId: newSalaryGrid.id,
        initialState: existingSalaryGrid,
        newState: newSalaryGrid,
      });

      await trackSalaryGridNewVersionCreated(ctx, {
        existingSalaryGridId: existingSalaryGrid.id,
        newSalaryGridId: newSalaryGrid.id,
        name: newSalaryGrid.name,
      });

      return newSalaryGrid;
    },
    { timeout: 60_000 }
  );
};

const createSalaryBandJobs = async (ctx: AppContext, params: { salaryGridId: number; newSalaryGridId: number }) => {
  const { salaryGridId, newSalaryGridId } = params;

  const salaryBandJobsWithMappedJobs = await ctx.prisma.salaryBandJob.findMany({
    where: { gridId: salaryGridId },
    select: {
      id: true,
      name: true,
      description: true,
      mappedJobs: {
        select: {
          externalJobId: true,
        },
      },
    },
  });

  return mapSeries(salaryBandJobsWithMappedJobs, async (job) => {
    const newSalaryBandJob = await ctx.prisma.salaryBandJob.create({
      data: {
        gridId: newSalaryGridId,
        name: job.name,
        description: job.description,
      },
    });

    const newSalaryBandMappedJobs = await mapSeries(job.mappedJobs, async (mappedJob) => {
      await ctx.prisma.salaryBandMappedJob.create({
        data: {
          gridId: newSalaryGridId,
          externalJobId: mappedJob.externalJobId,
          salaryBandJobId: newSalaryBandJob.id,
        },
      });
    });

    return {
      newSalaryBandJob: { ...newSalaryBandJob, mappedJobs: newSalaryBandMappedJobs },
      existingSalaryBandJob: job,
    };
  });
};

const createSalaryBandLevels = async (ctx: AppContext, params: { salaryGridId: number; newSalaryGridId: number }) => {
  const { salaryGridId, newSalaryGridId } = params;

  const salaryBandLevelsWithMappedLevels = await ctx.prisma.salaryBandLevel.findMany({
    where: { gridId: salaryGridId },
    select: {
      id: true,
      name: true,
      description: true,
      position: true,
      track: true,
      mappedLevels: {
        select: {
          externalLevelId: true,
          level: true,
        },
      },
    },
  });

  return mapSeries(salaryBandLevelsWithMappedLevels, async (level) => {
    const newSalaryBandLevel = await ctx.prisma.salaryBandLevel.create({
      data: {
        gridId: newSalaryGridId,
        name: level.name,
        description: level.description,
        position: level.position,
        track: level.track,
      },
    });

    const newSalaryBandMappedLevels = await mapSeries(level.mappedLevels, async (mappedLevel) => {
      await ctx.prisma.salaryBandMappedLevel.create({
        data: {
          gridId: newSalaryGridId,
          externalLevelId: mappedLevel.externalLevelId,
          level: mappedLevel.level,
          salaryBandLevelId: newSalaryBandLevel.id,
        },
      });
    });

    return {
      newSalaryBandLevel: { ...newSalaryBandLevel, mappedLevels: newSalaryBandMappedLevels },
      existingSalaryBandLevel: level,
    };
  });
};

const createSalaryBandsLocations = async (
  ctx: AppContext,
  params: { salaryGridId: number; newSalaryGridId: number }
) => {
  const { salaryGridId, newSalaryGridId } = params;

  const salaryBandLocationsWithMappedLocations = await ctx.prisma.salaryBandLocation.findMany({
    where: { gridId: salaryGridId },
    select: {
      id: true,
      name: true,
      mappedLocations: {
        select: {
          externalLocationId: true,
          locationId: true,
        },
      },
    },
  });

  return mapSeries(salaryBandLocationsWithMappedLocations, async (location) => {
    const newSalaryBandLocation = await ctx.prisma.salaryBandLocation.create({
      data: {
        gridId: newSalaryGridId,
        name: location.name,
      },
    });

    const newSalaryBandMappedLocations = await mapSeries(location.mappedLocations, async (mappedLocation) => {
      await ctx.prisma.salaryBandMappedLocation.create({
        data: {
          gridId: newSalaryGridId,
          externalLocationId: mappedLocation.externalLocationId,
          locationId: mappedLocation.locationId,
          salaryBandLocationId: newSalaryBandLocation.id,
        },
      });
    });

    return {
      newSalaryBandLocation: { ...newSalaryBandLocation, mappedLocations: newSalaryBandMappedLocations },
      existingSalaryBandLocation: location,
    };
  });
};

const createSalaryBands = async (
  ctx: AppContext,
  params: {
    salaryGridId: number;
    newSalaryGridId: number;
    salaryBandJobs: AsyncReturnType<typeof createSalaryBandJobs>;
    salaryBandLocations: AsyncReturnType<typeof createSalaryBandsLocations>;
  }
) => {
  const { salaryGridId, newSalaryGridId, salaryBandLocations, salaryBandJobs } = params;

  const existingSalaryBands = await ctx.prisma.salaryBand.findMany({
    where: { gridId: salaryGridId },
    select: {
      id: true,
      measure: true,
      isDraft: true,
      jobId: true,
      locationId: true,
      marketPositioning: true,
      currency: true,
      benchmarkedJobs: {
        select: {
          id: true,
          jobId: true,
        },
      },
      benchmarkedLocations: {
        select: {
          id: true,
          locationId: true,
        },
      },
    },
  });

  return mapSeries(existingSalaryBands, async (salaryBand) => {
    const job = salaryBandJobs.find((job) => job.existingSalaryBandJob.id === salaryBand.jobId);
    const location = salaryBandLocations.find(
      (location) => location.existingSalaryBandLocation.id === salaryBand.locationId
    );

    const newMarketPositioning = await value(async () => {
      if (salaryBand.marketPositioning) {
        return ctx.prisma.salaryBandMarketPositioning.create({
          data: omit(salaryBand.marketPositioning, ["id", "updatedAt", "createdAt"]),
        });
      }

      return null;
    });

    const newSalaryBand = await ctx.prisma.salaryBand.create({
      data: {
        gridId: newSalaryGridId,
        measure: salaryBand.measure,
        isDraft: true,
        jobId: assertNotNil(job).newSalaryBandJob.id,
        locationId: assertNotNil(location).newSalaryBandLocation.id,
        currencyId: salaryBand.currency.id,
        marketPositioningId: newMarketPositioning?.id ?? null,
      },
    });

    await ctx.prisma.salaryBandBenchmarkedJob.createMany({
      data: salaryBand.benchmarkedJobs.map((job) => ({
        bandId: newSalaryBand.id,
        gridId: newSalaryGridId,
        jobId: job.jobId,
      })),
    });

    await ctx.prisma.salaryBandBenchmarkedLocation.createMany({
      data: salaryBand.benchmarkedLocations.map((location) => ({
        bandId: newSalaryBand.id,
        gridId: newSalaryGridId,
        locationId: location.locationId,
      })),
    });

    return { newSalaryBand, existingSalaryBand: salaryBand };
  });
};

const createSalaryBandBenchmarkedLevels = async (
  ctx: AppContext,
  params: {
    salaryGridId: number;
    newSalaryGridId: number;
    salaryBandLevels: AsyncReturnType<typeof createSalaryBandLevels>;
  }
) => {
  const { salaryGridId, newSalaryGridId, salaryBandLevels } = params;

  const salaryBandBenchmarkedLevels = await ctx.prisma.salaryBandBenchmarkedLevel.findMany({
    where: { gridId: salaryGridId },
    select: {
      id: true,
      level: true,
      salaryBandLevelId: true,
    },
  });

  await mapSeries(salaryBandBenchmarkedLevels, async (benchmarkedLevel) => {
    const level = salaryBandLevels.find(
      (level) => level.existingSalaryBandLevel.id === benchmarkedLevel.salaryBandLevelId
    );

    await ctx.prisma.salaryBandBenchmarkedLevel.create({
      data: {
        gridId: newSalaryGridId,
        salaryBandLevelId: assertNotNil(level).newSalaryBandLevel.id,
        level: benchmarkedLevel.level,
      },
    });
  });
};

const createSalaryRanges = async (
  ctx: AppContext,
  params: {
    salaryBands: AsyncReturnType<typeof createSalaryBands>;
    salaryBandLevels: AsyncReturnType<typeof createSalaryBandLevels>;
  }
) => {
  const { salaryBands, salaryBandLevels } = params;

  const existingSalaryRanges = await ctx.prisma.salaryRange.findMany({
    where: { bandId: { in: salaryBands.map((band) => band.existingSalaryBand).map(getId) } },
  });

  return mapSeries(existingSalaryRanges, async (salaryRange) => {
    const level = salaryBandLevels.find((level) => level.existingSalaryBandLevel.id === salaryRange.levelId);
    const band = salaryBands.find((band) => band.existingSalaryBand.id === salaryRange.bandId);

    const newSalaryRange = await ctx.prisma.salaryRange.create({
      data: {
        ...omit(salaryRange, "id"),
        bandId: assertNotNil(band).newSalaryBand.id,
        levelId: assertNotNil(level).newSalaryBandLevel.id,
      },
    });

    return { newSalaryRange, existingSalaryRange: salaryRange };
  });
};

const createSalaryRangeEmployees = async (
  ctx: AppContext,
  params: { newSalaryGridId: number; salaryRanges: AsyncReturnType<typeof createSalaryRanges> }
) => {
  const { salaryRanges, newSalaryGridId } = params;

  const salaryRangeEmployees = await ctx.prisma.salaryRangeEmployee.findMany({
    where: { rangeId: { in: salaryRanges.map((range) => range.existingSalaryRange).map(getId) } },
  });

  await map(
    salaryRangeEmployees,
    async (salaryRangeEmployee) => {
      const range = salaryRanges.find((range) => range.existingSalaryRange.id === salaryRangeEmployee.rangeId);

      await ctx.prisma.salaryRangeEmployee.create({
        data: {
          ...omit(salaryRangeEmployee, ["id", "rangeId"]),
          gridId: newSalaryGridId,
          rangeId: assertNotNil(range).newSalaryRange.id,
        },
      });
    },
    { concurrency: 10 }
  );
};
