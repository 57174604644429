import { type IncomingMessage } from "http";
import { type Query } from "nextjs-routes";
import { type AsyncReturnType } from "type-fest";

type BaseArgs = Record<string, unknown>;

export const MiddlewareRuntime = {
  API: "API",
  SSR: "SSR",
} as const;

export type MiddlewareRuntime = (typeof MiddlewareRuntime)[keyof typeof MiddlewareRuntime];

export type MiddlewareContext<Args extends BaseArgs = BaseArgs> = {
  req: IncomingMessage;
  query?: Query;
  resolvedUrl?: string;
  runtime: MiddlewareRuntime;
} & Args;

type DoneResult = {
  status: "DONE";
};

type NextResult<T = BaseArgs> = {
  status: "NEXT";
  result?: T;
};

export const done = () => ({ status: "DONE" }) as const;

export const next = <T = BaseArgs>(result?: T) => ({ status: "NEXT", result }) as const;

type MiddlewareResult<T = BaseArgs> = DoneResult | NextResult<T>;

export type Middleware<Output = void, Input extends BaseArgs = BaseArgs, Options = BaseArgs> = (
  ctx: MiddlewareContext<Input>,
  options: Options
) => Promise<MiddlewareResult<Output>>;

export const createMiddlewareChain = (ctx: MiddlewareContext) => {
  let debug = false;
  const middlewares: { middleware: Middleware<BaseArgs, BaseArgs>; options: unknown }[] = [];

  const debugMiddleware = (middleware: Middleware<unknown>, result: MiddlewareResult) => {
    if (!debug) return;

    // eslint-disable-next-line no-console
    console.log(`[Middleware] Result of ${middleware.name}\nRESULT: ${JSON.stringify(result, null, 2)}`);
  };

  const makeContextBuilder = <Input extends BaseArgs>() => {
    return {
      enableDebug: () => {
        debug = true;

        return makeContextBuilder();
      },

      use: <
        Options extends BaseArgs,
        MiddlewareFn extends Middleware<BaseArgs, Input, Options>,
        Output extends MiddlewareResult<BaseArgs> = AsyncReturnType<MiddlewareFn>,
        Result extends BaseArgs = Output extends NextResult<infer R> ? R : never,
      >(
        fn: MiddlewareFn,
        options?: Options
      ) => {
        middlewares.push({ middleware: fn as Middleware<BaseArgs, BaseArgs>, options });

        return makeContextBuilder<Result>();
      },

      run: async () => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        let lastResult: any;

        for (const { middleware, options } of middlewares) {
          try {
            const result = await middleware({ ...ctx, ...lastResult }, options as BaseArgs);
            debugMiddleware(middleware, result);

            if (result.status === "DONE") break;

            lastResult = result.result;
          } catch (error) {
            debugMiddleware(middleware, lastResult);

            throw error;
          }
        }
      },
    };
  };

  return makeContextBuilder();
};
