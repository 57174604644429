import { value } from "~/components/helpers";
import { config } from "~/config";
import { PublicApiAuthenticationError } from "~/lib/api";
import { trackApiKeyAuthenticated } from "~/lib/external/segment/server/events";
import { done, next, type MiddlewareContext } from "~/lib/middleware/middleware";
import { type AuthenticationOptions } from "~/lib/session";

export const protectRouteWithApiKey = async (
  ctx: MiddlewareContext,
  options: { apiKey?: AuthenticationOptions["apiKey"] }
) => {
  if (!options.apiKey) {
    return next();
  }

  const apiAuthenticationData = value(() => {
    if (!options.apiKey || !ctx.req.headers.authorization) {
      return null;
    }

    const apiKeys = options.apiKey.map((key) => ({
      apiKey: config["app"]["publicApiKey"][key],
      partner: key,
    }));

    return apiKeys.find((item) => item.apiKey === ctx.req.headers.authorization);
  });

  if (apiAuthenticationData) {
    await trackApiKeyAuthenticated(ctx.req, {
      ipAddress: ctx.req.ip,
      url: ctx.req.url as string,
      partner: apiAuthenticationData.partner,
    });

    return done();
  }

  throw new PublicApiAuthenticationError("Unauthorised access");
};
