/* eslint-disable-next-line no-restricted-imports */
import { CircularProgress, Button as MuiButton, type ButtonProps } from "@mui/material";
import { forwardRef } from "react";

type ButtonType = <C extends React.ElementType>(
  props: ButtonProps<
    C,
    {
      component?: C;
      isLoading?: boolean;
    }
  >,
  ref?: React.Ref<HTMLButtonElement>
) => React.ReactElement;

const CastedButton: ButtonType = (props, ref) => {
  const { children, isLoading, disabled, endIcon, onClick, ...rest } = props;

  return (
    <MuiButton
      ref={ref}
      disabled={disabled || isLoading}
      endIcon={isLoading ? <CircularProgress size={14} color="inherit" /> : endIcon}
      onClick={(event) => {
        onClick?.(event);
      }}
      {...rest}
    >
      {children}
    </MuiButton>
  );
};

export const Button = forwardRef(CastedButton) as ButtonType;
