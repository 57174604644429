import { type Company } from "@prisma/client";
import { compact } from "lodash";
import { castStringAsMultipleTypes } from "~/services/additional-field/parse-additional-fields-spreadsheet";

export const computeAdditionalFieldValuePayloads = (
  company: Company,
  additionalFields: { additionalFieldId: number; additionalFieldMappingId: number | null; value: string | null }[]
) => {
  return compact(
    additionalFields.map(({ additionalFieldId, additionalFieldMappingId, value }) => {
      if (!value) return null;
      return {
        company: { connect: { id: company.id } },
        additionalField: { connect: { id: additionalFieldId } },
        additionalFieldMapping: additionalFieldMappingId ? { connect: { id: additionalFieldMappingId } } : null,
        ...castStringAsMultipleTypes(value),
      };
    })
  );
};
