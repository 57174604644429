import { isNumber } from "lodash";
import { value } from "~/components/helpers";
import { type AppContext } from "~/lib/context";

export const computeImpersonationStatus = (ctx: AppContext) => {
  const userImpersonationStatus = value(() => {
    if (!ctx.impersonation) {
      return { impersonatingUser: false } as const;
    }

    return {
      impersonatingUser: true,
      impersonatingUserBy: ctx.impersonation.by,
      impersonatingUserFor: ctx.impersonation.for,
    } as const;
  });

  const companyImpersonationStatus = value(() => {
    if (!isNumber(ctx.impersonatingCompanyId)) {
      return { impersonatingCompany: false } as const;
    }

    return {
      impersonatingCompany: true,
      impersonatingCompanyId: ctx.impersonatingCompanyId,
    } as const;
  });

  return { ...userImpersonationStatus, ...companyImpersonationStatus };
};

export type ImpersonationStatus = ReturnType<typeof computeImpersonationStatus>;
