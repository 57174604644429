import { type NextApiRequest } from "next";
import { type GetServerSidePropsContext } from "nextjs-routes";
import { initContext } from "~/lib/init-context";
import { type MiddlewareContext, next } from "~/lib/middleware/middleware";
import { populateCompensationReviewMetadata } from "~/lib/session";
import {
  type NullableAuthenticatedUser,
  type RequiredAuthenticatedUser,
} from "~/services/auth/fetch-authenticated-user";

export const setupContext = async (
  ctx: MiddlewareContext<{ user: RequiredAuthenticatedUser; impersonatingUser: NullableAuthenticatedUser }>,
  options: {
    req?: NextApiRequest;
    ctx?: GetServerSidePropsContext;
  }
) => {
  populateCompensationReviewMetadata(options);

  await initContext(ctx.req, ctx.user);

  return next(ctx);
};
