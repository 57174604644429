// Enable this if you need to debug why a component is re-rendering too often.
// import "~/lib/wdyr";

import { CacheProvider, type EmotionCache } from "@emotion/react";
import { ThemeProvider } from "@mui/material";
import { UserLocale } from "@prisma/client";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { appWithTranslation } from "next-i18next";
import { type AppProps } from "next/app";
import { useRouter } from "next/router";
import React, { useEffect, useMemo } from "react";
import { ImpersonationRequestedModal } from "~/components/account/impersonation-requested-modal";
import { CommandPalette } from "~/components/command-palette/command-palette";
import { CommandPaletteProvider } from "~/components/command-palette/command-palette-context";
import { CookiesWidget } from "~/components/layout/cookies-widget";
import { ConfirmNewMappingModal } from "~/components/remapping-request/confirm-new-mapping-modal";
import { ClickTracker } from "~/components/ui/click-tracker";
import { Alerts } from "~/components/ui/core/Alerts";
import { DatadogBrowserLogs } from "~/components/ui/datadog-browser-logs";
import { GlobalProgressBar } from "~/components/ui/global-progress-bar";
import { Meta } from "~/components/ui/meta";
import { PageViewTracker } from "~/components/ui/page-view-tracker";
import { TranslationSnackbar } from "~/components/ui/translation-snackbar";
import { config } from "~/config";
import createEmotionCache from "~/createEmotionCache";
import { AlertsProvider } from "~/hooks/useAlerts";
import {
  ExternalEmployeesSyncProgressProvider,
  ExternalEmployeesSyncSnackbar,
} from "~/hooks/useExternalEmployeesSyncSnackbar";
import { FeatureFlagsProvider } from "~/hooks/useFeatureFlags";
import { HttpImpersonationHeaderProvider } from "~/hooks/useHttpImpersonationHeader";
import { ImportSpreadsheetProgressProvider, ImportSpreadsheetSnackbar } from "~/hooks/useImportSpreadsheetSyncSnackbar";
import { IntercomForFiguresProvider } from "~/hooks/useIntercomForFigures";
import { LocaleProvider } from "~/hooks/useLocale";
import { PermissionsProvider } from "~/hooks/usePermissions";
import { ScrollSpyVisibleItemProvider } from "~/hooks/useScrollSpyVisibleItem";
import { SessionProvider } from "~/hooks/useSession";
import { SubscriptionsProvider } from "~/hooks/useSubscriptions";
import { useTabSyncingEvents } from "~/hooks/useTabSyncingEvents";
import i18nConfig from "~/lib/i18n/config";
import { useI18n } from "~/lib/i18n/use-i18n";
import { NEXT_PROPS_ERROR_KEY_NAME } from "~/lib/nextPropsErrorKeyName";
import { type AuthenticatedUser } from "~/lib/session";
import { createFiguresTheme } from "~/lib/theme";
import { welcomeMessage } from "~/lib/utils";
import { default as ErrorPage } from "~/pages/_error";
import { type FeatureFlagsStatus } from "~/services/feature-flags";
import { type ImpersonationStatus } from "~/services/impersonation-status";
import { type SubscriptionStatusWithPermissions } from "~/services/subscriptions/validate-subscription";
import { AbilityContext, createAbility } from "~/services/user/casl-permissions";
import { type PermissionsStatus } from "~/services/user/permissions/authentication-options";
import { getUserRoles } from "~/services/user/permissions/utils/get-user-roles";
import "~/styles/index.css";

// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache();

interface MyAppProps extends AppProps {
  emotionCache: EmotionCache;
}

const App = (props: MyAppProps) => {
  const { Component, pageProps, emotionCache = clientSideEmotionCache } = props;

  return (
    <CacheProvider value={emotionCache}>
      <AppConfig pageProps={pageProps}>
        <Component {...pageProps} />
      </AppConfig>
    </CacheProvider>
  );
};

export default appWithTranslation(App, i18nConfig);

type Props = React.PropsWithChildren<{
  pageProps?: {
    [NEXT_PROPS_ERROR_KEY_NAME]?: boolean;
    error?: {
      statusCode?: number;
      message?: string;
    };
    user?: AuthenticatedUser;
    userHash?: string;
    featureFlags?: FeatureFlagsStatus;
    _permissions?: PermissionsStatus;
    subscriptions?: SubscriptionStatusWithPermissions;
    impersonationStatus?: ImpersonationStatus;
  };
}>;

const queryClient = new QueryClient();

export const APP_ELEMENT_ID = "app";

export const AppConfig: React.FC<Props> = ({ pageProps, children }) => {
  const user = pageProps?.user ?? null;
  const MuiTheme = useMemo(() => createFiguresTheme(user?.locale ?? UserLocale.EN), [user?.locale]);
  const router = useRouter();
  const i18n = useI18n()[1];

  const userHash = pageProps?.userHash ?? null;
  const featureFlags = pageProps?.featureFlags ?? ({} as FeatureFlagsStatus);
  const subscriptions = pageProps?.subscriptions ?? ({} as SubscriptionStatusWithPermissions);
  const permissions = pageProps?._permissions ?? ({} as PermissionsStatus);
  const ability = createAbility(user, { subscriptions });
  const hasRenderedUser = !!user;
  const pageError = pageProps?.[NEXT_PROPS_ERROR_KEY_NAME];
  const shouldUseHttpImpersonationHeader =
    (user?.isSuperAdmin && pageProps?.impersonationStatus?.impersonatingCompany) ?? false;

  useTabSyncingEvents();

  useEffect(() => window.console.log(welcomeMessage), []);

  useEffect(() => {
    if (!config.app.isLocal) return;

    void fetch(`/locales/${i18n.language}/common.json`)
      .then((res) => res.json())
      .then((json) => {
        i18n.addResourceBundle(i18n.language, "common", json, true, true);
        return i18n.changeLanguage(i18n.language);
      });
  }, [router]);

  return (
    <HttpImpersonationHeaderProvider
      companyId={user?.companyId ?? -1}
      shouldUseHttpImpersonationHeader={shouldUseHttpImpersonationHeader}
    >
      <AbilityContext.Provider value={ability}>
        <ScrollSpyVisibleItemProvider>
          <IntercomForFiguresProvider>
            <QueryClientProvider client={queryClient}>
              <FeatureFlagsProvider featureFlags={featureFlags}>
                <SubscriptionsProvider subscriptions={subscriptions}>
                  <SessionProvider
                    user={user}
                    userHash={userHash}
                    hasRenderedUser={hasRenderedUser}
                    impersonationStatus={pageProps?.impersonationStatus}
                  >
                    <PermissionsProvider permissions={permissions} role={getUserRoles(user)}>
                      <CommandPaletteProvider>
                        <ThemeProvider theme={MuiTheme}>
                          <AlertsProvider>
                            <LocaleProvider user={user}>
                              <ExternalEmployeesSyncProgressProvider>
                                <ImportSpreadsheetProgressProvider>
                                  <div
                                    className="max-w-screen h-full w-full overflow-auto overflow-x-hidden"
                                    id={APP_ELEMENT_ID}
                                  >
                                    <Meta />

                                    <DatadogBrowserLogs />

                                    <GlobalProgressBar />

                                    <PageViewTracker />

                                    <ClickTracker />

                                    {!!pageError && router.pathname !== "/error/500" ? (
                                      <ErrorPage
                                        statusCode={pageProps?.error?.statusCode}
                                        message={pageProps?.error?.message}
                                      />
                                    ) : (
                                      children
                                    )}

                                    <CookiesWidget />

                                    <Alerts />

                                    <CommandPalette />

                                    <ExternalEmployeesSyncSnackbar />

                                    <ImportSpreadsheetSnackbar />

                                    <ImpersonationRequestedModal />

                                    <ConfirmNewMappingModal />

                                    <TranslationSnackbar />
                                  </div>
                                </ImportSpreadsheetProgressProvider>
                              </ExternalEmployeesSyncProgressProvider>
                            </LocaleProvider>
                          </AlertsProvider>
                        </ThemeProvider>
                      </CommandPaletteProvider>
                    </PermissionsProvider>
                  </SessionProvider>
                </SubscriptionsProvider>
              </FeatureFlagsProvider>
            </QueryClientProvider>
          </IntercomForFiguresProvider>
        </ScrollSpyVisibleItemProvider>
      </AbilityContext.Provider>
    </HttpImpersonationHeaderProvider>
  );
};
