import { differenceInHours } from "date-fns";
import type { AppContext } from "~/lib/context";
import { parseISO } from "~/lib/dates";
import { updateSegmentUser } from "~/lib/external/segment/server/client";
import { trackUserInteracted } from "~/lib/external/segment/server/events";
import type { AuthenticatedUser } from "~/lib/session";

export const updateUserLastActivity = async (ctx: AppContext, user: AuthenticatedUser) => {
  const hasRecentActivity = user.lastActivityAt && differenceInHours(new Date(), parseISO(user.lastActivityAt)) < 12;

  if (!hasRecentActivity) {
    await ctx.prisma.user.update({
      data: {
        lastActivityAt: new Date(),
      },
      where: {
        id: user.id,
      },
    });

    await trackUserInteracted(ctx, user);

    if (!user.isSuperAdmin) {
      await updateSegmentUser(ctx, user, { impersonating: false });
    }
  }
};
