import { useQueryClient } from "@tanstack/react-query";
import { useEffect } from "react";
import { useQuery } from "~/hooks/useQueries";
import { IMPERSONATION_STATUS_QUERY_KEY } from "~/pages/api/auth/company-impersonation/impersonation-status";
import { type NullableAuthenticatedUser } from "~/services/auth/fetch-authenticated-user";
import { type ImpersonationStatus } from "~/services/impersonation-status";

export const useImpersonationStatus = (params: {
  user: NullableAuthenticatedUser;
  initialImpersonatingStatus?: ImpersonationStatus;
}): ImpersonationStatus => {
  const { data: impersonationStatus } = useQuery<ImpersonationStatus>({
    path: IMPERSONATION_STATUS_QUERY_KEY,
    options: {
      enabled: !!params.user,
      initialData: params.initialImpersonatingStatus,
    },
    config: { method: "POST" },
  });

  const queryClient = useQueryClient();

  useEffect(() => {
    if (!params.user) {
      return;
    }

    void queryClient.invalidateQueries([IMPERSONATION_STATUS_QUERY_KEY]);
  }, [params.user?.companyId]);

  if (!impersonationStatus) {
    return {
      impersonatingUser: false,
      impersonatingCompany: false,
    };
  }

  return impersonationStatus;
};
