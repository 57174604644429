import { ExternalEmployeeSource, ExternalRemunerationStatus, ExternalRemunerationType } from "@prisma/client";
import { parseISO } from "date-fns";
import { compact } from "lodash";
import { match } from "ts-pattern";
import { value } from "~/components/helpers";
import { type KomboEmployment } from "~/lib/hris/kombo";
import { isIn } from "~/lib/utils";
import { type EmployeeData } from "~/services/synchronization/sync-external-employees";

export type KomboFactorialContract = {
  id: number;
  employee_id: number;

  role: string;
  level: string;

  effective_on: string;
  ends_on: string | null;
  starts_on: string;

  salary_amount?: number;
  salary_frequency: "daily" | "hourly" | "weekly" | "monthly" | "yearly";

  compensation_ids: number[];

  compensation: KomboFactorialCompensation[];
};

const recurrenceMultipliers = {
  monthly: 12,
  every_2_months: 12 / 2,
  every_3_months: 12 / 3,
  every_4_months: 12 / 4,
  every_5_months: 12 / 5,
  every_6_months: 12 / 6,
  every_7_months: 12 / 7,
  every_8_months: 12 / 8,
  every_9_months: 12 / 9,
  every_10_months: 12 / 10,
  every_11_months: 12 / 11,
  every_12_months: 12 / 12,
} as const;

enum KomboFactorialCompensationType {
  "FIXED" = "fixed",
  "UP_TO" = "up_to",
}

export type KomboFactorialCompensation = {
  id: number;
  description: string;
  compensation_type: KomboFactorialCompensationType | string;
  amount: number;
  recurrence: keyof typeof recurrenceMultipliers | null;
  starts_on: string;
  unit: "money";
};

export const getKomboFactorialBonusItems = (currentEmployment: KomboEmployment, companyId: number) => {
  const remunerationItems: EmployeeData["remunerationItems"] = [];

  const currentCompensations = currentEmployment?.remote_data?.["/v1/payroll/contract_versions"]?.compensation;

  if (!currentCompensations) {
    return remunerationItems;
  }

  return compact(
    currentCompensations
      .filter(({ compensation_type }) => isIn(compensation_type, Object.values(KomboFactorialCompensationType)))
      .map((compensation) => mapKomboFactorialBonus({ companyId, compensation }))
  );
};

const mapKomboFactorialBonus = (params: {
  companyId: number;
  compensation: KomboFactorialCompensation;
}): EmployeeData["remunerationItems"][number] | null => {
  const { companyId, compensation } = params;
  const compensationType = compensation.compensation_type as KomboFactorialCompensationType;
  const multiplier = compensation.recurrence ? recurrenceMultipliers[compensation.recurrence] : 12;

  const amount = compensation.amount * multiplier;

  const externalId = value(() => {
    const base = compensation.id ?? "";

    return match(compensationType)
      .with(KomboFactorialCompensationType.UP_TO, () => `variable-bonus${base}`)
      .with(KomboFactorialCompensationType.FIXED, () => `fixed-bonus${base}`)
      .exhaustive();
  });

  const name = value(() => {
    if (compensation.description) {
      return compensation.description;
    }

    return match(compensationType)
      .with(KomboFactorialCompensationType.UP_TO, () => "Variable bonus")
      .with(KomboFactorialCompensationType.FIXED, () => "Fixed bonus")
      .exhaustive();
  });

  const mappedType = match<KomboFactorialCompensationType, ExternalRemunerationType>(compensationType)
    .with(KomboFactorialCompensationType.UP_TO, () => ExternalRemunerationType.VARIABLE_BONUS)
    .with(KomboFactorialCompensationType.FIXED, () => ExternalRemunerationType.FIXED_BONUS)
    .exhaustive();

  return {
    source: ExternalEmployeeSource.KOMBO_FACTORIAL,
    externalId,
    amount,
    status: ExternalRemunerationStatus.LIVE,
    date: compensation.starts_on ? parseISO(compensation.starts_on) : null,
    nature: {
      connectOrCreate: {
        where: {
          companyId_source_externalId: {
            companyId: companyId,
            source: ExternalEmployeeSource.KOMBO_FACTORIAL,
            externalId,
          },
        },
        create: {
          source: ExternalEmployeeSource.KOMBO_FACTORIAL,
          externalId,
          name,
          mappedType,
          company: {
            connect: {
              id: companyId,
            },
          },
        },
      },
    },
  };
};
