import { next, type MiddlewareContext } from "~/lib/middleware/middleware";
import { updateUserLastActivity } from "~/lib/update-user-last-activity";
import { fireAndForget } from "~/lib/utils";
import {
  type NullableAuthenticatedUser,
  type RequiredAuthenticatedUser,
} from "~/services/auth/fetch-authenticated-user";

export const updateLastActivity = async (
  ctx: MiddlewareContext<{ user: RequiredAuthenticatedUser; impersonatingUser: NullableAuthenticatedUser }>
) => {
  await fireAndForget(updateUserLastActivity(ctx.req, ctx.user));

  return next(ctx);
};
