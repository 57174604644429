import { type Route } from "nextjs-routes";
import { ForbiddenError } from "~/lib/api";
import { next, type MiddlewareContext } from "~/lib/middleware/middleware";
import { type AuthenticationOptions } from "~/lib/session";
import { findAsyncInArray } from "~/lib/utils";
import {
  type NullableAuthenticatedUser,
  type RequiredAuthenticatedUser,
} from "~/services/auth/fetch-authenticated-user";
import { getActivePermissionsForUser } from "~/services/permissions/validate-permissions";

export const protectRouteWithPermissions = async (
  ctx: MiddlewareContext<{ user: RequiredAuthenticatedUser; impersonatingUser: NullableAuthenticatedUser }>,
  options: { permissions: AuthenticationOptions }
) => {
  const permissionPredicates = Object.entries(options.permissions).filter(([permission]) =>
    permission.startsWith("can")
  );

  const invalidPermission = await findAsyncInArray(permissionPredicates, async ([permission, value]) => {
    const permissions = await getActivePermissionsForUser(ctx.req);
    return permissions[permission as keyof typeof permissions] !== value;
  });

  if (!invalidPermission) {
    return next(ctx);
  }

  throw new ForbiddenError("Unauthorised access", {
    pathname: ctx.req.headers.referer ?? "/",
    query: { error: "forbidden" },
  } as Route);
};
