import { DoNotDisturb } from "@mui/icons-material";
import { Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Button } from "~/components/ui/core/button";
import { Link } from "~/components/ui/core/Link";
import { Modal } from "~/components/ui/core/Modal";
import { usePermissions } from "~/hooks/usePermissions";
import { useSession } from "~/hooks/useSession";
import { useApproveImpersonationAccessMutation } from "~/pages/api/auth/company-impersonation/approve-impersonation-access";
import { useDeclineImpersonationAccessMutation } from "~/pages/api/auth/company-impersonation/decline-impersonation-access";
import { useFetchImpersonationAccessRequestQuery } from "~/pages/api/auth/company-impersonation/find-impersonation-access-request";
import { IMPERSONATION_ACCESS_TYPE } from "~/services/impersonation/approve-impersonation-access";
import { IMPERSONATION_ACCESS_DURATION_IN_DAYS } from "~/services/impersonation/helper";

export const ImpersonationRequestedModal: React.FC = () => {
  const { user } = useSession();
  const { permissions } = usePermissions();
  const [isOpen, setIsOpen] = useState(false);

  const declineImpersonationAccessRequest = useDeclineImpersonationAccessMutation({
    successMessage: "Impersonation access request successfully declined!",
    onSuccess: () => setIsOpen(false),
  });

  const approveImpersonationAccess = useApproveImpersonationAccessMutation({
    successMessage: "Access approved successfully!",
    onSuccess: () => setIsOpen(false),
  });

  const { data: impersonationAccessRequest } = useFetchImpersonationAccessRequestQuery({
    input: { companyId: user?.companyId },
    options: { enabled: !!user && permissions.canAccessSettings },
  });

  useEffect(() => {
    setIsOpen(!!impersonationAccessRequest);
  }, [impersonationAccessRequest]);

  return (
    <Modal
      icon="lock-open-variant"
      className="w-[640px]"
      isOpen={isOpen}
      title="Permission requested"
      subtitle="In order to validate your data and potentially debug any issues, we need you to temporarily grant us access."
      onClose={() => setIsOpen(false)}
      customFooter={
        <Stack direction="row" justifyContent="space-between" className="border-t border-gray-300 px-4 pt-6 pb-4">
          <Button
            variant="outlined"
            color="secondary"
            startIcon={<DoNotDisturb />}
            isLoading={declineImpersonationAccessRequest.isLoading}
            onClick={() => declineImpersonationAccessRequest.mutateAsync({})}
          >
            Decline access
          </Button>

          <Stack direction="row" spacing={4}>
            <Button
              variant="contained"
              color="white"
              onClick={() =>
                approveImpersonationAccess.mutateAsync({ accessType: IMPERSONATION_ACCESS_TYPE.PERMANENT })
              }
              isLoading={approveImpersonationAccess.isLoading}
            >
              Grant permanent access
            </Button>

            <Button
              variant="contained"
              onClick={() =>
                approveImpersonationAccess.mutateAsync({ accessType: IMPERSONATION_ACCESS_TYPE.TEMPORARY })
              }
              isLoading={approveImpersonationAccess.isLoading}
            >
              Grant temporary access
            </Button>
          </Stack>
        </Stack>
      }
    >
      <Stack spacing={4} padding={4}>
        <Typography variant="h4">
          For {IMPERSONATION_ACCESS_DURATION_IN_DAYS} days, or until you revoke this access, Figures will be able to:
        </Typography>

        <ul className="list-inside list-disc">
          <Typography component="li">Access and edit your company information and details</Typography>
          <Typography component="li">Access and edit your employees compensation in Figures</Typography>
        </ul>

        <Typography variant="caption" color="text.secondary">
          We care about your privacy.
          <br />
          Figures’ team members can only access your data if you grant us rights to do so. You can see and revoke the
          granted permissions anytime from your
          <Link to="/account/settings" className="ml-1">
            account settings
          </Link>
          .
        </Typography>
      </Stack>
    </Modal>
  );
};
