import { ApiFeatureAccessError } from "~/lib/api";
import { next, type MiddlewareContext } from "~/lib/middleware/middleware";
import {
  type NullableAuthenticatedUser,
  type RequiredAuthenticatedUser,
} from "~/services/auth/fetch-authenticated-user";
import { type FeatureFlagName } from "~/services/feature-flags";

export const protectRouteWithFeatureFlag = async (
  ctx: MiddlewareContext<{ user: RequiredAuthenticatedUser; impersonatingUser: NullableAuthenticatedUser }>,
  options: { feature?: FeatureFlagName }
) => {
  if (!options.feature || ctx.req.featureFlags[options.feature]) {
    return next(ctx);
  }

  throw new ApiFeatureAccessError(`User cannot access the feature "${options.feature}"`);
};
