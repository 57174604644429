import classNames from "classnames";
import React, { useEffect } from "react";
import { useCommandPalette } from "~/components/command-palette/command-palette-context";
import { CommandPaletteStyle } from "~/components/command-palette/command-palette-theme";
import { ExternalEmployeeCommandPalette } from "~/components/command-palette/external-employee-command-palette";
import { RootCommandPalette } from "~/components/command-palette/root-command-palette";

export const CommandPalette: React.FC = () => {
  const { showPalette, selectedExternalEmployee } = useCommandPalette();

  useCommandK();

  return (
    <>
      <CommandPaletteStyle />

      <div
        className={classNames({
          "pointer-events-none fixed inset-0 z-[1301] bg-gray-900/30 transition": true,
          "opacity-0": !showPalette,
          "opacity-100": showPalette,
        })}
      />

      {showPalette && !selectedExternalEmployee && <RootCommandPalette />}

      {showPalette && selectedExternalEmployee && (
        <ExternalEmployeeCommandPalette externalEmployee={selectedExternalEmployee} />
      )}
    </>
  );
};

const useCommandK = () => {
  const { showPalette, togglePalette } = useCommandPalette();

  useEffect(() => {
    const onKeyDown = (event: KeyboardEvent) => {
      if (event.key === "k" && (event.metaKey || event.ctrlKey)) {
        event.preventDefault();
        togglePalette();
      }
    };

    window.addEventListener("keydown", onKeyDown);

    return () => window.removeEventListener("keydown", onKeyDown);
  }, [showPalette]);
};
