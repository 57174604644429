import { type MiddlewareContext, next } from "~/lib/middleware/middleware";
import { SessionKey } from "~/lib/session";
import { type RequiredAuthenticatedUser } from "~/services/auth/fetch-authenticated-user";
import { resolveImpersonatedUser } from "~/services/auth/user-impersonation/resolve-impersonated-user";

export const resolveUserImpersonation = async (ctx: MiddlewareContext<{ user: RequiredAuthenticatedUser }>) => {
  const impersonatedUser = await resolveImpersonatedUser(ctx.req, {
    impersonatingUser: ctx.user,
    impersonatedUserId: ctx.req.session.get<string>(SessionKey.IMPERSONATED_USER_ID) ?? null,
  });

  if (impersonatedUser) {
    return next({
      user: impersonatedUser,
      impersonatingUser: ctx.user,
    });
  }

  return next({
    ...ctx,
    impersonatingUser: null,
  });
};
