import { type UseQueryOptions, useQuery as useReactQuery } from "@tanstack/react-query";
import { pickBy } from "lodash";
import { type Route, route } from "nextjs-routes";
import { type ParsedUrlQueryInput } from "querystring";
import { type ApiRoutePath, useApi } from "~/hooks/useApi";
import { type ListCompaniesResponse } from "~/pages/api/admin/list-companies";
import { type ClassifyJobResponse } from "~/pages/api/classify-job";
import { type GetEmployeeHistoryResponse } from "~/pages/api/get-employee-history";
import { type ListCountriesResponse } from "~/pages/api/list-countries";
import { type ListCurrenciesResponse } from "~/pages/api/list-currencies";
import { type ListJobFamiliesResponse } from "~/pages/api/list-job-families";
import { type ListJobsResponse } from "~/pages/api/list-jobs";
import { type ListLocationsOrderBy, type ListLocationsResponse } from "~/pages/api/list-locations";

// Locally cache static models (very rarely changing models) for 5 minutes
const STATIC_MODELS_STALE_TIME = 5 * 60 * 1000;

// Locally cache dynamic models for 1 minute. When in doubt, use this one
export const DYNAMIC_MODELS_STALE_TIME = 1 * 60 * 1000;

/**
 * Wrapper around react-query to handle URL building
 * and data fetching with our custom `useApi` hook.
 */
export const useQuery = <TResponse>(params: {
  path: ApiRoutePath;
  params?: ParsedUrlQueryInput;
  options?: UseQueryOptions<TResponse>;
  config?: { method?: "GET" | "POST" };
}) => {
  const { apiFetch } = useApi();

  const definedParams = pickBy(params.params ?? {}, (v) => v !== undefined);

  const path = route({ pathname: params.path, query: definedParams } as Route);

  const fetcher = () =>
    apiFetch<TResponse>(params.path, { method: params.config?.method ?? "GET", query: definedParams });

  return useReactQuery<TResponse>([path], fetcher, params.options);
};

export const useListLocationsQuery = (
  params?: {
    hideRemote?: boolean;
    hideOther?: boolean;
    hideFallbackedLocation?: boolean;
    orderBy?: ListLocationsOrderBy;
  },
  options?: UseQueryOptions<ListLocationsResponse>
) => {
  return useQuery({
    path: "/api/list-locations",
    params: {
      "hide-remote": params?.hideRemote,
      "hide-other": params?.hideOther,
      "hide-fallbacked-location": params?.hideFallbackedLocation ?? true,
      "order-by": params?.orderBy,
    },
    options: {
      staleTime: STATIC_MODELS_STALE_TIME,
      ...options,
    },
  });
};

export const useListCountriesQuery = (options?: UseQueryOptions<ListCountriesResponse>) => {
  return useQuery({
    path: "/api/list-countries",
    options: {
      staleTime: STATIC_MODELS_STALE_TIME,
      ...options,
    },
  });
};

export const useListJobsQuery = (
  params?: { hideEmpty?: boolean; withTargetPercentiles?: boolean; useTestData?: boolean },
  options?: UseQueryOptions<ListJobsResponse>
) => {
  return useQuery({
    path: "/api/list-jobs",
    params: {
      "hide-empty": params?.hideEmpty,
      "with-target-percentiles": params?.withTargetPercentiles,
      "use-test-data": params?.useTestData,
    },
    options: {
      staleTime: STATIC_MODELS_STALE_TIME,
      ...options,
    },
  });
};

export const useListJobFamiliesQuery = (options?: UseQueryOptions<ListJobFamiliesResponse>) => {
  return useQuery({
    path: "/api/list-job-families",
    options: {
      staleTime: STATIC_MODELS_STALE_TIME,
      ...options,
    },
  });
};

export const useListCurrenciesQuery = (options?: UseQueryOptions<ListCurrenciesResponse>) => {
  return useQuery({
    path: "/api/list-currencies",
    options: {
      staleTime: STATIC_MODELS_STALE_TIME,
      ...options,
    },
  });
};

export const useListCompaniesQuery = (
  params: { path?: ApiRoutePath; includeVentureCapitals?: boolean; onlyValidImpersonationAccess?: boolean },
  options?: UseQueryOptions<ListCompaniesResponse>
) => {
  return useQuery({
    path: params.path ?? "/api/admin/list-companies",
    params: {
      "include-venture-capitals": params?.includeVentureCapitals,
      "only-valid-impersonation-access": params?.onlyValidImpersonationAccess,
    },
    options: {
      ...options,
      staleTime: DYNAMIC_MODELS_STALE_TIME,
    },
  });
};

export const useGetEmployeeHistoryQuery = (
  params: { employeeId: number },
  options?: UseQueryOptions<GetEmployeeHistoryResponse>
) => {
  return useQuery({
    path: `/api/get-employee-history`,
    params: { employeeId: params.employeeId },
    options: {
      ...options,
      staleTime: DYNAMIC_MODELS_STALE_TIME,
    },
  });
};

export const useClassifyJobQuery = (
  params: { search: string | null },
  options?: UseQueryOptions<ClassifyJobResponse>
) => {
  return useQuery({
    path: "/api/classify-job",
    params: {
      job: params.search,
    },
    options: {
      enabled: !!params.search && params.search.length >= 3,
      staleTime: STATIC_MODELS_STALE_TIME,
      ...options,
    },
  });
};
