import { generateUserHMAC } from "~/lib/crypto";
import { next, type MiddlewareContext } from "~/lib/middleware/middleware";
import { SessionKey } from "~/lib/session";
import {
  type NullableAuthenticatedUser,
  type RequiredAuthenticatedUser,
} from "~/services/auth/fetch-authenticated-user";

export const generateUserHash = async (
  ctx: MiddlewareContext<{ user: RequiredAuthenticatedUser; impersonatingUser: NullableAuthenticatedUser }>
) => {
  const userHash = ctx.req.session.get<string>(SessionKey.USER_HASH);

  if (!userHash) {
    ctx.req.session.set(SessionKey.USER_HASH, generateUserHMAC(ctx.req.user));

    await ctx.req.session.save();
  }

  return next(ctx);
};
