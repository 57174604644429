import { type AdditionalFieldMapping, type CustomRemunerationItem, type IntegrationSettings } from "@prisma/client";
import { isNotNull } from "~/lib/utils";

export type IntegrationCustomFields = Pick<
  IntegrationSettings,
  | "holidayAllowanceCustomFieldName"
  | "fteCustomFieldName"
  | "levelCustomFieldName"
  | "baseSalaryCustomFieldName"
  | "variableCustomFieldName"
  | "externalIdCustomFieldName"
  | "businessUnitCustomFieldName"
  | "locationCustomFieldName"
  | "jobCustomFieldName"
> & {
  additionalFieldMappings?: Pick<AdditionalFieldMapping, "hrisFieldName" | "additionalFieldId">[];
  customRemunerationItems?: Pick<CustomRemunerationItem, "hrisFieldName" | "id">[];
};

export const BlankIntegrationCustomFields: IntegrationCustomFields = {
  holidayAllowanceCustomFieldName: null,
  fteCustomFieldName: null,
  levelCustomFieldName: null,
  baseSalaryCustomFieldName: null,
  variableCustomFieldName: null,
  externalIdCustomFieldName: null,
  businessUnitCustomFieldName: null,
  locationCustomFieldName: null,
  jobCustomFieldName: null,
  additionalFieldMappings: [],
  customRemunerationItems: [],
};

export const getMissingCustomFields = (
  integrationSettings: IntegrationCustomFields,
  availableFields: {
    id: string;
    name: string;
  }[]
) => {
  return [
    integrationSettings.externalIdCustomFieldName,
    integrationSettings.levelCustomFieldName,
    integrationSettings.baseSalaryCustomFieldName,
    integrationSettings.variableCustomFieldName,
    integrationSettings.fteCustomFieldName,
    integrationSettings.holidayAllowanceCustomFieldName,
    integrationSettings.businessUnitCustomFieldName,
    integrationSettings.locationCustomFieldName,
    integrationSettings.jobCustomFieldName,
    ...(integrationSettings.additionalFieldMappings?.map(({ hrisFieldName }) => hrisFieldName) ?? []),
    ...(integrationSettings.customRemunerationItems?.map(({ hrisFieldName }) => hrisFieldName) ?? []),
  ]
    .filter(isNotNull)
    .filter((customFieldId) => !availableFields.find((field) => customFieldId === field.id.toString()));
};
