import { type IntegrationSource, QueueJobName } from "@prisma/client";
import { map } from "bluebird";
import { chain, values } from "lodash";
import { number, object } from "yup";
import { type AppContext, transaction } from "~/lib/context";
import { getStaticModelsForSync } from "~/lib/integration";
import { logError, logInfo } from "~/lib/logger";
import { BaseJobDataSchema } from "~/lib/queue/base-job-data-schema";
import { fillJobCache, jobCacheTransaction } from "~/lib/queue/queue-job-cache";
import { sendJob } from "~/lib/queue/send-job";
import { type YupOutputType } from "~/lib/utils";
import { syncExternalEmployee } from "~/services/synchronization/sync-external-employee";
import {
  EmployeeDataSchema,
  guessFteFactor,
  integrationSettingsForSyncInclude,
} from "~/services/synchronization/sync-external-employees";
import {
  PostSyncExternalEmployeesHierarchyDataSchema,
  PostSyncExternalEmployeesUpdatesReportDataSchema,
  sendPostSyncExternalEmployeesJob,
} from "~/workers/post-sync-external-employees";

export const SYNC_EXTERNAL_EMPLOYEES_CHUNK_SIZE = 50;
const SYNC_EXTERNAL_EMPLOYEES_MAX_CONCURRENCY = 1;

const SyncExternalEmployeesJobDataSchema = BaseJobDataSchema.concat(object({ singletonKey: number().required() }));

export type SyncExternalEmployeesJobData = YupOutputType<typeof SyncExternalEmployeesJobDataSchema>;

export const syncExternalEmployeesWorkerService = async (ctx: AppContext, data: SyncExternalEmployeesJobData) => {
  const { singletonKey, companyId } = SyncExternalEmployeesJobDataSchema.validateSync(data, {
    abortEarly: false,
  });

  const company = await ctx.prisma.company.findUniqueOrThrow({
    where: { id: companyId },
    include: {
      integrationSettings: {
        where: { enabled: true },
        include: integrationSettingsForSyncInclude,
      },
      defaultCountry: true,
    },
  });

  const staticModels = await getStaticModelsForSync(ctx);

  const { remainingLinesCount } = await jobCacheTransaction(
    ctx,
    {
      companyId: company.id,
      queueJobName: QueueJobName.SYNC_EXTERNAL_EMPLOYEES,
      validationSchema: EmployeeDataSchema,
      chunkSize: SYNC_EXTERNAL_EMPLOYEES_CHUNK_SIZE,
    },
    async (ctx, employeesData) => {
      const fteFactor = guessFteFactor(employeesData);

      const managerExternalIds: { source: IntegrationSource; externalEmployeeId: number; managerExternalId: string }[] =
        [];

      const updatesBySource = chain(company.integrationSettings)
        .groupBy("source")
        .mapValues((_, key) => ({ source: key as IntegrationSource, created: 0, updated: 0 }))
        .value();

      await map(
        employeesData,
        async (employeeData) => {
          const integrationSettings = company.integrationSettings.find(
            (integrationSettings) => integrationSettings.source === employeeData.input.source
          );

          if (!integrationSettings) {
            logError(ctx, "[sync-external-employee] No integration settings found for employee", {
              companyId: company.id,
              externalId: employeeData.input.externalId,
              source: employeeData.input.source,
            });
            return;
          }

          let syncRes;

          try {
            const { externalEmployee, created: isCreated } = await transaction(ctx, () =>
              syncExternalEmployee(ctx, employeeData, {
                company,
                staticModels,
                fteFactor,
                integrationSettings,
              })
            );

            syncRes = {
              isCreated,
              externalEmployeeId: externalEmployee.id,
            };
          } catch (error) {
            logError(ctx, "[sync-external-employee] Couldn't sync employee", {
              companyId: company.id,
              error,
              employeeNumber: employeeData.input.employeeNumber,
            });
          }

          if (syncRes) {
            const { isCreated, externalEmployeeId } = syncRes;
            const updates = updatesBySource[employeeData.input.source];

            if (isCreated) {
              updates && updates.created++;
            } else {
              updates && updates.updated++;
            }

            if (employeeData.managerExternalId) {
              managerExternalIds.push({
                source: integrationSettings.source,
                externalEmployeeId,
                managerExternalId: employeeData.managerExternalId,
              });
            }
          }
        },
        { concurrency: SYNC_EXTERNAL_EMPLOYEES_MAX_CONCURRENCY }
      );

      await fillJobCache(ctx, {
        companyId: company.id,
        queueJobName: QueueJobName.POST_SYNC_EXTERNAL_EMPLOYEES,
        data: values(updatesBySource),
        validationSchema: PostSyncExternalEmployeesUpdatesReportDataSchema,
      });

      if (managerExternalIds.length > 0) {
        await fillJobCache(ctx, {
          companyId: company.id,
          queueJobName: QueueJobName.POST_SYNC_EXTERNAL_EMPLOYEES,
          data: managerExternalIds,
          validationSchema: PostSyncExternalEmployeesHierarchyDataSchema,
        });
      }
    }
  );

  if (remainingLinesCount > 0) {
    logInfo(ctx, "[sync-external-employees] More employees to sync, scheduling another job", {
      companyId: company.id,
      chunkSize: SYNC_EXTERNAL_EMPLOYEES_CHUNK_SIZE,
      queueJobName: QueueJobName.SYNC_EXTERNAL_EMPLOYEES,
      remainingLinesCount,
      nextSingletonKey: singletonKey + 1,
      currentSingletonKey: singletonKey,
    });

    return sendSyncExternalEmployeesJob(ctx, { singletonKey: singletonKey + 1, companyId: company.id });
  }

  return sendPostSyncExternalEmployeesJob(ctx, { companyId: company.id });
};

export const sendSyncExternalEmployeesJob = async (ctx: AppContext, data: SyncExternalEmployeesJobData) => {
  await sendJob(ctx, {
    jobName: QueueJobName.SYNC_EXTERNAL_EMPLOYEES,
    data,
    options: { singletonKey: `${data.companyId}-${data.singletonKey}` },
  });
};
