import { ApiImpersonationForbiddenError, ApiImpersonationMismatchError } from "~/lib/api";
import { MiddlewareRuntime, next, type MiddlewareContext } from "~/lib/middleware/middleware";
import { parseString } from "~/lib/query-params";
import {
  ImpersonateCompanyIdParam,
  ImpersonatedCompanyHeader,
  SessionKey,
  stopCompanyImpersonation,
} from "~/lib/session";
import { AuthenticatedUserIncludes } from "~/services/auth/authenticated-user-includes";
import {
  type NullableAuthenticatedUser,
  type RequiredAuthenticatedUser,
} from "~/services/auth/fetch-authenticated-user";
import { hasImpersonationAccess } from "~/services/impersonation/helper";

export const resolveCompanyImpersonation = async (
  ctx: MiddlewareContext<{ user: RequiredAuthenticatedUser; impersonatingUser: NullableAuthenticatedUser }>
) => {
  const companyIdFromQuery = ctx.query ? parseString(ctx.query, ImpersonateCompanyIdParam) : undefined;
  if (ctx.user.isSuperAdmin && companyIdFromQuery) {
    ctx.req.session.set(SessionKey.IMPERSONATED_COMPANY_ID, companyIdFromQuery);
    await ctx.req.session.save();
  }

  const companyId = ctx.req.session.get<string>(SessionKey.IMPERSONATED_COMPANY_ID);
  if (!companyId) {
    return next(ctx);
  }

  if (ctx.runtime === MiddlewareRuntime.API) {
    const impersonatedCompanyId = ctx.req.headers[ImpersonatedCompanyHeader.toLowerCase()];
    if (impersonatedCompanyId && impersonatedCompanyId !== companyId) {
      throw new ApiImpersonationMismatchError("Impersonation Mismatch", companyId, impersonatedCompanyId.toString());
    }
  }

  const hasValidImpersonationAccess = await hasImpersonationAccess(ctx.req, {
    companyId: +companyId,
  });
  if (hasValidImpersonationAccess) {
    return next({
      ...ctx,
      user: {
        ...ctx.user,
        companyId: parseInt(companyId),
        company: await ctx.req.prisma.company.findUniqueOrThrow({
          where: { id: parseInt(companyId) },
          include: AuthenticatedUserIncludes["company"]["include"],
        }),
      },
    });
  }

  await stopCompanyImpersonation(ctx.req);

  throw new ApiImpersonationForbiddenError("Impersonation not allowed", companyId);
};
