import HttpStatus from "http-status-codes";
import { type NextApiHandler } from "next";
import { number, object } from "yup";
import { type ApiRoute } from "~/hooks/useApi";
import { api } from "~/lib/api";
import { createQuery } from "~/lib/react-query";
import {
  type GetAllAdditionalFieldsResult,
  getAllAdditionalFields,
} from "~/services/additional-field/get-all-additional-fields";

export const GetAllAdditionalFieldSchema = object({
  integrationSettingsId: number().optional(),
});

const handler: NextApiHandler<GetAllAdditionalFieldsResult> = async (req, res) => {
  const allAdditionalField = await getAllAdditionalFields(req);

  return res.status(HttpStatus.OK).json(allAdditionalField);
};

export default api(handler, {
  method: "POST",
  authentication: { canAccessIntegrations: true },
});

export const GET_ALL_ADDITIONAL_FIELD_QUERY_KEY: ApiRoute["pathname"] =
  "/api/additional-field/get-all-additional-field";

export const useGetAllAdditionalFieldQuery = createQuery<typeof handler, typeof GetAllAdditionalFieldSchema>({
  path: GET_ALL_ADDITIONAL_FIELD_QUERY_KEY,
  schema: GetAllAdditionalFieldSchema,
});
