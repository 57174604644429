import { type NullableAuthenticatedUser } from "~/services/auth/fetch-authenticated-user";
import { type FeatureFlagsStatus } from "~/services/feature-flags";
import { type SubscriptionStatusWithPermissions } from "~/services/subscriptions/validate-subscription";
import * as actionPermissions from "./action-permissions";
import * as compensationReviewPermissions from "./compensation-review-permissions";
import * as featureAccessPermissions from "./feature-access-permissions";
import * as marketDataPermissions from "./market-data-permissions";
import { canAccessSalaryBandsTiering, canUpdateSalaryBands } from "./salary-bands-permissions";

export type PermissionsParams = {
  user: NullableAuthenticatedUser;
  subscriptions: SubscriptionStatusWithPermissions;
  featureFlags: FeatureFlagsStatus;
};

export const globalPermissions = {
  ...marketDataPermissions,
  ...compensationReviewPermissions,
  ...featureAccessPermissions,
  ...actionPermissions,
  canUpdateSalaryBands,
  canAccessSalaryBandsTiering,
};

export type Permissions = keyof typeof globalPermissions;

export type PermissionsStatus = Record<Permissions, boolean>;

// Default permissions with every permissions being false
export const defaultPermissions: PermissionsStatus = Object.fromEntries(
  Object.keys(globalPermissions).map((permission) => [permission, false])
) as Record<Permissions, boolean>;
