import {
  DataValidationFlagOrigin,
  DataValidationFlagType,
  EmployeeLevel,
  EmployeeStatus,
  Prisma,
} from "@prisma/client";
import { mapSeries } from "bluebird";
import { chain, compact, map } from "lodash";
import { match } from "ts-pattern";
import { type AsyncReturnType } from "type-fest";
import { type AppContext } from "~/lib/context";
import { getRequiredUser } from "~/lib/get-required-user";
import { logInfo } from "~/lib/logger";
import { validateRequestAuthorised } from "~/lib/security";

//For more info: https://www.notion.so/figures-hr/Data-Validation-Error-Code-f99b35bbf9b7462bab87be3bdbe9afb8
export enum DataValidationCode {
  //Unusual Compensation
  UC1 = "UC1",
  UC2 = "UC2",
  UC3 = "UC3",
  UC4 = "UC4",
  UC5 = "UC5",

  //External Job title and Level mismatch
  JTL1 = "JTL1",
  JTL2 = "JTL2",
  JTL4 = "JTL4",
  JTL5 = "JTL5",
  JTL6 = "JTL6",

  //Wrong Contract Type
  WCT1 = "WCT1",
  WCT2 = "WCT2",
  WCT3 = "WCT3",
  WCT4 = "WCT4",
  WCT5 = "WCT5",

  //Wrong External Level
  UL1 = "UL1",
  UL2 = "UL2",
  UL3 = "UL3",

  //Duplicate Employee
  DE1 = "DE1",
}

export const MINIMUM_THRESHOLD = 5_000_00; // 5.000 EUR
export const NON_DIR_MAXIMUM_THRESHOLD = 300_000_00; // 300.000 EUR
export const GLOBAL_MAXIMUM_THRESHOLD = 2_000_000_00; // 2.000.000 EUR
export const NON_DIR_MAXIMUM_THRESHOLD_TOTAL_CASH = 500_000_00; // 500.000 EUR

export const formatDataValidationFlagType = (flag: DataValidationFlagType): string => {
  return match(flag)
    .with(DataValidationFlagType.SUSPICIOUS_COMPENSATION, () => "Unusual Compensation")
    .with(DataValidationFlagType.SUSPICIOUS_JOB_TITLE, () => "Unusual Job Title")
    .with(DataValidationFlagType.SUSPICIOUS_LEVEL, () => "Unusual Level")
    .with(DataValidationFlagType.OTHER, () => "Other")
    .exhaustive();
};

type DataValidationFlagInputProps = {
  comment: string;
  isLive: boolean;
  type: DataValidationFlagType;
  origin?: DataValidationFlagOrigin;
  authorId?: number;
  companyId?: number;
};

export const createFlagIfNoLiveFlags = async (
  ctx: AppContext,
  employeeId: number,
  data: DataValidationFlagInputProps
) => {
  const user = getRequiredUser(ctx);

  const employee = await ctx.prisma.employee.findFirstOrThrow({
    where: {
      id: employeeId,
      ...(!user.isSuperAdmin && {
        companyId: user.companyId,
      }),
    },
    select: {
      companyId: true,
    },
  });

  validateRequestAuthorised(ctx, { companyId: employee.companyId });

  const liveFlagOrIgnored = await ctx.prisma.employeeDataValidationFlag.findFirst({
    where: {
      employeeId,
      OR: [{ isLive: true }, { isLive: false, ignore: true }],
    },
  });

  if (liveFlagOrIgnored) {
    return liveFlagOrIgnored;
  }

  logInfo(ctx, "[data validation flag] Creating new flag", {
    employeeId,
    companyId: data.companyId,
  });

  return ctx.prisma.employeeDataValidationFlag.create({
    data: {
      ...data,
      companyId: data.companyId ?? employee.companyId,
      type: data.type ?? DataValidationFlagType.OTHER,
      employeeId,
    },
  });
};

export type CreateFlagIfNoLiveFlagsResult = AsyncReturnType<typeof createFlagIfNoLiveFlags>;

const employeeForFlagCheckingArgs = Prisma.validator<Prisma.EmployeeDefaultArgs>()({
  select: {
    id: true,
    level: true,
    baseSalary: true,
    onTargetBonus: true,
    fixedBonus: true,
    currencyId: true,
    externalEmployee: {
      select: {
        job: {
          select: {
            name: true,
          },
        },
        level: {
          select: {
            name: true,
          },
        },
      },
    },
    currency: {
      select: {
        id: true,
        euroExchangeRate: true,
        symbol: true,
      },
    },
    location: {
      select: {
        country: {
          select: {
            legalMinimumWage: true,
          },
        },
      },
    },
  },
});

type EmployeeForFlagChecking = Prisma.EmployeeGetPayload<typeof employeeForFlagCheckingArgs>;

const fetchCompanyEmployeesForFlagChecking = async (ctx: AppContext, { companyId }: { companyId: number }) => {
  return ctx.prisma.employee.findMany({
    where: {
      companyId,
      status: EmployeeStatus.LIVE,
    },
    ...employeeForFlagCheckingArgs,
  });
};

const fetchEmployeeForFlagChecking = async (ctx: AppContext, employeeId: number) => {
  return ctx.prisma.employee.findFirstOrThrow({
    where: {
      id: employeeId,
      status: EmployeeStatus.LIVE,
    },
    ...employeeForFlagCheckingArgs,
  });
};

type EmployeeUnusualFlagData = {
  employeeId: number;
  comment: string;
  type: DataValidationFlagType;
};

const getAllUnusualCompensations = (
  employeeOrEmployees: EmployeeForFlagChecking | EmployeeForFlagChecking[]
): EmployeeUnusualFlagData[] => {
  return compact([employeeOrEmployees].flat().map(checkForCompensationInconsistencies));
};

const getAllUnusualLevels = (
  employeeOrEmployees: EmployeeForFlagChecking | EmployeeForFlagChecking[]
): EmployeeUnusualFlagData[] => {
  return compact([employeeOrEmployees].flat().map(checkForLevelInconsistencies));
};

const checkForLevelInconsistencies = (employee: EmployeeForFlagChecking) => {
  const jobTitleInconsistenciesFlag = checkForJobTitleInconsistencies(employee);
  if (jobTitleInconsistenciesFlag) {
    return jobTitleInconsistenciesFlag;
  }

  const wrongContractTypeFlag = checkForWrongContractType(employee);
  if (wrongContractTypeFlag) {
    return wrongContractTypeFlag;
  }

  const wrongExternalLevelFlag = checkForWrongExternalLevel(employee);
  if (wrongExternalLevelFlag) {
    return wrongExternalLevelFlag;
  }

  // Nothing to report on purpose
  return undefined;
};

const JUNIOR_LEVELS = [EmployeeLevel.JUNIOR, EmployeeLevel.BEGINNER] as EmployeeLevel[];
const NON_MANAGEMENT_LEVELS = [
  EmployeeLevel.JUNIOR,
  EmployeeLevel.BEGINNER,
  EmployeeLevel.PRINCIPAL,
  EmployeeLevel.INTERMEDIATE,
  EmployeeLevel.SENIOR,
  EmployeeLevel.STAFF,
] as EmployeeLevel[];
const JUNIOR_STAFF_LEVELS = [EmployeeLevel.JUNIOR, EmployeeLevel.STAFF] as EmployeeLevel[];
const checkForJobTitleInconsistencies = (employee: EmployeeForFlagChecking) => {
  const externalJobTitle = employee.externalEmployee?.job?.name.toLowerCase();

  if (!externalJobTitle) {
    return undefined;
  }

  const juniorLevels = JUNIOR_LEVELS.includes(employee.level);
  const nonManagementLevels = NON_MANAGEMENT_LEVELS.includes(employee.level);

  const JTL1 = externalJobTitle.includes("team manager") && nonManagementLevels;
  if (JTL1) {
    return {
      employeeId: employee.id,
      comment: `${DataValidationCode.JTL1} - "Team Manager" external job title associated to non management level`,
      type: DataValidationFlagType.SUSPICIOUS_LEVEL,
    };
  }

  const JTL2 = externalJobTitle.includes("senior") && juniorLevels;
  if (JTL2) {
    return {
      employeeId: employee.id,
      comment: `${DataValidationCode.JTL2} - "Senior" external job title associated to junior level`,
      type: DataValidationFlagType.SUSPICIOUS_LEVEL,
    };
  }

  const JTL4 = externalJobTitle.includes("intermediate") && JUNIOR_STAFF_LEVELS.includes(employee.level);
  if (JTL4) {
    return {
      employeeId: employee.id,
      comment: `${DataValidationCode.JTL4} - "Intermediate" external job title associated to junior or staff level`,
      type: DataValidationFlagType.SUSPICIOUS_LEVEL,
    };
  }

  const csuiteJobTitles = ["cto", "cpo", "cro", "cfo", "ceo", "coo"];
  const JTL5 = csuiteJobTitles.some((value) => externalJobTitle === value) && employee.level !== EmployeeLevel.C_LEVEL;

  if (JTL5) {
    return {
      employeeId: employee.id,
      comment: `${DataValidationCode.JTL5} - "C-level" external job title associated to non C-level level`,
      type: DataValidationFlagType.SUSPICIOUS_LEVEL,
    };
  }

  const csuiteFullTitle = externalJobTitle.includes("chief") && externalJobTitle.includes("officer");
  const JTL6 = csuiteFullTitle && employee.level !== EmployeeLevel.C_LEVEL;

  if (JTL6) {
    return {
      employeeId: employee.id,
      comment: `${DataValidationCode.JTL6} - "Chief ... Officer" external job title associated to non C-level level`,
      type: DataValidationFlagType.SUSPICIOUS_LEVEL,
    };
  }

  // Nothing to report on purpose
  return undefined;
};

const checkForWrongContractType = (employee: EmployeeForFlagChecking) => {
  const externalJobTitle = employee.externalEmployee?.job?.name.toLowerCase();

  if (!externalJobTitle) {
    return undefined;
  }

  const excluded = ["internal", "international"];
  const WCT1 = externalJobTitle.includes("intern") && !excluded.some((value) => externalJobTitle.includes(value));

  if (WCT1) {
    return {
      employeeId: employee.id,
      comment: `${DataValidationCode.WCT1} - "Intern" contract should be skipped`,
      type: DataValidationFlagType.SUSPICIOUS_LEVEL,
    };
  }

  const WCT2 = externalJobTitle.includes("freelance");

  if (WCT2) {
    return {
      employeeId: employee.id,
      comment: `${DataValidationCode.WCT2} - "Freelance" contract should be skipped`,
      type: DataValidationFlagType.SUSPICIOUS_LEVEL,
    };
  }

  const apprenticeship = ["apprentice", "apprenticeship", "apprenti", "alternant", "alternants"];
  const WCT3 = apprenticeship.some((value) => externalJobTitle.includes(value));

  if (WCT3) {
    return {
      employeeId: employee.id,
      comment: `${DataValidationCode.WCT3} - "Apprentice" contract should be skipped`,
      type: DataValidationFlagType.SUSPICIOUS_LEVEL,
    };
  }

  const WCT4 = externalJobTitle.includes("student");

  if (WCT4) {
    return {
      employeeId: employee.id,
      comment: `${DataValidationCode.WCT4}- "Student" countract should be skipped`,
      type: DataValidationFlagType.SUSPICIOUS_LEVEL,
    };
  }

  const WCT5 = externalJobTitle.includes("werkstudent");

  if (WCT5) {
    return {
      employeeId: employee.id,
      comment: `${DataValidationCode.WCT5} - "Werkstudent" contract should be skipped`,
      type: DataValidationFlagType.SUSPICIOUS_LEVEL,
    };
  }

  return undefined;
};

const MANAGEMENT_LEVELS = [
  EmployeeLevel.MANAGER,
  EmployeeLevel.DIRECTOR,
  EmployeeLevel.TEAM_LEAD,
  EmployeeLevel.HEAD_OF,
  EmployeeLevel.VP,
  EmployeeLevel.C_LEVEL,
] as EmployeeLevel[];
const checkForWrongExternalLevel = (employee: EmployeeForFlagChecking) => {
  const externalLevel = employee.externalEmployee?.level?.name?.toLowerCase();
  const managementLevels = MANAGEMENT_LEVELS.includes(employee.level);

  if (!externalLevel) {
    return undefined;
  }

  const UL1 =
    externalLevel.includes("senior") && !externalLevel.includes("rookie") && employee.level === EmployeeLevel.JUNIOR;
  if (UL1) {
    return {
      employeeId: employee.id,
      comment: `${DataValidationCode.UL1} - "Senior" external level associated to Junior level`,
      type: DataValidationFlagType.SUSPICIOUS_LEVEL,
    };
  }

  const UL2 = externalLevel.includes("junior") && !JUNIOR_LEVELS.includes(employee.level);
  if (UL2) {
    return {
      employeeId: employee.id,
      comment: `${DataValidationCode.UL2} - "Junior" external level associated to non-Junior level`,
      type: DataValidationFlagType.SUSPICIOUS_LEVEL,
    };
  }

  const UL3 = externalLevel.includes("intermediate") && managementLevels;
  if (UL3) {
    return {
      employeeId: employee.id,
      comment: `${DataValidationCode.UL3} - "Intermediate" external level associated to management level`,
      type: DataValidationFlagType.SUSPICIOUS_LEVEL,
    };
  }

  // Nothing to report on purpose
  return undefined;
};

const DIR_LEVELS = [EmployeeLevel.C_LEVEL, EmployeeLevel.VP, EmployeeLevel.DIRECTOR] as EmployeeLevel[];
const checkForCompensationInconsistencies = (employee: EmployeeForFlagChecking) => {
  const euroSalary =
    employee.currencyId === 1 ? employee.baseSalary : employee.baseSalary / employee.currency.euroExchangeRate;

  const UC1 = euroSalary < MINIMUM_THRESHOLD;

  if (UC1) {
    return {
      employeeId: employee.id,
      comment: `${DataValidationCode.UC1} - The compensation (${employee.baseSalary / 100}${
        employee.currency.symbol
      }) of this employee is under our threshold of 5.000€`,
      type: DataValidationFlagType.SUSPICIOUS_COMPENSATION,
    };
  }

  const UC2 = employee.location.country.legalMinimumWage && euroSalary < employee.location.country.legalMinimumWage;

  if (UC2) {
    return {
      employeeId: employee.id,
      comment: `${DataValidationCode.UC2} - The compensation (${employee.baseSalary / 100}${
        employee.currency.symbol
      }) of this employee is under the legal minimum wage of his country (${
        employee.location.country.legalMinimumWage
      } ${employee.currency.symbol})`,
      type: DataValidationFlagType.SUSPICIOUS_COMPENSATION,
    };
  }

  const UC3 = euroSalary > NON_DIR_MAXIMUM_THRESHOLD && !DIR_LEVELS.includes(employee.level);

  if (UC3) {
    return {
      employeeId: employee.id,
      comment: `${DataValidationCode.UC3} - The compensation (${employee.baseSalary / 100}${
        employee.currency.symbol
      }) of this employee is over our threshold for non C-level employees`,
      type: DataValidationFlagType.SUSPICIOUS_COMPENSATION,
    };
  }

  const UC4 = euroSalary > GLOBAL_MAXIMUM_THRESHOLD;

  if (UC4) {
    return {
      employeeId: employee.id,
      comment: `${DataValidationCode.UC4} - The compensation (${employee.baseSalary / 100}${
        employee.currency.symbol
      }) of this employee is over our global threshold`,
      type: DataValidationFlagType.SUSPICIOUS_COMPENSATION,
    };
  }

  const totalCash = employee.baseSalary + (employee.onTargetBonus ?? 0) + (employee.fixedBonus ?? 0);
  const euroTotalCash = employee.currencyId === 1 ? totalCash : totalCash / employee.currency.euroExchangeRate;

  const UC5 =
    euroTotalCash > NON_DIR_MAXIMUM_THRESHOLD_TOTAL_CASH &&
    euroTotalCash > 3 * euroSalary &&
    !DIR_LEVELS.includes(employee.level);

  if (UC5) {
    return {
      employeeId: employee.id,
      comment: `${DataValidationCode.UC5} - The total compensation (${totalCash / 100}${
        employee.currency.symbol
      }) of this employee is over our threshold for non C-level/VP employees`,
      type: DataValidationFlagType.SUSPICIOUS_COMPENSATION,
    };
  }
  return undefined;
};

export const getUnusualFlagsForCompanyEmployees = async (ctx: AppContext, { companyId }: { companyId: number }) => {
  validateRequestAuthorised(ctx, { companyId });

  const employees = await fetchCompanyEmployeesForFlagChecking(ctx, { companyId });
  const employeesToBeFlagged = [...getAllUnusualLevels(employees), ...getAllUnusualCompensations(employees)];

  const employeesToBeFlaggedIds = map(employeesToBeFlagged, "employeeId");
  const employeesWithoutAutomatedFlags = chain(employees).map("id").difference(employeesToBeFlaggedIds).value();

  return {
    employeesToBeFlagged,
    employeesWithoutAutomatedFlags,
  };
};

const getUnusualFlagsForEmployee = async (ctx: AppContext, employeeId: number) => {
  const employee = await fetchEmployeeForFlagChecking(ctx, employeeId);

  return [...getAllUnusualLevels(employee), ...getAllUnusualCompensations(employee)];
};

export const createCompanyEmployeesFlags = async (
  ctx: AppContext,
  companyId: number,
  origin: DataValidationFlagOrigin
) => {
  const { employeesToBeFlagged: companyEmployeesFlagData, employeesWithoutAutomatedFlags } =
    await getUnusualFlagsForCompanyEmployees(ctx, {
      companyId,
    });

  await removedAutomatedFlags(ctx, employeesWithoutAutomatedFlags);

  return mapSeries(companyEmployeesFlagData, async (flag) => {
    return createFlagIfNoLiveFlags(ctx, flag.employeeId, {
      comment: flag.comment,
      companyId,
      isLive: true,
      type: flag.type,
      origin,
    });
  });
};

export const createEmployeeFlags = async (
  ctx: AppContext,
  employeeId: number,
  companyId: number,
  origin: DataValidationFlagOrigin
) => {
  validateRequestAuthorised(ctx, { companyId });

  await removedAutomatedFlags(ctx, [employeeId]);

  const employeeFlagData = await getUnusualFlagsForEmployee(ctx, employeeId);

  await mapSeries(employeeFlagData, async (flag) => {
    return createFlagIfNoLiveFlags(ctx, employeeId, {
      comment: flag.comment,
      companyId,
      isLive: true,
      type: flag.type,
      origin,
    });
  });
};

const removedAutomatedFlags = async (ctx: AppContext, employeeIds: number[]) => {
  return ctx.prisma.employeeDataValidationFlag.updateMany({
    where: {
      employeeId: {
        in: employeeIds,
      },
      isLive: true,
      ignore: false,
      origin: DataValidationFlagOrigin.HRIS_SYNC,
    },
    data: {
      isLive: false,
    },
  });
};
