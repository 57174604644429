import { type AsyncReturnType } from "type-fest";
import { type AppContext } from "~/lib/context";
import { getRequiredUser } from "~/lib/get-required-user";
import { fetchAllowedCompanyLevelFramework } from "~/services/level-framework/fetch-allowed-company-level-framework";

export const fetchAllowedCompanyBenchmarkLevels = async (ctx: AppContext) => {
  const allowedLevelFrameworks = await fetchAllowedCompanyLevelFramework(ctx);

  const allBenchmarkLevels = await ctx.prisma.benchmarkLevel.findMany({
    where: {
      frameworkId: {
        in: allowedLevelFrameworks.map((framework) => framework.id),
      },
    },
    include: {
      framework: {
        select: {
          name: true,
        },
      },
    },
    orderBy: {
      min: "asc",
    },
  });

  if (!ctx.user) {
    return allBenchmarkLevels;
  }

  const user = getRequiredUser(ctx);

  if (user.permissions.allowedBenchmarkLevels.length > 0) {
    return allBenchmarkLevels.filter((level) => {
      return user.permissions.allowedBenchmarkLevels.some((allowedLevel) => allowedLevel.id === level.id);
    });
  }

  return allBenchmarkLevels;
};

export type FetchAllowedCompanyBenchmarkLevelsResult = AsyncReturnType<typeof fetchAllowedCompanyBenchmarkLevels>;
