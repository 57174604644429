import { QueueJobName } from "@prisma/client";
import { array, mixed, number, object, string } from "yup";
import { type AppContext } from "~/lib/context";
import { type SpreadsheetRow } from "~/lib/external/google-sheets/client";
import { BaseJobDataSchema } from "~/lib/queue/base-job-data-schema";
import { sendJob } from "~/lib/queue/send-job";
import { type YupOutputType } from "~/lib/utils";

const GenerateSpreadsheetJobDataSchema = BaseJobDataSchema.concat(
  object({
    googleSheetId: number().required(),
    values: array().of(mixed<SpreadsheetRow<number>>().required()).ensure(),
    rangePattern: string().required(),
    startingRow: number().required(),
  })
);

export type GenerateSpreadsheetJobData = YupOutputType<typeof GenerateSpreadsheetJobDataSchema>;

export const generateSpreadsheetWorkerService = async (ctx: AppContext, data: GenerateSpreadsheetJobData) => {
  const input = GenerateSpreadsheetJobDataSchema.validateSync(data, { abortEarly: false });

  const googleSheet = await ctx.prisma.googleSheet.findUniqueOrThrow({
    where: { id: input.googleSheetId },
  });

  await ctx.gsheet.fillSpreadsheet({
    spreadsheetId: googleSheet.fileId,
    values: input.values,
    rangePattern: input.rangePattern,
    startingRow: input.startingRow,
  });
};

export const sendGenerateSpreadsheetJob = async (ctx: AppContext, data: GenerateSpreadsheetJobData) => {
  return sendJob(ctx, {
    jobName: QueueJobName.GENERATE_SPREADSHEET,
    data,
    options: { singletonKey: data.googleSheetId.toString() },
  });
};
