import { AuthenticationError } from "~/lib/api";
import tracer from "~/lib/datadog/tracing";
import { buildUser } from "~/lib/logger";
import { done, next, type MiddlewareContext } from "~/lib/middleware/middleware";
import { fetchAuthenticatedUser } from "~/services/auth/fetch-authenticated-user";

export const fetchUserFromSession = async (
  ctx: MiddlewareContext<{ userId: string }>,
  options: { optional: boolean }
) => {
  const user = await fetchAuthenticatedUser(ctx.req, {
    userId: parseInt(ctx.userId),
  });

  if (user) {
    // This makes no sense but fixes a weird compilation issue.
    if (typeof window === "undefined") {
      tracer.setUser(buildUser(user));
    }

    return next({ user });
  }

  if (options.optional) {
    return done();
  }

  throw new AuthenticationError("Unauthorised access", {
    targetUrl: ctx.resolvedUrl,
    error: "no_user",
  });
};
