import {
  Check,
  DragIndicator,
  InfoOutlined,
  LayersOutlined,
  Search,
  VisibilityOffOutlined,
  VisibilityOutlined,
} from "@mui/icons-material";
import {
  Checkbox,
  CircularProgress,
  FormGroup,
  InputAdornment,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import classNames from "classnames";
import { compact, isFunction, isString, keyBy, mapValues, orderBy, partition, sumBy } from "lodash";
import { useRouter } from "next/router";
import React, { type ChangeEvent, type CSSProperties, type ReactElement, useEffect, useMemo, useState } from "react";
import { type ItemInterface, ReactSortable } from "react-sortablejs";
import { useRendersCount } from "react-use";
import { useDebounce } from "use-debounce";
import { value } from "~/components/helpers";
import { Icon } from "~/components/ui/core/Icon";
import { Link, NO_LINK } from "~/components/ui/core/Link";
import { TitledTooltip } from "~/components/ui/core/TitledTooltip";
import { Button } from "~/components/ui/core/button";
import { DropDown } from "~/components/ui/core/drop-down";
import { useDelayedLoadingState } from "~/hooks/useDelayedLoadingState";
import { useSession } from "~/hooks/useSession";
import { useI18n } from "~/lib/i18n/use-i18n";
import { type OrderParams, type PaginationResult, parseOrderParams } from "~/lib/pagination";
import { parseString } from "~/lib/query-params";
import { currentUrlWithQueryParams } from "~/lib/url";
import { stripDiacritics } from "~/lib/utils";
import { type TableColumnVisibilityKeys } from "~/pages/api/update-user-flag";

export const CHECKBOX_WIDTH = 42;

export type Column<Entry> = {
  id: string;
  name?: string;
  formatName?: () => string | JSX.Element;
  tooltip?: string;
  sortable?: boolean;
  grow?: number;
  size?: number;
  fixed?: boolean;
  maxWidth?: string | number;
  borderLeft?: boolean;
  align?: "left" | "right" | "center";
  headerStyle?: CSSProperties;
  selector?: keyof Entry | ((row: Entry) => string | number | boolean | Date | null | undefined);
  format?: (row: Entry, index: number) => JSX.Element | string | number | null;
  classNames?: (row: Entry, index: number) => string | null;
  styles?: (row: Entry, index: number) => CSSProperties | null;
  visible?: boolean;
  hideFromColumnsVisibilityDropdown?: boolean;
  orderingId?: string;
};

export type RowSelection = { [key: number]: boolean };

type Props<Entry> = {
  className?: string;
  columns: Column<Entry>[];
  data: Entry[];
  dense?: boolean;
  inCard?: boolean;
  visibleColumnsKey?: TableColumnVisibilityKeys;
  navigation?: LocalNavigation<Entry> | UrlSyncedNavigation<Entry>;
  hideEmptyPagination?: boolean;
  hideTableHead?: boolean;
  highlightedData?: Entry[];
  highlightClass?: string;
  striped?: boolean;
  onRowClick?: (entry: Entry, index: number) => void;
  disabledClick?: boolean;
  noDataComponent?: JSX.Element;
  afterSort?: (sortedData: Entry[]) => void;
  renderHeader?: (components: {
    searchInput?: JSX.Element;
    visibilityDropDown: (className?: string) => JSX.Element;
  }) => JSX.Element;
  initialSort?: {
    onSort?: (columnId: string) => Promise<void>;
    sortByColumn?: string | null;
  };
  tableSelection?: ReturnType<typeof useTableSelection<Entry>>;
  rowsAreLoading?: boolean;
  rowToKey?: (row: Entry) => string | number;
};

type LocalNavigation<Entry> = {
  type: "local";
  pagination?: { perPage: number };
  search?: {
    fullWidth?: boolean;
    placeholder?: string;
    dense?: boolean;
    entryToStrings: (row: Entry) => string[];
    afterSearch?: (visibleRows: Entry[]) => void;
  };
};

type UrlSyncedNavigation<Entry> = {
  type: "url";
  shallow?: boolean;
  pagination: {
    meta: PaginationResult<Entry>;
  };
  search?: {
    fullWidth?: boolean;
    placeholder?: string;
    dense?: boolean;
  };
};

const initialSortSeparator = "***";

const getSavedColumns = <Entry,>(
  dbColumns: Pick<Column<Entry>, "id" | "visible">[],
  defaultColumns: Column<Entry>[]
) => {
  const [existingDbColumns, nonExistingDbColumns] = partition(defaultColumns, (column) =>
    dbColumns.map(({ id }) => id).includes(column.id)
  );

  return compact([
    ...dbColumns.map((dbColumn) => {
      const column = existingDbColumns.find(({ id }) => id === dbColumn.id);
      if (!column) return null;

      return {
        ...column,
        visible: dbColumn.visible,
      };
    }),
    ...nonExistingDbColumns,
  ]);
};

export const Table = <Entry,>({
  columns,
  data,
  dense = false,
  inCard = false,
  navigation = { type: "local" },
  hideTableHead = false,
  hideEmptyPagination = false,
  striped = true,
  highlightedData = [],
  highlightClass = "bg-primary-300",
  onRowClick,
  disabledClick,
  noDataComponent,
  afterSort,
  renderHeader,
  initialSort,
  tableSelection,
  visibleColumnsKey,
  className,
  rowToKey,
  rowsAreLoading,
}: Props<Entry>): ReactElement => {
  const { t } = useI18n();
  const router = useRouter();
  const routerOrderParams = navigation.type === "url" ? parseOrderParams(router.query) : null;
  const isLoaderVisible = useDelayedLoadingState({ value: rowsAreLoading ?? false });

  const { user, updateFlag, isUpdatingFlag } = useSession();
  const [isVisibilityDropdownOpen, setIsVisibilityDropdownOpen] = useState<boolean>(false);

  const [localPage, setLocalPage] = useState(0);
  const [query, setQuery] = useState<string>(parseString(router.query, "query") ?? "");
  const [orderColumnId, setOrderColumnId] = useState<string | null>(routerOrderParams?.column ?? null);
  const [orderDirection, setOrderDirection] = useState<OrderParams["direction"]>(routerOrderParams?.direction ?? "asc");

  const fixedList = columns.filter((columns) => columns.fixed);

  const hasDbColumns = visibleColumnsKey && user && user.flags[visibleColumnsKey].length;

  const getOrderList = () => {
    const orderedColumns = hasDbColumns
      ? getSavedColumns(user.flags[visibleColumnsKey] as Pick<Column<Entry>, "id" | "visible">[], columns)
      : columns;

    return orderedColumns.filter((c) => !c.fixed);
  };

  const getVisibleColumns = () => {
    const visibleColumns = hasDbColumns
      ? getSavedColumns(user.flags[visibleColumnsKey] as Pick<Column<Entry>, "id" | "visible">[], columns)
      : columns;

    return mapValues(
      keyBy(visibleColumns, (column) => column.id),
      (column) => column.visible ?? true
    );
  };

  const [orderList, setOrderList] = useState(getOrderList);
  const [visibleColumns, setVisibleColumns] = useState(getVisibleColumns);

  useEffect(() => {
    setOrderList(getOrderList);
    setVisibleColumns(getVisibleColumns);
  }, [JSON.stringify(columns)]);

  const columnsObject = keyBy(columns, (column) => column.id);

  const changeVisibleColumns = (name: string | number) => {
    setVisibleColumns({
      ...visibleColumns,
      [name]: !visibleColumns[name],
    });
  };

  useEffect(() => {
    if (!initialSort?.sortByColumn) return;
    const [columnId, order] = initialSort.sortByColumn.split(initialSortSeparator);
    if (!columnId || !order) return;

    setOrderColumnId(columnId);
    setOrderDirection(order as OrderParams["direction"]);
  }, []);

  const offset = localPage * (navigation.type === "local" ? navigation.pagination?.perPage ?? 0 : 0);

  const displayedColumns = columns.filter((column) => !!visibleColumns[column.id]);

  const totalMaxSize = sumBy(displayedColumns, (column) => column.grow ?? 1);
  const columnMaxSizes = displayedColumns.map((column) => (column.grow ?? 1) / totalMaxSize);

  const totalSize = sumBy(displayedColumns, (column) => column.size ?? 1);
  const columnSizes = displayedColumns.map((column) => (column.size ?? 1) / totalSize);

  /**
   * In "local" navigation mode, filter the data with the provided callback
   */
  const filteredData = useMemo(() => {
    if (navigation.type !== "local" || !navigation.search || !query.length) {
      return data;
    }

    const queryLower = query.toLowerCase();

    return data.filter((entry) => {
      if (!navigation.search) {
        return true;
      }

      const strings = navigation.search.entryToStrings(entry);

      return strings.some((string) => {
        return stripDiacritics(string)
          .toLowerCase()
          .includes(queryLower as string);
      });
    });
  }, [data, query]);

  /**
   * In "local" navigation mode, trigger afterSearch when the filtered data has changed
   */
  useEffect(() => {
    if (navigation.type !== "local" || !navigation.search?.afterSearch) {
      return;
    }

    navigation.search.afterSearch(filteredData);
  }, [filteredData]);

  /**
   * In "local" navigation mode, compute the pages count based on the filtered data size
   */
  const pagesCount = useMemo(() => {
    if (navigation.type !== "local" || !navigation.pagination) {
      return null;
    }

    return Math.ceil(filteredData.length / navigation.pagination.perPage);
  }, [filteredData]);

  /**
   * In "local" navigation mode, order the data using the state selector & order
   */
  const sortedData = useMemo(() => {
    if (navigation.type !== "local") {
      return filteredData;
    }

    return orderBy(
      filteredData,
      (entry) => {
        if (orderColumnId === null) {
          return true;
        }

        const column = displayedColumns.find(
          (column) => column.orderingId === orderColumnId || column.id === orderColumnId
        );
        if (!column) {
          return true;
        }

        return isFunction(column.selector) ? column.selector(entry) : !!column.selector ? entry[column.selector] : null;
      },
      orderDirection
    );
  }, [filteredData, orderColumnId, orderDirection]);

  /**
   * In "local" navigation mode, slice the data using the local state
   */
  const displayedData = useMemo(() => {
    if (navigation.type !== "local" || !navigation.pagination) {
      return sortedData;
    }

    return sortedData.slice(offset, offset + navigation.pagination.perPage);
  }, [sortedData, offset]);

  const totalCount = navigation.type === "url" ? navigation.pagination.meta.count : displayedData.length;

  const showFullTableSelector = useMemo(() => {
    if (!tableSelection?.enableFullSelection) return false;
    if (!displayedData.length) return false;
    if (displayedData.length === totalCount) return false;

    const selectedData = displayedData.filter((entry) => {
      if (!tableSelection?.rowSelection || !tableSelection.getRowSelectionIndex) return false;

      return tableSelection.rowSelection[tableSelection.getRowSelectionIndex(entry)];
    });

    return selectedData.length === displayedData.length;
  }, [
    displayedData,
    tableSelection?.enableFullSelection,
    tableSelection?.rowSelection,
    tableSelection?.getRowSelectionIndex,
  ]);

  useEffect(() => {
    afterSort?.(sortedData);
  }, [JSON.stringify(sortedData)]);

  /**
   * In "URL" navigation mode, synchronise the search query.
   */
  const [debouncedQuery] = useDebounce(query, 300);
  const isRendered = useRendersCount() > 1;
  useEffect(() => {
    if (!isRendered) return;

    if (navigation.type !== "url") {
      return;
    }

    if (!isString(debouncedQuery)) {
      return;
    }

    void router.replace(currentUrlWithQueryParams(router, { page: null, query: debouncedQuery }), undefined, {
      shallow: navigation.shallow,
    });
  }, [debouncedQuery]);

  /**
   * In "URL" navigation mode, synchronise the order column & direction.
   */
  useEffect(() => {
    if (navigation.type !== "url") {
      return;
    }

    const column = displayedColumns.find(
      (column) => column.orderingId === orderColumnId || column.id === orderColumnId
    );
    if (!column || !column.sortable) {
      return;
    }

    const orderParam = orderColumnId;
    const directionParam = orderDirection === "desc" ? "desc" : null;

    void router.replace(
      currentUrlWithQueryParams(router, { page: null, order: orderParam, direction: directionParam }),
      undefined,
      { shallow: navigation.shallow }
    );
  }, [orderColumnId, orderDirection]);

  const searchInput = navigation.search ? (
    <TextField
      variant="outlined"
      className={classNames({
        "w-64": !navigation.search.fullWidth,
        "w-full": navigation.search.fullWidth,
      })}
      placeholder={navigation.search.placeholder ?? t("components.core.table.filter-results")}
      type="text"
      size="small"
      value={query}
      onChange={(event) => {
        setLocalPage(0);
        setQuery(event.target.value);
      }}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Search fontSize="small" className="text-gray-500" />
          </InputAdornment>
        ),
      }}
    />
  ) : undefined;

  const visibilityDropDown = (className?: string) => {
    return (
      <DropDown
        open={isVisibilityDropdownOpen}
        onOpenChange={(open: boolean) => setIsVisibilityDropdownOpen(open)}
        className={className}
        label={t("components.core.table.columns")}
        ButtonProps={{ startIcon: <LayersOutlined fontSize="medium" /> }}
        MenuProps={{
          anchorOrigin: { vertical: "bottom", horizontal: "right" },
          transformOrigin: { vertical: "top", horizontal: "right" },
        }}
        header={t("components.core.table.ordering-visibility")}
      >
        <FormGroup className="flex max-h-[225px] flex-col flex-nowrap overflow-x-scroll">
          <div className="overflow-x-scroll">
            <ReactSortable list={orderList} setList={setOrderList} handle=".handler">
              {orderList
                .filter((c) => !c.hideFromColumnsVisibilityDropdown)
                .map((item: ItemInterface) => (
                  <Stack key={item.id} direction="row" alignItems="center">
                    <ListItemIcon
                      className={classNames("handler text-gray-500", {
                        "cursor-grab": !item.chosen,
                        "cursor-grabbing": item.chosen,
                      })}
                    >
                      <DragIndicator fontSize="small" />
                    </ListItemIcon>

                    <MenuItem className="w-full px-1" onClick={() => changeVisibleColumns(item.id)}>
                      <ListItemText className={classNames({ "opacity-40": !visibleColumns[item.id] })}>
                        {item.name}
                      </ListItemText>

                      <ListItemIcon className="ml-4">
                        {visibleColumns[item.id] ? (
                          <VisibilityOutlined fontSize="small" />
                        ) : (
                          <VisibilityOffOutlined fontSize="small" className="text-gray-300" />
                        )}
                      </ListItemIcon>
                    </MenuItem>
                  </Stack>
                ))}
            </ReactSortable>
          </div>

          {!!visibleColumnsKey && (
            <Stack className="border-t py-2 px-1">
              <Button
                variant="outlined"
                startIcon={<Check fontSize="small" />}
                isLoading={isUpdatingFlag}
                onClick={async () => {
                  await updateFlag(
                    {
                      [visibleColumnsKey]: orderList.map(({ id }) => ({ id, visible: !!visibleColumns[id] })),
                    },
                    t("components.core.table.columns-saved")
                  );

                  setIsVisibilityDropdownOpen(false);
                }}
              >
                {t("components.core.table.save-columns")}
              </Button>
            </Stack>
          )}
        </FormGroup>
      </DropDown>
    );
  };

  const ACTION_ID = "checkbox";
  const actionColumn: Column<Entry> = { id: ACTION_ID };

  const columnList = value(() => {
    if (tableSelection?.rowSelection) return [actionColumn, ...fixedList, ...orderList];

    return [...fixedList, ...orderList];
  });

  return (
    <div className={classNames(className, "relative flex w-full flex-col")}>
      {renderHeader?.({ searchInput, visibilityDropDown })}

      {!renderHeader && navigation.search && searchInput}

      {showFullTableSelector && (
        <Stack
          className="-mx-6 border-b bg-gray-100 py-1"
          direction="row"
          alignItems="center"
          justifyContent="center"
          spacing={2}
        >
          {tableSelection?.isFullSelected && (
            <>
              <Typography>
                {t("components.core.table.rows-are-selected", {
                  paginationCount: totalCount.toString(),
                })}
              </Typography>
              <Button variant="text" onClick={tableSelection.resetSelection}>
                {t("components.core.table.clear-selection")}
              </Button>
            </>
          )}

          {!tableSelection?.isFullSelected && (
            <>
              <Typography>
                {t("components.core.table.rows-on-this-page", { displayedData: displayedData.length.toString() })}{" "}
              </Typography>
              <Button
                variant="text"
                onClick={() => {
                  tableSelection?.onFullSelectionChange?.(true);
                }}
              >
                {t("components.core.table.select-all", {
                  paginationCount: totalCount.toString(),
                })}
              </Button>
            </>
          )}
        </Stack>
      )}

      <div
        className={classNames({
          "overflow-x-auto": true,
          "-mx-6": inCard,
          "relative": true,
        })}
      >
        {isLoaderVisible && (
          <div className="absolute inset-0 z-20 flex h-full w-full items-center justify-center bg-gray-900 bg-opacity-25">
            <CircularProgress size={30} color="secondary" />
          </div>
        )}
        <table className="w-full text-xs text-gray-900">
          {!hideTableHead && (
            <thead>
              <tr className="bg-white">
                {columnList.map((col, index) => {
                  const column = columnsObject[col.id];

                  if (col.id === ACTION_ID && tableSelection) {
                    return (
                      <CheckboxCell
                        key={col.id}
                        rowSelection={tableSelection.rowSelection}
                        onRowSelectionChange={tableSelection.onRowSelectionChange}
                        getRowSelectionIndex={tableSelection?.getRowSelectionIndex}
                        displayedData={displayedData}
                        enableRowSelection={tableSelection.enableRowSelection}
                        disabled={displayedData.every((entry) => !tableSelection.enableRowSelection(entry))}
                        index={index}
                        isHead
                        fixed={!!fixedList.length}
                      />
                    );
                  }

                  if (visibleColumns[col.id] === false || !column) return null;

                  const { align = "left" } = column;

                  const sizeIndex = tableSelection ? index - 1 : index;
                  const maxWidth = column.maxWidth ?? `${(columnMaxSizes[sizeIndex] as number) * 100}%`;
                  const width = `${(columnSizes[sizeIndex] as number) * 100}%`;

                  const name = column.formatName ? column.formatName() : column.name;
                  const lastFixed = column.fixed && !displayedColumns[index + 1]?.fixed;

                  return (
                    <th
                      key={index}
                      className={classNames({
                        "font-semibold": true,
                        "py-3 px-4": !dense,
                        "py-1 px-2": dense,
                        "cursor-pointer": column.sortable,
                        "group hover:text-primary-600": column.sortable,
                        "border-l-2 border-gray-400": column.borderLeft,
                        "text-left": align === "left",
                        "text-right": align === "right",
                        "text-center": align === "center",
                        "sticky z-10 bg-white": column.fixed,
                      })}
                      style={{ ...column.headerStyle, maxWidth, width }}
                      onClick={async () => {
                        const id = column.orderingId ?? column.id;

                        if (!column.sortable) {
                          return;
                        }
                        if (id !== orderColumnId) {
                          setOrderDirection("asc");
                          await initialSort?.onSort?.(`${id}${initialSortSeparator}asc`);
                        } else {
                          const direction = orderDirection === "asc" ? "desc" : "asc";
                          setOrderDirection(direction);
                          await initialSort?.onSort?.(`${id}${initialSortSeparator}${direction}`);
                        }

                        setOrderColumnId(id);
                      }}
                    >
                      <div
                        className={classNames({
                          "relative flex items-center space-x-2 whitespace-nowrap": true,
                          "justify-end": align === "right",
                          "justify-center": align === "center",
                        })}
                      >
                        {!column.tooltip && name && <span>{name}</span>}

                        {column.tooltip && column.name && (
                          <TitledTooltip heading={column.name} text={column.tooltip ?? ""}>
                            <Stack direction="row" alignItems="center" gap={1}>
                              {name && <span>{name}</span>}
                              <InfoOutlined className="text-base text-gray-500" />
                            </Stack>
                          </TitledTooltip>
                        )}

                        {(column.orderingId === orderColumnId || column.id === orderColumnId) && (
                          <Icon
                            className="text-gray-500 group-hover:text-primary-600"
                            name={orderDirection === "asc" ? "arrow-up" : "arrow-down"}
                          />
                        )}
                      </div>

                      {lastFixed && <div className="absolute top-0 bottom-0 right-1 w-px bg-gray-200" />}
                    </th>
                  );
                })}
              </tr>
            </thead>
          )}

          <tbody>
            <>
              {sortedData.length === 0 && rowsAreLoading === false && (
                <tr>
                  <td colSpan={displayedColumns.length}>
                    {noDataComponent ?? (
                      <div className="w-full p-5 text-center text-sm font-medium">
                        {t("components.core.table.no-records-found")}
                      </div>
                    )}
                  </td>
                </tr>
              )}

              {displayedData.map((entry, index) => {
                const highlight = highlightedData.includes(entry);

                return (
                  <tr
                    key={rowToKey?.(entry) ?? index}
                    className={classNames({
                      "group transition": striped,
                      "bg-gray-50": striped && index % 2 === 0,
                      "bg-white": (striped && index % 2 === 1) || !striped,
                      "cursor-not-allowed": disabledClick,
                      "cursor-pointer hover:bg-primary-100": onRowClick && !disabledClick,
                      [highlightClass]: highlight,
                    })}
                  >
                    {columnList.map((col, columnIndex) => {
                      const column = columnsObject[col.id];

                      if (col.id === ACTION_ID && tableSelection) {
                        return (
                          <CheckboxCell
                            key={col.id}
                            rowSelection={tableSelection.rowSelection}
                            onRowSelectionChange={tableSelection.onRowSelectionChange}
                            getRowSelectionIndex={tableSelection.getRowSelectionIndex}
                            displayedData={displayedData}
                            enableRowSelection={tableSelection.enableRowSelection}
                            disabled={!tableSelection.enableRowSelection(entry)}
                            entry={entry}
                            index={index}
                            fixed={!!fixedList.length}
                          />
                        );
                      }

                      if (visibleColumns[col.id] === false || !column) return null;

                      const sizeIndex = tableSelection ? columnIndex - 1 : columnIndex;
                      const size = column.maxWidth ?? `${columnMaxSizes[sizeIndex] as number}%`;
                      const lastFixed = column.fixed && !displayedColumns[columnIndex + 1]?.fixed;

                      const value = isFunction(column.selector)
                        ? column.selector(entry)
                        : !!column.selector
                        ? entry[column.selector]
                        : null;

                      const formattedValue = column.format ? column.format(entry, index) : value?.toString();

                      return (
                        <td
                          key={column.id}
                          className={classNames(column.classNames?.(entry, index), {
                            "whitespace-nowrap py-2 px-4": !dense,
                            "py-1 px-2": dense,
                            "border-l-2 border-gray-400": column.borderLeft,
                            "text-right": column.align === "right",
                            "text-center": column.align === "center",
                            "sticky z-10 bg-inherit": column.fixed,
                          })}
                          style={{ ...column.styles?.(entry, index), maxWidth: size }}
                          onClick={() => {
                            if (column.id !== "actions" && !disabledClick) {
                              onRowClick?.(entry, offset + index);
                            }
                          }}
                        >
                          <div>{formattedValue}</div>
                          {lastFixed && <div className="absolute top-0 bottom-0 right-1 w-px bg-gray-200" />}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </>
          </tbody>
        </table>
      </div>

      {navigation.type === "local" && pagesCount !== null && pagesCount > 0 && (
        <div className="mt-6 mb-2 mr-6 flex items-center space-x-2 self-center">
          <Button
            size="small"
            variant="contained"
            disabled={localPage <= 0}
            onClick={() => setLocalPage(localPage - 1)}
            className="min-w-0"
          >
            &larr;
          </Button>
          <Typography className="text-sm text-gray-700">
            {t("components.core.table.start-page", {
              pageCount: pagesCount.toString(),
              localPage: String(localPage + 1),
            })}
          </Typography>
          <Button
            size="small"
            variant="contained"
            disabled={localPage >= pagesCount - 1}
            onClick={() => setLocalPage(localPage + 1)}
            className="min-w-0"
          >
            &rarr;
          </Button>
        </div>
      )}

      {navigation.type === "url" &&
        navigation.pagination.meta.count > 0 &&
        !(hideEmptyPagination && navigation.pagination.meta.totalPages === 1) && (
          <div className="mt-6 mb-2 mr-6 flex items-center space-x-2 self-center">
            <Link
              noLinkStyle
              shallow={navigation.shallow}
              to={
                navigation.pagination.meta.previousPage
                  ? currentUrlWithQueryParams(router, {
                      page: navigation.pagination.meta.previousPage,
                    })
                  : NO_LINK
              }
            >
              <Button
                size="small"
                variant="contained"
                disabled={!navigation.pagination.meta.previousPage}
                className="min-w-0"
              >
                &larr;
              </Button>
            </Link>
            <Typography className="text-sm text-gray-700">
              {t("components.core.table.end-page", {
                currentPage: navigation.pagination.meta.currentPage.toString(),
                totalPages: navigation.pagination.meta.totalPages.toString(),
              })}
            </Typography>
            <Link
              noLinkStyle
              shallow={navigation.shallow}
              to={
                navigation.pagination.meta.nextPage
                  ? currentUrlWithQueryParams(router, {
                      page: navigation.pagination.meta.nextPage,
                    })
                  : NO_LINK
              }
            >
              <Button
                size="small"
                variant="contained"
                disabled={!navigation.pagination.meta.nextPage}
                className="min-w-0"
              >
                &rarr;
              </Button>
            </Link>
          </div>
        )}
    </div>
  );
};

export const EmptyCell: React.FC = () => {
  return <span className="text-gray-500">-</span>;
};

type CheckboxCellProps<Entry> = {
  rowSelection: RowSelection;
  onRowSelectionChange: (newValue: RowSelection) => void;
  getRowSelectionIndex?: (T: Entry) => number;
  displayedData: Entry[];
  disabled?: boolean;
  isHead?: boolean;
  entry?: Entry;
  index: number;
  fixed: boolean;
  enableRowSelection?: (T: Entry) => boolean;
};

const CheckboxCell = <Entry,>({
  rowSelection,
  onRowSelectionChange,
  getRowSelectionIndex,
  displayedData,
  disabled = false,
  isHead = false,
  entry,
  index,
  enableRowSelection = () => true,
  fixed,
}: CheckboxCellProps<Entry>): ReactElement => {
  const rowIndex = value(() => {
    if (getRowSelectionIndex && entry) return getRowSelectionIndex(entry);

    return index;
  });

  const onSelectionChange = (unfilteredRowSelection: RowSelection) => {
    const selection = unfilteredRowSelection;

    displayedData.forEach((entry) => {
      selection[getRowSelectionIndex?.(entry) as number] =
        enableRowSelection(entry) && !!unfilteredRowSelection[getRowSelectionIndex?.(entry) as number];
    });

    onRowSelectionChange(selection);
  };

  const getAllRowSelected = () => {
    const allRows = value(() => {
      if (getRowSelectionIndex) {
        return keyBy(displayedData, (entry) => getRowSelectionIndex?.(entry));
      }

      return keyBy(
        displayedData.map((_, index) => index),
        "index"
      );
    });

    return mapValues(allRows, () => true);
  };

  const isChecked = value(() => {
    if (!isHead) return rowSelection[rowIndex] ?? false;

    return !displayedData.some((data, index) => {
      if (getRowSelectionIndex) {
        return enableRowSelection(data) && !rowSelection[getRowSelectionIndex(data)];
      }

      return enableRowSelection(data) && !rowSelection[index];
    });
  });

  const Tag = isHead ? "th" : "td";

  return (
    <Tag
      className={classNames({
        "py-3 px-4 text-left": true,
        "sticky left-0 z-20 bg-inherit": fixed,
      })}
      style={{
        minWidth: CHECKBOX_WIDTH,
        maxWidth: CHECKBOX_WIDTH,
        width: CHECKBOX_WIDTH,
      }}
    >
      <Checkbox
        onChange={(event: ChangeEvent<HTMLInputElement>) => {
          if (isHead) {
            if (isChecked) return onRowSelectionChange({});

            return onSelectionChange(getAllRowSelected());
          }

          onSelectionChange({ ...rowSelection, [rowIndex]: event.target.checked });
        }}
        checked={isChecked}
        className="animate-scale p-0 transition duration-75 ease-in-out"
        size="small"
        disabled={disabled}
      />
    </Tag>
  );
};

export const useTableSelection = <Entry,>(params: {
  pagination: PaginationResult<Entry>;
  enableFullSelection?: boolean;
  enableRowSelection?: <T extends Entry>(row: T) => boolean;
  getRowSelectionIndex?: <T extends Entry>(row: T) => number;
}) => {
  const [isFullSelected, setIsFullSelected] = useState(false);
  const [rowSelection, setRowSelection] = useState<{ [key: number]: boolean }>({});

  const onRowSelectionChange = (newRowSelection: { [key: number]: boolean }) => {
    setRowSelection(newRowSelection);
    setIsFullSelected(false);
  };

  const onFullSelectionChange = (newIsFullSelected: boolean) => {
    setIsFullSelected(newIsFullSelected);

    if (newIsFullSelected) {
      setRowSelection(
        Object.fromEntries(
          params.pagination.items.map((row, index) => [
            params.getRowSelectionIndex ? params.getRowSelectionIndex(row) : index,
            newIsFullSelected,
          ])
        )
      );
    } else {
      setRowSelection({});
    }
  };

  const resetSelection = () => {
    setIsFullSelected(false);
    setRowSelection({});
  };

  const selectedItems = useMemo(
    () =>
      params.pagination.items.filter((row, rowIndex) => {
        const index = params.getRowSelectionIndex ? params.getRowSelectionIndex(row) : rowIndex;

        return rowSelection[index];
      }),
    [rowSelection, params.pagination]
  );

  const selectedIds = useMemo(
    () =>
      Object.entries(rowSelection)
        .filter(([, value]) => value)
        .map(([key]) => Number(key)),
    [rowSelection]
  );

  const selectionSize = useMemo(() => {
    if (isFullSelected) return params.pagination.count;

    return selectedIds.length;
  }, [params.pagination, selectedIds, isFullSelected]);

  const isEmpty = selectionSize === 0;

  return {
    enableFullSelection: params.enableFullSelection,
    enableRowSelection: params.enableRowSelection ?? (() => true),
    getRowSelectionIndex: params.getRowSelectionIndex,

    isFullSelected,
    rowSelection,
    selectedItems,
    selectedIds,
    selectionSize,
    isEmpty,

    onRowSelectionChange,
    onFullSelectionChange,
    resetSelection,
  };
};
