import { value } from "~/components/helpers";
import { type AppContext } from "~/lib/context";
import { getRequiredUser } from "~/lib/get-required-user";
import { getId } from "~/lib/utils";
import { type PermissionsSchema, type UserPermissionsSchemaFilters } from "~/services/permissions/permissions-schema";
export const generateAdditionalFieldsPermissionsSchema = (ctx: AppContext, filters: UserPermissionsSchemaFilters) => {
  const user = getRequiredUser(ctx);
  const { isAdmin, isHr, ownedByCompany } = filters;

  const canAccessAdditionalFields = value(() => {
    if (isAdmin || isHr) {
      return true;
    }

    return user.permissions.canAccessAdditionalFields;
  });

  const allowedAdditionalFieldIds = value(() => {
    // Trick to avoid CASL throwing an error, we just won't fetch anything.
    if (!canAccessAdditionalFields) {
      return [-1];
    }

    if (isAdmin || isHr) {
      return null;
    }

    return user.permissions.allowedAdditionalFields.map(getId);
  });

  const additionalFieldIsAccessible = {
    ...ownedByCompany,
    ...(allowedAdditionalFieldIds?.length && { id: { in: allowedAdditionalFieldIds } }),
  };

  const additionalFieldValueIsAccessible = {
    ...ownedByCompany,
    ...(allowedAdditionalFieldIds?.length && { additionalFieldId: { in: allowedAdditionalFieldIds } }),
  };

  return {
    AdditionalField: {
      read: additionalFieldIsAccessible,
      update: (isAdmin || isHr) && additionalFieldIsAccessible,
      delete: (isAdmin || isHr) && additionalFieldIsAccessible,
    },

    AdditionalFieldMapping: {
      read: (isAdmin || isHr) && additionalFieldIsAccessible,
      update: (isAdmin || isHr) && additionalFieldIsAccessible,
      delete: (isAdmin || isHr) && additionalFieldIsAccessible,
    },

    AdditionalFieldValue: {
      read: additionalFieldValueIsAccessible,
      update: (isAdmin || isHr) && additionalFieldValueIsAccessible,
      delete: (isAdmin || isHr) && additionalFieldValueIsAccessible,
    },
  } satisfies Partial<PermissionsSchema>;
};
