import { chain } from "lodash";
import { type AppContext } from "~/lib/context";
import { logInfo } from "~/lib/logger";
import { type UpdateMappingSchemaInput } from "~/pages/api/update-mapping";
import { type ExternalJobForMapping, fetchExternalJobsForMapping } from "~/services/mapping";
import { mapExternalJobs } from "~/services/mapping/map-external-jobs";
import { updateExternalEmployeesAfterMapping } from "~/services/mapping/update-external-employees-after-mapping";

export const getExternalJobsWithUnskippedSuggestions = (
  externalJobs: ExternalJobForMapping[],
  CONFIDENCE_SCORE_THRESHOLD: number
) => {
  return chain(externalJobs)
    .filter((externalJob) => {
      if (externalJob.mappedJobId || externalJob.skippedAt) {
        return false;
      }

      if ((externalJob.suggestionConfidenceScore ?? 0) < CONFIDENCE_SCORE_THRESHOLD) {
        return false;
      }

      return true;
    })
    .map((externalJob) => {
      if (!externalJob.suggestedJob) {
        return null;
      }

      return {
        id: externalJob.id,
        mappedJobId: externalJob.suggestedJob.id,
        checked: !externalJob.suggestionSkippedAt,
        name: externalJob.name,
        suggestionConfidenceScore: externalJob.suggestionConfidenceScore,
        suggestionId: externalJob.suggestedJob.id,
        suggestionName: externalJob.suggestedJob.name,
      };
    })
    .compact()
    .orderBy(["suggestionConfidenceScore"], "desc")
    .value();
};

export const autoMapExternalJobs = async (ctx: AppContext, companyId: number) => {
  logInfo(ctx, "[mapping] Auto-mapping external jobs", { companyId });

  const CONFIDENCE_SCORE_THRESHOLD = 0.7;
  const externalJobs = await fetchExternalJobsForMapping(ctx, { forLegacyAutomapping: true });
  const externalJobsToMap: UpdateMappingSchemaInput["externalJobs"] = getExternalJobsWithUnskippedSuggestions(
    externalJobs,
    CONFIDENCE_SCORE_THRESHOLD
  ).map((externalJob) => ({ externalJobId: externalJob.id, mappedJobId: externalJob.suggestionId }));

  if (externalJobsToMap.length) {
    const impactedEmployeeIds = await mapExternalJobs(ctx, { mapping: externalJobsToMap, companyId, autoMapped: true });

    // (flag) eligible for pub/sub
    await updateExternalEmployeesAfterMapping(ctx, impactedEmployeeIds, companyId);
  }
};
