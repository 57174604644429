import { OpenInNew } from "@mui/icons-material";
import { Alert, Card, Stack, Typography } from "@mui/material";
import React, { type PropsWithChildren } from "react";
import { RecommendationSummary } from "~/components/compensation-review/recommendation-summary";
import { NextLinkComposed } from "~/components/ui/core/Link";
import { LoadingIndicator } from "~/components/ui/core/LoadingIndicator";
import { Button } from "~/components/ui/core/button";
import { CardHeader } from "~/components/ui/core/card-header";
import { useSubscriptions } from "~/hooks/useSubscriptions";
import { DateFormats } from "~/lib/dates";
import { useI18n } from "~/lib/i18n/use-i18n";
import { useFetchExternalEmployeeCompensationHistoryQuery } from "~/pages/api/external-employee/fetch-external-employee-compensation-history";
import { CompensationReviewContextProvider } from "~/services/compensation-review/campaigns/campaign-handler";

type Props = {
  externalEmployeeId: number;
  hideIfEmpty?: boolean;
};

const Layout: React.FC<PropsWithChildren> = ({ children }) => {
  return <Stack className="relative h-full">{children}</Stack>;
};

// TODO : migrate/remove after deploying new employee-form everywhere
export const LegacyExternalEmployeeCompensationHistory: React.FC<Props> = ({ externalEmployeeId }) => {
  return (
    <div className="p-4">
      <CompensationReviewHistory externalEmployeeId={externalEmployeeId} />
    </div>
  );
};

// TODO : move to external-employee/drawer folder
export const CompensationReviewHistory: React.FC<Props> = ({ externalEmployeeId, hideIfEmpty }) => {
  const { t, formatDate } = useI18n();
  const { subscriptions } = useSubscriptions();

  const { data: compensationHistory, isFetching } = useFetchExternalEmployeeCompensationHistoryQuery({
    input: {
      externalEmployeeId,
      excludedCampaignId: null,
    },
    options: {
      enabled: subscriptions.CAN_ACCESS_COMPENSATION_REVIEW,
    },
  });

  if (isFetching && !compensationHistory) {
    return (
      <Layout>
        <Stack className="items-center justify-center pt-8" direction="row" gap={4}>
          <Typography variant="h3">{t("components.external-employee-panel.history.loading")}</Typography>
          <LoadingIndicator size="xs" />
        </Stack>
      </Layout>
    );
  }

  if (!isFetching && !compensationHistory?.length) {
    if (!!hideIfEmpty) {
      return null;
    }

    return (
      <Layout>
        <Alert severity="warning" color="secondary">
          <Typography variant="body1">{t("components.external-employee-panel.history.no-history-found")}</Typography>
        </Alert>
      </Layout>
    );
  }

  return (
    <Layout>
      <Stack spacing={4}>
        {compensationHistory?.map(({ compensationReview, employee }) => {
          const date = employee.campaign?.endDate ?? employee.campaign?.startDate ?? employee.campaign?.createdAt;

          return (
            <Card key={employee.id} className="w-full">
              <CardHeader
                title={employee.campaign.name}
                description={formatDate(date, DateFormats.MONTH_YEAR)}
                actions={
                  <Button
                    className="ml-auto"
                    component={NextLinkComposed}
                    newTab
                    color="gray"
                    to={{
                      pathname: "/compensation-review/campaigns/[campaignId]",
                      query: { campaignId: `${employee.campaign.id}` },
                    }}
                    endIcon={<OpenInNew />}
                  >
                    {t("components.external-employee-panel.history.open-campaign")}
                  </Button>
                }
              />

              <Stack className="max-w-lg pt-4">
                <CompensationReviewContextProvider value={compensationReview}>
                  <RecommendationSummary employee={employee} />
                </CompensationReviewContextProvider>
              </Stack>
            </Card>
          );
        })}
      </Stack>
    </Layout>
  );
};
