import { type EmployeeLevel } from "@prisma/client";
import { type AppContext } from "~/lib/context";
import { createMissingBenchmarkLevel } from "~/services/benchmark-level/create-missing-benchmark-level";
import { fetchAllowedCompanyBenchmarkLevels } from "~/services/benchmark-level/fetch-allowed-company-benchmark-levels";

export const matchLevelWithBenchmarkLevel = async (
  ctx: AppContext,
  params: { level: EmployeeLevel | null | undefined }
) => {
  const { level } = params;

  if (!level) {
    return;
  }

  const benchmarkLevels = await fetchAllowedCompanyBenchmarkLevels(ctx);

  const benchmarkLevel = benchmarkLevels.find((benchmarkLevel) => {
    return level === benchmarkLevel.name;
  });

  //I don't know how to have a proper fallback
  if (level && !benchmarkLevel) {
    return createMissingBenchmarkLevel(ctx, { figuresLevel: level });
  }

  return benchmarkLevel;
};
