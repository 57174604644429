import chalk from "chalk";
import { type Tracer } from "dd-trace";
import { writeFileSync } from "fs";
import jsonColorizer from "json-colorizer";
import { chain, isString, keys, omit } from "lodash";
import { default as originalFetch, type RequestInfo, type RequestInit, type Response } from "node-fetch";
import packageJson from "~/../package.json";
import { value } from "~/components/helpers";
import { config } from "~/config";

// eslint-disable-next-line no-console
const log = (message: string) => console.log(`[fetch] ${message}`);

export const fetch = async (url: RequestInfo, init?: RequestInit): Promise<Response> => {
  if (process.env.DEBUG_FETCH && !config.app.isJest) {
    return await debugFetch(url, init);
  }

  if (typeof window === "undefined") {
    const tags = {
      "span.kind": "server",
      url,
      "method": init?.method || "GET",
    };

    // eslint-disable-next-line @typescript-eslint/no-var-requires
    return await (require("dd-trace") as Tracer).trace(
      "server.fetch",
      { tags, service: `${packageJson.name}.fetch` },
      async () => originalFetch(url, init)
    );
  }

  return originalFetch(url, init);
};

const debugFetch = async (url: RequestInfo, init?: RequestInit): Promise<Response> => {
  const method = value(() => {
    if (!init?.method) {
      return chalk.green("GET");
    }
    if (init?.method === "POST") {
      return chalk.red("POST");
    }
    if (init?.method === "GET") {
      return chalk.green("GET");
    }
    return chalk.yellow(init.method);
  });
  log(`${method} : ${url}`);

  if (init) {
    if (init.headers) {
      const safeHeaders = chain(init.headers)
        .toPairs()
        .map(([key, value]) => {
          if (isString(value) && value.length > 80) {
            return [key, `${value.slice(0, 40)}...${value.slice(-40)}`];
          }
          return [key, value];
        })
        .fromPairs()
        .value();
      // eslint-disable-next-line no-console
      console.table(safeHeaders);
    }

    const ignoredKeys = ["body", "method", "headers"];

    if (keys(init).find((key) => !ignoredKeys.includes(key))) {
      log(`Options :`);
      log(jsonColorizer(JSON.stringify(omit(init, ignoredKeys), null, 2)));
    }

    if (init.body) {
      log(`Body :`);

      const body = value(() => {
        if (init.body instanceof URLSearchParams) {
          return Object.fromEntries(init.body);
        }
        return JSON.parse(init.body as string);
      });

      log(jsonColorizer(JSON.stringify(body, null, 2)));
    }
  }

  const res = await originalFetch(url, init);

  if (res.ok) {
    const { safeUrl, safeEndpoint, offset } = value(() => {
      try {
        const { hostname, pathname, searchParams } = new URL(url as string);
        return {
          safeUrl: hostname.split(".").slice(-2).join("_"),
          safeEndpoint: pathname.split("/").slice(1).join("_"),
          offset:
            searchParams.get("offset") ||
            searchParams.get("$skip") ||
            searchParams.get("pageNumber") ||
            searchParams.get("cursor") ||
            searchParams.get("page"),
        };
      } catch {
        return { safeUrl: "example_com", safeEndpoint: "unknown", offset: null };
      }
    });

    const contentType = res.headers.get("content-type");

    // The working directory isn't the same for the dev server and for the CLI scripts
    const path = process.cwd().includes("src/cli") ? "../.." : ".";
    const filename = `${path}/tmp/dumps/integrations/${[safeUrl, safeEndpoint, offset].filter(Boolean).join("_")}.json`;

    if (contentType?.includes("application/json")) {
      const data = await res.json();

      writeFileSync(filename, JSON.stringify(data, null, 2));
      log(`✅ Dumped to ${filename}`);

      // res.json consumes the body and can only be run once so we monkey-patch the retrieved data to the json method
      res.json = async () => data;
    }
  }

  return res;
};
