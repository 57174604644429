import { QueueJobName } from "@prisma/client";
import { number, object } from "yup";
import { type AppContext } from "~/lib/context";
import { BaseJobDataSchema } from "~/lib/queue/base-job-data-schema";
import { sendJob } from "~/lib/queue/send-job";
import { type YupOutputType } from "~/lib/utils";
import { synchronizeSalaryRangeEmployees } from "~/services/salary-bands/configuration/synchronise-salary-range-employees";
import { createSalaryBands } from "~/services/salary-bands/creation/create-salary-bands";

const CreateSalaryBandsJobDataSchema = BaseJobDataSchema.concat(object({ salaryGridId: number().required() }));

export type CreateSalaryBandsJobData = YupOutputType<typeof CreateSalaryBandsJobDataSchema>;

export const createSalaryBandsWorkerService = async (ctx: AppContext, data: CreateSalaryBandsJobData) => {
  const { salaryGridId } = CreateSalaryBandsJobDataSchema.validateSync(data, { abortEarly: false });

  await createSalaryBands(ctx, { salaryGridId });
  await synchronizeSalaryRangeEmployees(ctx, { salaryGridId });
};

export const sendCreateSalaryBandsJob = async (ctx: AppContext, data: CreateSalaryBandsJobData) => {
  await sendJob(ctx, {
    jobName: QueueJobName.CREATE_SALARY_BANDS,
    data,
    options: { singletonKey: data.salaryGridId.toString() },
  });
};
