import { SalaryGridStatus } from "@prisma/client";
import { type PermissionsParams } from "~/services/user/permissions/authentication-options";
import { canAccessSalaryBands, canAccessSettings } from "~/services/user/permissions/feature-access-permissions";
import { type PermissionFunction } from "~/services/user/permissions/functions";

export const canUpdateSalaryBands = (params: PermissionsParams) => canAccessSettings(params);

export const canUpdateSalaryGrid: PermissionFunction<SalaryGridStatus> = (params, salaryGridStatus) =>
  canUpdateSalaryBands(params) && salaryGridStatus !== SalaryGridStatus.ARCHIVED;

export const canAccessSalaryBandsTiering = (params: PermissionsParams) => {
  if (!canAccessSalaryBands(params)) return false;

  return !!params.featureFlags?.CAN_ACCESS_SALARY_BANDS_TIERING;
};
